import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getToolRentalById, getToolRentalList } from 'api/tools/toolsRental.api'

/**
 * 대여 반납 목록
 * @type {AsyncThunk<unknown, AsyncThunk<?, void, AsyncThunkConfig>, AsyncThunkConfig>}
 */
export const rentalListAsync = createAsyncThunk(
    'rental/list/get',
    async ({ search, regionId, secondPath }, { rejectWithValue }) => {
        try {
            const { data } = await getToolRentalList(search, regionId, secondPath)
            return data
        } catch (error) {
            return rejectWithValue(error)
        }
    },
)

/**
 * 대여 반납 상세
 * @type {AsyncThunk<unknown, void, AsyncThunkConfig>}
 */
export const rentalDetailAsync = createAsyncThunk(
    'rental/detail/get',
    async ({ regionId, rentalId }, { rejectWithValue }) => {
        try {
            const response = await getToolRentalById({ regionId, rentalId })
            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    },
)

/**
 * 대여 반납 생성
 * @type {AsyncThunk<Promise<void>, void, AsyncThunkConfig>}
 */
export const postRentalAsync = createAsyncThunk('rental/post', async values => {
    // const { region, id, password } = values
    // await postAccount({ region, id, password })
    return values
})

const initialState = {
    isFetching: false,
    rentalInfo: {
        page: {},
        filterCountInfo: {},
        rentalList: [],
    },
    rentalDetail: null,
    error: null,
}

// 현재 사용안함
const rentalSlice = createSlice({
    name: 'rental',
    initialState,
    reducers: {
        // 상세 초기화
        clearRentalDetail: state => {
            state.rentalDetail = null
        },
    },
    extraReducers: builder => {
        builder
            // 대여 반납 목록
            .addCase(rentalListAsync.pending, state => {
                state.isFetching = true
            })
            .addCase(rentalListAsync.fulfilled, (state, { payload }) => {
                state.isFetching = false
                state.rentalInfo = payload
            })
            .addCase(rentalListAsync.rejected, (state, { error }) => {
                state.isFetching = false
                state.error = error.message
            })
            // 대여 반납 상세
            .addCase(rentalDetailAsync.pending, state => {
                state.isFetching = true
            })
            .addCase(rentalDetailAsync.fulfilled, (state, { payload }) => {
                state.isFetching = false
                state.rentalDetail = payload
            })
            .addCase(rentalDetailAsync.rejected, (state, { error }) => {
                state.isFetching = false
                state.error = error.message
            })
    },
})

export const getRentalInfo = state => state.rental.rentalInfo
export const getRentalDetail = state => state.rental.rentalDetail
export const { clearRentalDetail } = rentalSlice.actions

export const getIsFetching = state => state.rental.isFetching
export const getError = state => state.rental.rentalInfo.error
export default rentalSlice
