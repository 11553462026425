import dayjs from 'dayjs'

import _ from 'lodash'
import { createSupabaseQuery, getCount, getPaginationInfo, getRecruitmentTutorCount, getTotalCount } from 'api/common'

/**
 * 핸드폰 번호 체크
 * '010-1234-5678' or '01012345678'
 * @param number
 * @returns {boolean}
 */
export const checkPhoneNumber = number => {
    const regPhone = /^01([0|1|6|7|9])-?([0-9]{3,4})-?([0-9]{4})$/
    return regPhone.test(number)
}

/**
 * 생년월일 체크
 * YYMMDD
 * @param birth
 * @returns {boolean}
 */
export const checkBirthSimple = birth => {
    const regBirth = /([0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1,2][0-9]|3[0,1]))/g
    return regBirth.test(birth)
}

/**
 * 생년월일 체크
 * YYYYMMDD
 * @param birth
 * @returns {boolean}
 */
export const checkBirthLong = birth => {
    const regBirth = /^(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/
    return regBirth.test(birth)
}

/**
 * 파일 Type 체크
 * @param file
 * @returns {boolean}
 */
export const validateFileType = file => {
    const allowedMimeTypes = ['image/jpeg', 'image/png', 'image/gif']
    return allowedMimeTypes.includes(file.type)
}

/**
 * 생년월일 유효성 검사
 * @param _ 해당 필드의 규칙을 나타내는 객체 (무시)
 * @param birth
 * @returns {Promise<never>|Promise<void>}
 */
export const validateBirthday = (_, birth) => {
    if (!birth) {
        return Promise.reject(new Error('생년월일을 입력해주세요.'))
    }
    if (!checkBirthLong(birth)) {
        return Promise.reject(new Error('올바른 생년월일 형식이 아닙니다.'))
    }
    return Promise.resolve()
}

/**
 * 핸드폰 번호 유효성 검사
 * @param _ 해당 필드의 규칙을 나타내는 객체 (무시)
 * @param phone
 * @returns {Promise<never>|Promise<void>}
 */
export const validatePhoneNumber = (_, phone) => {
    if (!phone) {
        return Promise.reject(new Error('핸드폰 번호를 입력해주세요.'))
    }
    if (!checkPhoneNumber(phone)) {
        return Promise.reject(new Error('올바른 핸드폰 번호 형식이 아닙니다.'))
    }
    return Promise.resolve()
}

/**
 * 기본 국가 언어 체크
 * @returns {string}
 * @constructor
 */
export const CheckCountry = () => {
    const defaultCountry = localStorage.getItem('locale')
    if (defaultCountry) {
        if (defaultCountry === 'ko') {
            return '한국어'
        }
        if (defaultCountry === 'en') {
            return 'English'
        }
        if (defaultCountry === 'ja') {
            return '日本語'
        }
    }
    return '한국어'
}

/**
 * 교육기간 계산
 * @param time
 * @returns {string}
 */
export const calculateProgramPeriod = time => {
    if (time < 8) {
        return '단기'
    }
    return '장기'
}

/**
 * 지난달 첫 날짜 / 마지막 날짜 구하기
 * @returns {[dayjs.Dayjs,dayjs.Dayjs]}
 */
export const getLastMonthDates = () => {
    // 현재 날짜를 가져옵니다.
    const today = dayjs().utc()

    // 저번달의 첫 날을 계산합니다.
    const lastMonthStart = today.subtract(1, 'month').startOf('month')

    // 저번달의 마지막 날을 계산합니다.
    const lastMonthEnd = lastMonthStart.endOf('month')

    return [lastMonthStart, lastMonthEnd]
}

/**
 * 이번달 첫 날짜 / 마지막 날짜 구하기
 * @returns {[dayjs.Dayjs,dayjs.Dayjs]}
 */
export const getCurrentMonthDates = () => {
    // 현재 날짜를 가져옵니다.
    const today = dayjs()

    // 이번 달의 첫 날을 계산합니다.
    const currentMonthStart = today.startOf('month')

    // 이번 달의 마지막 날을 계산합니다.
    const currentMonthEnd = today.endOf('month')

    return [currentMonthStart, currentMonthEnd]
}

/**
 * 이번달 첫 날짜 / 마지막 날짜 구하기
 * 유닉스 시간
 * ex) 1696086000000
 * @returns {[number,number]}
 */
export const getCurrentMonthDatesUnixType = () => {
    const lastMonthFirstDay = dayjs().subtract(1, 'month').startOf('month')
    const thisMonthLastDay = dayjs().endOf('month')
    const currentMonthStart = lastMonthFirstDay.valueOf()
    const currentMonthEnd = thisMonthLastDay.valueOf()

    return [currentMonthStart, currentMonthEnd]
}

/**
 * 다음달
 * 'YYYY년 MM월'
 */
export const getNextMonthDateFormatted = () => {
    // 현재 날짜 가져오기
    const currentDate = dayjs()
    // 다음 달의 날짜 가져오기
    const nextMonthDate = currentDate.add(1, 'month')
    // 특정 형식으로 날짜 포맷팅
    return nextMonthDate.format('YYYY년 MM월')
}

/**
 * 천원 단위 콤마 컨버팅 함수
 * @param number
 * @returns {*}
 */
export const formatCurrency = number => {
    // Convert the number to a string
    if (!number) return 0
    const numString = number.toString()

    // Use regex to add commas
    const formattedNumber = numString.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return formattedNumber
}

// 숫자를 3자리까지만 나오도록 포맷팅
export const formatNumberTo3Digits = value => {
    if (value) {
        const formattedValue = Number(value).toLocaleString('en-US', {
            maximumFractionDigits: 0,
        })
        return formattedValue
    }
    return value
}

// 숫자를 1,000자씩 나오도록 포맷팅
export const formatNumberToThousands = value => {
    if (value) {
        const formattedValue = Number(value).toLocaleString('en-US')
        return formattedValue
    }
    return value
}

// 입력된 값에서 숫자 외의 문자 제거
export const numberParser = value => {
    return value.replace(/\D/g, '')
}

/**
 * 프로그램 운영 필터링
 * 예정/진행/완료
 * @param startDateStr
 * @param endDateStr
 * @returns {string}
 */
export const getStatus = (startDateStr, endDateStr) => {
    // 현재 날짜 가져오기
    const currentDate = new Date()

    // 문자열을 Date 객체로 변환
    const startDate = new Date(`${startDateStr.slice(0, 4)}-${startDateStr.slice(4, 6)}-${startDateStr.slice(6)}`)
    const endDate = new Date(`${endDateStr.slice(0, 4)}-${endDateStr.slice(4, 6)}-${endDateStr.slice(6)}`)

    // 예정, 진행, 완료 상태 판단
    if (currentDate < startDate) {
        return '예정'
    }
    if (currentDate <= endDate) {
        return '진행'
    }
    return '완료'
}

/* eslint-disable */
export const processDateRange = (start, end) => {
    const currentDate = dayjs() // 현재 날짜

    const startDate = dayjs(start, { format: 'YYYYMMDD' }).format('YYYY-MM-DD')
    const endDate = dayjs(end, { format: 'YYYYMMDD' }).format('YYYY-MM-DD')

    if (currentDate.isBefore(startDate)) {
        return '대기'
    } else if (currentDate.isBetween(startDate, endDate)) {
        return '재직중'
    } else if (currentDate.isAfter(endDate)) {
        return '계약만료'
    } else {
        console.log('올바르지 않은 날짜 범위')
        return null
    }
}

/**
 * 객체 카멜케이스 변환 함수
 * @param obj
 * @returns {{}|*}
 */
export const convertKeysToCamelCase = obj => {
    if (_.isObject(obj)) {
        if (_.isArray(obj)) {
            // 배열인 경우
            return obj.map(item => convertKeysToCamelCase(item))
        }
        // 객체인 경우
        const camelCaseObject = {}
        _.forEach(obj, (value, key) => {
            if (_.isObject(value) || _.isArray(value)) {
                // 값이 객체나 배열인 경우 재귀적으로 처리
                camelCaseObject[_.camelCase(key)] = convertKeysToCamelCase(value)
            } else {
                // 기타 경우 카멜 케이스로 변환
                camelCaseObject[_.camelCase(key)] = value
            }
        })
        return camelCaseObject
    }
    // 기타 경우 (문자열, 숫자 등)
    return obj
}

/**
 * YYYY-MM-DD HH:mm:ss.SSSZ
 * 12시로 포맷팅 (시작일, 종료일)
 * @param startDate
 * @returns {string}
 */
export const formatDate = startDate => {
    const dateObject = dayjs(String(startDate), { format: 'YYYYMMDD' })
        .set('hour', 12)
        .set('minute', 0)
        .set('second', 0)
        .set('millisecond', 0)

    const formattedStartDate = dateObject.format('YYYY-MM-DD HH:mm:ss.SSSZ')
    return formattedStartDate
}

/**
 * page range 계산
 * ex) Range : 0-9
 * @param pageSize
 * @param currentPage
 * @returns {[number,number]}
 */
export const calculatePageRange = (pageSize, currentPage) => {
    const start = (currentPage - 1) * pageSize
    const end = start + pageSize - 1
    return [start, end]
}

/**
 * 페이지 정보 계산 함수
 * supaBase
 * 사용하지 않음
 */
export const calculatePaginationInfo = async (table, search, pageSize, regionId) => {
    const currentPage = new URLSearchParams(search).get('page') || 1
    const paginationInfo = await getPaginationInfo(table, Number(currentPage), pageSize, regionId)
    return paginationInfo
}

/**
 * 페이지 range 계산
 * @returns {string}
 */
export const handleGetRange = (search, pageSize) => {
    const pageParams = new URLSearchParams(search).get('page')
    const [start, end] = calculatePageRange(pageSize, pageParams)
    if (pageParams) {
        return { start, end }
    }
    return ''
}

/**
 * 카멜케이스 변경
 * @param camelCase
 * @returns {*}
 */
export const camelToSnake = camelCase => {
    return camelCase.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
}
/**
 * 교육관리 필터링
 * query string 형식
 * @param params
 * @returns {string}
 */
export const buildSupabaseFilter = params => {
    const filters = []
    const searchParams = new URLSearchParams(params)

    // eslint-disable-next-line no-restricted-syntax
    for (const key of searchParams.keys()) {
        const snakeCaseKey = camelToSnake(key)
        const value = searchParams.get(key)
        if (snakeCaseKey === 'class_hours') {
            if (value === '단기') {
                filters.push(`${snakeCaseKey}=lt.8`)
            }
            if (value === '장기') {
                filters.push(`${snakeCaseKey}=gte.8`)
            }
        } else if (snakeCaseKey === 'search') {
            filters.push(`program_name=ilike.${value}`)
        } else if (snakeCaseKey === 'sort') {
            filters.push(`program_name=ilike.${value}`)
        } else if (snakeCaseKey === 'page') {
            /* empty */
        } else {
            filters.push(`${snakeCaseKey}=eq.${value}`)
        }
    }
    // 필터를 &로 구분하여 URL 형태로 조합
    const filterString = filters.join('&')
    // 최종 URL 반환
    return filterString
}

/**
 * 필터링 공통 함수
 * supabase
 * chain 형식
 * @param table
 * @param params
 * @param regionId
 * @param path
 * @returns {PostgrestFilterBuilder<any, *, GetResult<any, any, R | unknown, "*">[], R | unknown>}
 */
export const buildSupabaseChainFilter = async (table, params, regionId, path) => {
    const classHours = 'class_hours'

    const programId = 'program_id'
    const tutorId = 'tutor_id'
    const rentalId = 'rental_id'

    const programName = 'program_name'
    const organizationName = 'organization_name'
    const tutorName = 'tutor_name'

    const pageSize = 10

    const searchParams = new URLSearchParams(params)
    // // 실제 데이터 구하는 쿼리 (페이지 range 포함)
    let query = createSupabaseQuery(table, regionId, path)

    const start = searchParams.get('startDate')
    const end = searchParams.get('endDate')

    // 기간 query 추가
    if (!!start && !!end) {
        const formattedStart = start.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')
        const formattedEnd = end.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3 23:59:59')
        // const formattedStart = dayjs(start).format("YYYY-MM-DD 00:00:00")
        // const formattedEnd = dayjs(end).format("YYYY-MM-DD 23:59:59")

        query = query.gte('start_date', formattedStart).lte('end_date', formattedEnd)
    } else if (!!start) {
        const formattedStart = start.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')
        // const formattedStart = dayjs(start).format("YYYY-MM-DD 00:00:00")

        if (path === 'inventory') {
            query = query.eq('acquire_date', formattedStart)
        } else {
            query = query.gte('start_date', formattedStart)
        }
    } else if (!!end) {
        const formattedEnd = end.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3 23:59:59')
        // const formattedEnd = dayjs(end).format("YYYY-MM-DD 23:59:59")

        query = query.lte('end_date', formattedEnd)
    }

    // 기본 정렬
    if (path === 'program') {
        query = query.order(programId, { ascending: false })
    }
    if (path === 'tutors') {
        query = query.order(tutorId, { ascending: false })
    }
    if (path === 'rental') {
        query = query.order(rentalId, { ascending: false })
    }

    if (table === 'demander_info' || table === 'report_info') return { query, searchParams }

    if (searchParams !== 'page') {
        query = query.limit(10)
    }

    let searchValue = ''
    let selectValue = ''

    // eslint-disable-next-line no-restricted-syntax
    for (const key of searchParams.keys()) {
        const snakeCaseKey = camelToSnake(key)
        const value = searchParams.get(key)

        if (snakeCaseKey === classHours) {
            if (value === '단기') {
                query = query.lt(classHours, 8)
            }
            if (value === '장기') {
                query = query.gte(classHours, 8)
            }
        } else if (snakeCaseKey === 'start_date') {
            /* empty */
        } else if (snakeCaseKey === 'end_date') {
            /* empty */
        } else if (snakeCaseKey === 'select') {
            if (path === 'program') {
                selectValue = value
            }
        } else if (snakeCaseKey === 'search') {
            searchValue = value

            if (path === 'program') {
                if (selectValue === 'programName') {
                    query = query.ilike(programName, `%${value}%`)
                }
                if (selectValue === 'organizationName') {
                    query = query.ilike(organizationName, `%${value}%`)
                }
            }
            if (path === 'tutors') {
                query = query.ilike(tutorName, `%${value}%`)
            }
            if (path === 'inventory') {
                query = query.ilike('tools_name', `%${value}%`)
            }
        } else if (snakeCaseKey === 'sort') {
            if (value === 'createdDt') {
                if (path === 'program') {
                    query = query.order(programId, { ascending: true })
                }
                if (path === 'tutors') {
                    query = query.order(tutorId, { ascending: true })
                }
                if (path === 'inventory') {
                    query = query.order('created_at', { ascending: true })
                }
            }
            if (value === 'updatedDt') {
                if (path === 'program') {
                    query = query.order(programId, { ascending: true })
                }
                if (path === 'tutors') {
                    query = query.order(tutorId, { ascending: true })
                }
                if (path === 'inventory') {
                    query = query.order('updated_at', { ascending: true })
                }
            }
            // if (value === 'date') {
            //     console.log('date')
            //
            //     query = query.order(programId, { ascending: true })
            // }
        } else if (snakeCaseKey === 'is_advanced_instructor') {
            if (value === 'Y') {
                query = query.eq(snakeCaseKey, '고급강사')
            }
            if (value === 'N') {
                query = query.eq(snakeCaseKey, '일반강사')
            }
        } else if (snakeCaseKey === 'page') {
            const { start, end } = handleGetRange(params, pageSize)
            query = query.range(start, end)
        } else {
            if (snakeCaseKey === 'region_id') {
                if (value === 'all') {
                    // 'all'일 경우 조건 무시
                    continue
                } else {
                    query = query.eq(snakeCaseKey, value)
                }
            } else {
                query = query.eq(snakeCaseKey, value)
            }
        }
    }

    return {
        query,
    }
}

/**
 * 교윤관리 필터 카운트
 * @param table
 * @param item
 * @param first
 * @param second
 * @param third
 * @param regionId
 * @returns {Promise<{scheduled: *, progress: *, totalCount: *, complete: *}|null>}
 */
export const getCountsForAllProgramStatus = async (table, item, first, second, third, regionId) => {
    try {
        const [scheduled, progress, complete, totalCount] = await Promise.all([
            getCount(table, item, first, regionId),
            getCount(table, item, second, regionId),
            getCount(table, item, third, regionId),
            getTotalCount(table, regionId),
        ])

        const filterCountInfo = {
            totalCount,
            scheduled,
            progress,
            complete,
            // "cancel": 0
        }
        return filterCountInfo
    } catch (error) {
        console.error('에러 발생:', error.message)
        return null
    }
}

/**
 * 강사관리 필터 카운트
 * @param table
 * @param firstItem
 * @param secondItem
 * @param thirdItem
 * @param first
 * @param second
 * @param third
 * @returns {Promise<{scheduled: *, progress: *, totalCount: *, complete: *}|null>}
 */
export const getCountsForTutorAllStatus = async (
    table,
    firstItem,
    secondItem,
    thirdItem,
    first,
    second,
    third,
    regionId,
) => {
    try {
        const [newCount, rehiredCount, advancedCount, totalCount] = await Promise.all([
            // getCount(table, firstItem, first, regionId),
            getRecruitmentTutorCount(table, regionId),
            getCount(table, secondItem, second, regionId),
            getCount(table, thirdItem, third, regionId),
            getTotalCount(table, regionId),
        ])

        const filterCountInfo = {
            totalCount,
            newCount,
            rehiredCount,
            advancedCount,
            // "cancel": 0
        }
        return filterCountInfo
    } catch (error) {
        console.error('에러 발생:', error.message)
        return null
    }
}

/**
 * 대여 관리
 * JSON 스네이크 -> 카멜케이스 변환
 * @param item
 * @returns {{tutorName: (string|*), rentalDispatchDate: *, rentalApplicationDate: *, rentalReturnDate: *, region_Id, rentalStatus: *, rentalId: *}}
 */
export const convertRentalSnakeToCamelCase = item => {
    const rentalInfo = {
        rentalId: item.rental_id,
        rentalStatus: item.rental_status,
        rentalApplicationDate: item.rental_application_date,
        rentalDispatchDate: item.rental_dispatch_date,
        rentalReturnDate: item.rental_return_date,
        regionId: item.region_id,
        renterName: item.tutor_name,
    }
    return rentalInfo
}

/**
 * 교육관리
 * JSON 스네이크 -> 카멜케이스 변환
 * @param item
 * @returns {{programDemandRepresentative: {managerPosition: *, managerEmail: *, managerTelNumber: *, managerPhoneNumber: *, managerName: *, managerRemarks: *}, tutorId: null, programDemandAgency: {addressSido: *, addressSigungu: *, organizationName: *, areaName: *, grade: *, classroom: *, category: *, addressBname: *}, sysRegrId: *, sysModDtime: *, programAttachmentList: null, programBasicInfo: {method: *, educationType: *, programName: *, course: *, detail: *}, sysModrId: *, programInstructorMatchingList: null, programOperationInfo: {endDate: *, numberOfGraduates: *, numberOfSession: *, monthOfEdu: *, timeNumber: *, classHours: *, numberOfSutdent, numberOfApplicants: *, startDate: *, status: *}, sysRegDtime: *, regionId: *, programEtcInfo: {computerYn: string, numberOfAttendance: *, teachingMaterials: *}, programId: *}}
 */
export const convertProgramSnakeToCamelCase = item => {
    // 프로그램 기본 정보
    const programBasicInfo = {
        programName: item.program_name,
        detail: item.detail,
        educationType: item.education_type,
        course: item.course,
        method: item.method,
    }

    // 프로그램 수요 기관 정보
    const programDemandAgency = {
        category: item.category,
        grade: item.grade,
        classroom: item.classroom,
        organizationName: item.organization_name,
        addressSido: item.address_sido,
        addressSigungu: item.address_sigungu,
        addressBname: item.address_bname,
        areaName: item.area_name,
    }

    // 프로그램 대표자 정보
    const programDemandRepresentative = {
        managerName: item.manager_name,
        managerPosition: item.manager_position,
        managerTelNumber: item.manager_tel_number,
        managerPhoneNumber: item.manager_phone_number,
        managerEmail: item.manager_email,
        managerRemarks: item.manager_remarks,
    }

    // 프로그램 기타 정보
    const programEtcInfo = {
        teachingMaterials: item.teaching_materials,
        computerYn: item.computer_yn ? 'Y' : 'N',
        numberOfAttendance: item.number_of_attendance,
    }

    // 프로그램 운영 정보
    const programOperationInfo = {
        numberOfApplicants: item.number_of_applicants,
        status: item.status,
        startDate: item.start_date.replace(/-/g, ''),
        endDate: item.end_date.replace(/-/g, ''),
        monthOfEdu: item.month_of_edu,
        classHours: item.class_hours,
        numberOfSession: item.number_of_session,
        numberOfGraduates: item.number_of_graduates,
        numberOfSutdent: item.number_of_sutdent || '',
        timeNumber: item.time_number,
    }

    // 변환된 데이터 객체 생성
    const convertedItem = {
        programId: item.program_id,
        regionId: item.region_id,
        tutorId: null,
        programAttachmentList: item.program_attachment,
        programInstructorMatchingList: item.program_instructor_matching,
        programBasicInfo,
        programDemandAgency,
        programDemandRepresentative,
        programEtcInfo,
        programOperationInfo,
        sysRegDtime: item.sys_reg_dtime,
        sysRegrId: item.sys_regr_id,
        sysModDtime: item.sys_mod_dtime,
        sysModrId: item.sys_modr_id,
    }

    return convertedItem
}

/**
 * 교육관리
 * JSON 카멜케이스 -> 스네이크 변환
 * @param item
 * @returns {{program_name: *, detail: *, education_type: *, course: *, method: *, category: *, grade: *, classroom: *, organization_name: *, address_sido: *, address_sigungu: *, address_bname: *, area_name: *, manager_name: *, manager_position: *, manager_tel_number: *, manager_phone_number: *, manager_email: *, manager_remarks: *, teaching_materials: *, computer_yn: *, number_of_attendance: *, number_of_applicants: *, status: *, start_date: *, end_date: *, month_of_edu: *, class_hours: *, number_of_session: *, number_of_graduates: *, number_of_sutdent: *, time_number: *, program_id: *, region_id: *, tutor_id: *, program_attachment: *, program_instructor_matching: *, sys_reg_dtime: *, sys_regr_id: *, sys_mod_dtime: *, sys_modr_id: *}}
 */
export const convertProgramCamelToSnakeCase = item => {
    // 변환된 데이터 객체 생성
    const convertedItem = {
        program_id: item.programId,
        region_id: item.regionId,
        tutor_id: item.tutorId,
        sys_reg_dtime: item.sysRegDtime,
        sys_regr_id: item.sysRegrId,
        sys_mod_dtime: item.sysModDtime,
        sys_modr_id: item.sysModrId,
        program_name: item.programName,
        detail: item.detail,
        education_type: item.educationType,
        course: item.course,
        method: item.method,
        category: item.category,
        grade: item.grade,
        classroom: item.classroom,
        organization_name: item.organizationName,
        address_sido: item.addressSido,
        address_sigungu: item.addressSigungu,
        address_bname: item.addressBname,
        area_name: item.areaName,
        manager_name: item.managerName,
        manager_position: item.managerPosition,
        manager_tel_number: item.managerTelNumber,
        manager_phone_number: item.managerPhoneNumber,
        manager_email: item.managerEmail,
        manager_remarks: item.managerRemarks,
        teaching_materials: item.teachingMaterials,
        computer_yn: item.computerYn === 'Y',
        number_of_attendance: item.numberOfAttendance,
        number_of_applicants: item.numberOfApplicants,
        status: item.status,
        start_date: item.startDate,
        end_date: item.endDate,
        month_of_edu: item.monthOfEdu,
        class_hours: item.classHours,
        number_of_session: item.numberOfSession,
        number_of_graduates: item.numberOfGraduates,
        number_of_sutdent: item.numberOfSutdent || '',
        time_number: item.timeNumber,
    }
    return convertedItem
}

/**
 * 강사관리
 * JSON 스네이크 -> 카멜케이스 변환
 * @param item
 * @returns {{sysRegrId: null, gender: *, sysModrId: null, sido: *, major: *, educationLevel: *, areaName: *, contact: *, tutorSortation: {isVeterans: *, hasDisability: *, isForeign: *, isAdvancedInstructor: *, isNew: *, classification: *, isRehired: *}, tutorEtcInfo: {evaluation: *, ownCarYn: *, localTalent: *, uniqueNum: *, continuPlacement: *, remarks: *}, email: *, tutorDepositInfo: {accountNum: *, accountHolderNm: *, bankNm: *}, tutorId: *, residentNum: *, sigungu: *, cohortStartYear: *, sysModDtime: null, tutorName: *, tutorQualifications: {teachingExperienceSw, signLanguageYn: *, privateCertification: *, teachingExperienceSwArrayStr: *, teachingExperienceGeneral, teachingExperienceYn: *, teacherCertification: *, swCertificationArrayStr: *, swCertification, teachingExperienceGeneralArrayStr: *}, tutorContractInfo: {employmentType: *, endDate: *, employmentStatus: *, startDate: *}, sysRegDtime: null, bname: *, regionId: *, tutorFoundedInfo: {dateOfEstablish: *, appointmentNum: *, businessRegistrationNumber: *, businessName: *, businessType: *, businessAddress: *, isFounded: *, taxStatus: *}, tutorProgramInstructorMatchingList: null}}
 */
export const convertTutorSnakeToCamelCase = item => {
    const tutorContractInfo = {
        employmentType: item.employment_type,
        employmentStatus: item.employment_status,
        startDate: item.start_date,
        endDate: item.end_date,
    }

    const tutorDepositInfo = {
        accountHolderNm: item.account_holder_nm,
        bankNm: item.bank_nm,
        accountNum: item.account_num,
    }

    const tutorFoundedInfo = {
        taxStatus: item.tax_status,
        businessType: item.business_type,
        businessAddress: item.business_address,
        businessName: item.business_name,
        dateOfEstablish: item.date_of_establish,
        isFounded: item.is_founded,
        businessRegistrationNumber: item.business_registration_number,
        appointmentNum: item.appointment_num,
    }

    // console.log('item.teaching_experience_general : ', item.teaching_experience_general)
    // console.log('item.teaching_experience_general.length : ', item.teaching_experience_general.length)

    const tutorQualifications = {
        teacherCertification: item.teacher_certification,
        teachingExperienceGeneral: item.teaching_experience_general
            ? typeof item.teaching_experience_general === 'string'
                ? item.teaching_experience_general.length > 1
                    ? item.teaching_experience_general.split(',')
                    : [item.teaching_experience_general] // Convert single value to an array
                : null
            : null,
        teachingExperienceSw: item.teaching_experience_sw
            ? typeof item.teaching_experience_sw === 'string'
                ? item.teaching_experience_sw.length > 1
                    ? item.teaching_experience_sw.split(',')
                    : [item.teaching_experience_sw] // Convert single value to an array
                : null
            : null,
        swCertification: item.sw_certification,
        signLanguageYn: item.sign_language_yn ? true : item.sign_language_yn === '' ? false : false,
        teachingExperienceYn: item.teaching_experience_yn,
        privateCertification: item.private_certification,
        teachingExperienceGeneralArrayStr: item.teaching_experience_general,
        teachingExperienceSwArrayStr: item.teaching_experience_sw,
        swCertificationArrayStr: item.sw_certification,
    }

    const tutorSortation = {
        // classification: item.classification,
        isYouth: item.is_youth,
        isRetiree: item.is_retiree,
        isCareerGapFemale: item.is_career_gap_female,
        isForeign: item.is_foreign,
        hasDisability: item.has_disability,
        isNew: item.is_new,
        isRehired: item.is_rehired,
        isAdvancedInstructor: item.is_advanced_instructor,
        isVeterans: item.is_veterans,
    }

    const tutorEtcInfo = {
        ownCarYn: item.own_car_yn,
        uniqueNum: item.unique_num,
        localTalent: item.local_talent,
        continuPlacement: item.continu_placement,
        evaluation: item.evaluation,
        remarks: item.remarks,
    }

    // 변환된 데이터 객체 생성
    const convertedItem = {
        tutorId: item.tutor_id.toString(),
        tutorName: item.tutor_name,
        sido: item.sido,
        sigungu: item.sigungu,
        bname: item.bname,
        residentNum: item.resident_num,
        contact: item.contact,
        email: item.email,
        gender: item.gender,
        regionId: item.region_id,
        cohortStartYear: item.cohort_start_year,
        major: item.major,
        educationLevel: item.education_level,
        areaName: item.area_name,
        tutorContractInfo,
        tutorDepositInfo,
        tutorFoundedInfo,
        tutorQualifications,
        tutorSortation,
        tutorEtcInfo,
        tutorProgramInstructorMatchingList: null,
        sysRegDtime: null,
        sysRegrId: null,
        sysModDtime: null,
        sysModrId: null,
    }

    return convertedItem
}

/**
 * 강사관리
 * JSON 카멜케이스 -> 스네이크 변환
 * @param item
 * @returns {{employment_type: *, is_advanced_instructor: *, is_veterans: *, is_new: *, has_disability: *, classification: *, is_rehired: *, is_foreign: *, evaluation: *, unique_num: *, local_talent: *, continu_placement: *, remarks: *, sign_language_yn: *, teaching_experience_yn: *, private_certification: *, teaching_experience_general: *, teaching_experience_sw: *, sw_certification: *, tutor_id: *, tutor_name: *, sido: *, sigungu: *, bname: *, resident_num: *, contact: *, email: *, gender: *, region_id: *, cohort_start_year: *, major: *, education_level: *, area_name: *, employment_status: *, start_date: *, end_date: *, account_holder_nm: *, bank_nm: *, account_num: *, tax_status: *, business_type: *, business_address: *, business_name: *, date_of_establish: *, is_founded: *, business_registration_number: *, appointment_num: *}}
 */
export const convertTutorCamelToSnakeCase = item => {
    const convertedItem = {
        tutor_id: item.tutorId,
        tutor_name: item.tutorName,
        sido: item.sido,
        sigungu: item.sigungu,
        bname: item.bname,
        resident_num: item.residentNum,
        contact: item.contact,
        email: item.email,
        gender: item.gender,
        region_id: item.regionId,
        cohort_start_year: item.cohortStartYear,
        major: item.major,
        education_level: item.educationLevel,
        area_name: item.areaName,
        employment_type: item.employmentType === '협동조합채용' ? '협동조합' : item.employmentType,
        employment_status: item.employmentStatus,
        start_date: item.startDate,
        end_date: item.endDate,
        account_holder_nm: item.accountHolderNm,
        bank_nm: item.bankNm,
        account_num: item.accountNum,
        tax_status: item.taxStatus,
        business_type: item.businessType,
        business_address: item.businessAddress,
        business_name: item.businessName,
        date_of_establish: item.dateOfEstablish,
        is_founded: item.isFounded,
        business_registration_number: item.businessRegistrationNumber,
        appointment_num: item.appointmentNum,
        teacher_certification: item.teacherCertification,
        teaching_experience_general: item.teachingExperienceGeneralArrayStr
            ? item.teachingExperienceGeneralArrayStr.join(',')
            : null,
        teaching_experience_sw: item.teachingExperienceSwArrayStr ? item.teachingExperienceSwArrayStr.join(',') : null,
        sw_certification: item.swCertification,
        sign_language_yn: item.signLanguageYn,
        teaching_experience_yn: item.teachingExperienceYn,
        private_certification: item.privateCertification,
        // classification: item.classification,
        is_youth: item.isYouth,
        is_retiree: item.isRetiree,
        is_career_gap_female: item.isCareerGapFemale,
        is_foreign: item.isForeign,
        has_disability: item.hasDisability,
        is_new: item.isNew,
        is_rehired: item.isRehired,
        is_advanced_instructor: item.isAdvancedInstructor,
        is_veterans: item.isVeterans,
        own_car_yn: item.ownCarYn,
        unique_num: item.uniqueNum,
        local_talent: item.localTalent,
        continu_placement: item.continuPlacement,
        evaluation: item.evaluation,
        remarks: item.remarks,
        sys_reg_dtime: item.sysRegDtime,
        sys_regr_id: item.sysRegrId,
        sys_mod_dtime: item.sysModDtime,
        sys_modr_id: item.sysModrId,
    }

    return convertedItem
}

/**
 * 월간보고 특이사항 배열
 * 스네이크 -> 카멜케이스
 * 컨버팅
 * @param item
 * @returns {{sys_reg_dtime: *, issue_main_points: (string|*), report_issue_id, report_id: *, sys_regr_id, sys_mod_dtime: null, issue_action_taken: *, issue_solution: *, is_delete: boolean, sys_modr_id: null, issue_occurrence_date: dayjs.Dayjs}}
 */
export const convertReportIssueSnakeToCamelCase = item => {
    const convertedItem = {
        reportIssueId: item.report_issue_id,
        reportId: item.report_id,
        isDelete: item.is_delete,
        sysRegDtime: item.sys_reg_dtime,
        sysRegrId: item.sys_regr_id,
        sysModDtime: item.sys_mod_dtime,
        sysModrId: item.sys_modr_id,
        mainPoints: item.issue_main_points,
        actionTaken: item.issue_action_taken,
        occurrenceDate: item.issue_occurrence_date,
        solution: item.issue_solution,
    }
    return convertedItem
}
/**
 * 월간보고
 * 스네이크 -> 카멜케이스
 * 컨버팅
 * @param item
 * @returns {{monthlyBudgetExecution: {total: *, governmentFunds: *, localInKindFunds: *, localCashFunds: *}, sysRegrId: (string|any), reportId: string, organizationName, dateOfReport: *, issue: {occurrenceDate: *, mainPoints: *, actionTaken: *, solution: *}, sysModDtime: (null|*), author, totalProjectBudget: {total: *, governmentFunds: *, localInKindFunds: *, localCashFunds: *}, accumulatedExpenditure: {total: *, inKind: *, cash: *}, sysModrId: (null|*), sysRegDtime: *, regionId: *, totalExecutionRate: {total: *, inKind: *, cash: *}, monthOfReport: string, overallResponsiblePerson: *, planAndPerformanceInfo: {monthlyPerformance: {details: *, expenditureAmount: *, ratio: *}, nextMonthPlane: {details: *, expenditureAmount: *, ratio: *}, otherBusiness: *, monthlyPlan: {details: *, expenditureAmount: *, ratio: *}, suspense: *}, projectName: *}}
 */
export const convertReportSnakeToCamelCase = item => {
    const convertedIssues = item.issue && convertReportIssueSnakeToCamelCase(item.issue)
    const today = dayjs().format('YYYY-MM-DD HH:mm:ss.SSSZ')

    return {
        reportId: item.report_id,
        regionId: item.region_id,
        monthOfReport: `${item.month_of_report}`,
        organizationName: item.organization_name,
        projectName: item.project_name,
        dateOfReport: today.replace(/[-T:.]/g, ''),
        author: item.author,
        overallResponsiblePerson: item.overall_responsible_person,
        totalProjectBudget: {
            governmentFunds: item.total_project_budget_government_funds,
            localCashFunds: item.total_project_budget_local_cash_funds,
            localInKindFunds: item.total_project_budget_localin_kind_funds,
            total: item.total_project_budget_total,
        },
        monthlyBudgetExecution: {
            governmentFunds: item.monthly_budget_execution_government_funds,
            localCashFunds: item.monthly_budget_execution_local_cash_funds,
            // localInKindFunds: item.monthly_budget_execution_localin_kind_funds,
            total: item.monthly_budget_execution_total,
        },
        // accumulatedExpenditure: {
        //     cash: item.accumulated_expenditure_cash,
        //     inKind: item.accumulated_expenditure_in_kind.trim(),
        //     total: item.accumulated_expenditure_total,
        // },
        totalExecutionRate: {
            cash: item.total_execution_rate_cash,
            inKind: item.total_execution_rate_in_kind,
            total: item.total_execution_rate_total,
        },
        planAndPerformanceInfo: {
            monthlyPlan: {
                details: item.monthly_plan_details, //(계획)당월 내용
                expenditureAmount: item.monthly_plan_expenditure_amount, //(계획)집행금액
                ratio: item.monthly_plan_ratio, //(계획)비율
            },
            monthlyPerformance: {
                details: item.monthly_performance_details, // 교육관리 내용
                eventDetails: item.monthly_event_details, // 행사관리 내용
                cumulativePerformanceNumber: item.cumulative_performance_number, // 실적 누적인원
                cumulativeMonthNumber: item.cumulative_month_number, // 실적 당월인원,
                cumulativePerformanceDetails: item.cumulative_performance_details, // 실적 누적내용
                cumulativeMonthDetails: item.cumulative_month_details, // 실적 당월내용
                performanceDetails: item.performance_details, // 세부 누적내용
                performanceMonthDetails: item.performance_month_details, // 세부 당월내용
                expenditureAmount: item.monthly_performance_expenditure_amount, // 교육관리 집행 금액
                ratio: item.monthly_performance_ratio, // 교육관리 비율
                regionId: item.region_id,
                monthOfReport: `${item.month_of_report}`, // 작성월
            },
            nextMonthPlane: {
                details: item.next_month_plan_details, // 익월 내용
                otherBusiness: item.other_business, // 기타업부
                suspense: item.suspense, // 미결사항
                expenditureAmount: item.next_month_plan_expenditure_amount, // 익월 집행금액
                ratio: item.next_month_plan_ratio, //익월 비율
            },
        },
        issue: convertedIssues,
        sysRegDtime: item.sys_reg_dtime,
        sysRegrId: item.sys_regr_id,
        sysModDtime: item.sys_mod_dtime,
        sysModrId: item.sys_modr_id,
    }
}

/**
 * 월간보고 특이사항
 * 배열 -> 객체로 변경
 * @param inputArray
 * @returns {{occurrenceDate: *, sysModrId: *, mainPoints: *, sysRegrId: *, actionTaken: *, reportId: *, sysRegDtime: *, sysModDtime: *, solution: *, reportIssueId: *, isDelete: *}[]|*[]}
 */
export const convertArrayToObject = inputArray => {
    if (inputArray.length === 0) {
        return [] // 빈 배열이면 빈 배열 반환
    }

    const firstItem = inputArray[0]

    const objects = Array.from({ length: firstItem.mainPoints.length }, (_, index) => ({
        reportIssueId: firstItem.reportIssueId,
        reportId: firstItem.reportId,
        isDelete: firstItem.isDelete,
        sysRegDtime: firstItem.sysRegDtime,
        sysRegrId: firstItem.sysRegrId,
        sysModDtime: firstItem.sysModDtime,
        sysModrId: firstItem.sysModrId,
        mainPoints: firstItem.mainPoints[index],
        actionTaken: firstItem.actionTaken[index],
        occurrenceDate: dayjs(firstItem.occurrenceDate[index]),
        solution: firstItem.solution[index],
    }))

    return objects
}

/**
 * 올해 range
 * @returns {{endDate: string, startDate: string}}
 */
export const createYearRange = () => {
    // 현재 연도 얻기
    const currentYear = dayjs().year()

    // 시작 날짜 (현재 연도 2023년 1월 1일)
    const startDate = dayjs(`${currentYear}-01-01`).format()

    // 종료 날짜 (2023년 12월 31일)
    const endDate = dayjs(`${currentYear + 1}-01-01`)
        .subtract(1, 'day')
        .format()

    return { startDate, endDate }
}

/**
 * 올해 range (1월부터 저번달 말일까지)
 * @returns {{endDate: string, startDate: string}}
 */
export const createYearRangeUntilLastDayOfLastMonth = selectedMonth => {
    // 기준 날짜로부터 연도 얻기
    const currentYear = dayjs(selectedMonth).year()

    // 시작 날짜 (현재 연도 2023년 1월 1일)
    const startDate = dayjs(`${currentYear}-01-01`).format()

    // 종료 날짜 (저번달 말일까지)
    const endDate = dayjs(selectedMonth).subtract(1, 'month').endOf('month').format()

    // 종료 날짜 (이번달 말일까지)
    // const endDate = dayjs().endOf('month').format()

    return { startDate, endDate }
}

/**
 *
 * @param  item
 * @returns convertedData
 * 수혜학생 통계 엑셀 export conver
 */
const convertExcelColToKor = (item = []) => {
    const convertedData = {
        연도: item.year,
        지역: item.region,
        '1-2시간': item['hour_1to2'],
        '3-8시간': item['hour_3to8'],
        '8-30시간': item['hour_8to30'],
        '30-50시간': item['hour_30to50'],
        '50시간이상': item.more50_hours,
        수혜율: item.benefited_ratio,
        채용율: item.hired_ratio,
        찾아가는교육비율: item.visiting_ratio,
        교육신청자수: item.number_of_applicants,
        교육수혜자수: item.number_of_benefited,
        강사채용수: item.number_of_trained,
        온라인교육: item.online,
        오프라인교육: item.offline,
        초등학교: item.element_school,
        중학교: item.middle_school,
        고등학교: item.high_school,
        특수학교: item.special_school,
        남성: item.male,
        여성: item.female,
        미래채움센터有: item.mirae_center_y,
        미래채움센터無: item.mirae_center_n,
        일반학생: item.regular_students,
        소외계층: item.underprivileged,
        일반학교: item.regular_school,
        '특수학교 (장애학교, 대안학교 등)': item.special_school_2,
        '기관 (아동복지센터 등)': item.welfare_center,
        센터내교육: item.in_center_edu,
        찾아가는교육: item.visiting_edu,
        기타: item.etc,
    }
    return convertedData
}

export const convertExcelColumnToKor = item => {
    const convertedData = item && item.map(convertExcelColToKor)

    return convertedData
}

/**
 *
 * @param  item
 * @returns convertedData
 * 강사 통계 엑셀 export conver
 */
const convertTutorExcelColToKor = (item = []) => {
    const convertedData = {
        연도: item.year,
        지역: item.region,
        강사채용수: item.hired_count,
        채용율: item.hired_ratio,
        '이탈인원 포함 합계': item.total_with_fail,
        신규강사: item.new_employee,
        재고용강사: item.reemployee,
        청년: item.youth,
        경력단절여성: item.online,
        전문퇴직자: item.career_stop_woman,
        '20~29세': item.age_20s,
        '30~39세': item.age_30s,
        '40~49세': item.age_40s,
        '50대이상': item.age_over_50,
        남성: item.male,
        여성: item.female,
        '창업(사업자등록완료)': item.own_bussiness,
        정규직: item.full_time_contract,
        계약직: item.contract_worker,
        '주 40시간 이상': item.over_40_week,
        '주 15 ~ 39시간': item.work_15to39_week,
        '주 15시간 미만': item.under_15_week,
        '4대보험 전부가입': item.full_insurance,
        '4대보험 일부가입': item.partial_insurance,
        '4대보험 미가입': item.no_insurance,
        '정규직 (무기계약직)': item.indefine_contract,
        '21개월~30개월(2차 재고용)': item.secondary_recontract,
        '11개월~20개월(1차 재고용)': item.first_recontract,
        '10개월 이내(최초고용)': item.first_contract,
        ICTSW관련전공: item.ictswY,
        ICTSW관련없음: item.ictswN,
        인문과학: item.humanity_major,
        사회과학: item.social_science_major,
        자연과학: item.natural_science_major,
        공학: item.tech_major,
        예체능: item.art_major,
        기타전공: item.etc_major,
        'SW강의경험 없음': item.no_sw_experience,
        'SW강의경험 1년 미만': item.sw_experience_under_year,
        'SW강의경험 1년이상 3년미만': item.sw_experience_one_to_three,
        'SW강의경험 3년이상': item.sw_experience_over_three,
        '기타 강의경험 없음': item.no_lec_experience,
        '기타 강의경험 1년 미만': item.lec_experience_under_year,
        '기타 강의경험 1년이상 3년미만': item.lec_experience_one_to_three,
        '기타 강의경험 3년이상': item.lec_experience_over_three,
        '강사 평균임금': item.average_wage,
    }
    return convertedData
}

export const convertTutorExcelColumnToKor = item => {
    const convertedData = item && item.map(convertTutorExcelColToKor)

    return convertedData
}

/**
 * 특이사항 비었는지 확인
 * @param issues
 * @returns {*|boolean}
 */
export const areAllFieldsEmpty = issues => {
    // issues 배열이 존재하고 배열 요소의 길이가 1 이상인지 확인
    if (Array.isArray(issues) && issues.length > 0) {
        // 모든 요소에 대해 확인
        return issues.every(issue => {
            // 현재 요소가 객체인지 확인
            if (typeof issue === 'object' && issue !== null) {
                // mainPoints, solution, actionTaken이 모두 비어있는지 확인
                return !issue.mainPoints && !issue.solution && !issue.actionTaken
            }
            // 객체가 아닌 경우도 처리 가능
            return false
        })
    }

    // issues 배열이나 첫 번째 요소가 없거나 형식이 맞지 않는 경우
    return false
}

export const isPropertyEmptyArray = (obj, propertyName) => {
    return Array.isArray(obj[propertyName]) && obj[propertyName].length === 0
}

/**
 * 주민등록번호 게산
 * @param jumin
 * @returns {number|null}
 */
export const getAgeFromJumin = jumin => {
    // 전달받은 주민번호 데이터에 '-' 확인 후 있으면 제거
    if (jumin.includes('-')) {
        jumin = jumin.replace('-', '')
    }
    // 전달받은 주민번호 데이터 자체에 문제가 있는 경우
    if (jumin.includes('-') == false && jumin.length != 13) {
        console.log('==================ERROR:주민등록번호 데이터 확인 필요')
        return false
    }
    let today = new Date() // 현재 날짜 및 시간
    let juminFront = jumin.substr(0, 6) // 주민번호앞자리
    let juminBackFirstVal = jumin.substr(6, 1) //주민번호뒷자리 첫 문자열(2000년도 이전생인지 확인)
    let age = 0
    let birthDate = null
    let juminYear = null
    let juminMonth = jumin.substr(2, 2) //10
    let juminDate = jumin.substr(4, 2) //03
    let monthCheck = 0
    // 1,2는 1900~1999년생 남녀, 5,6은 1900~1999년생 외국인 남녀
    if (juminBackFirstVal == 1 || juminBackFirstVal == 2 || juminBackFirstVal == 5 || juminBackFirstVal == 6) {
        // 2000년생 이전일 경우
        juminYear = '19' + jumin.substr(0, 2) //93~~
        // 문법상 Month(월)은 0부터 시작하기 때문에 -1 처리해야 됨.
        birthDate = new Date(juminYear * 1, juminMonth - 1, juminDate * 1)
        // 현재 연도에서 - 태어난 연도
        age = today.getFullYear() - birthDate.getFullYear()
        // 현재 월에서 - 태어난 월
        monthCheck = today.getMonth() - birthDate.getMonth()
        // 생일 월이 현재 월을 지나지 않았을 경우 만 나이기 때문에 -1
        if (monthCheck < 0 || (monthCheck === 0 && today.getDate() < birthDate.getDate())) {
            age--
        }
    } else {
        // 2000년생 이후
        juminYear = '20' + jumin.substr(0, 2) //01~~
        birthDate = new Date(juminYear * 1, juminMonth - 1, juminDate * 1)
        age = today.getFullYear() - birthDate.getFullYear()
        monthCheck = today.getMonth() - birthDate.getMonth()
        if (monthCheck < 0 || (monthCheck === 0 && today.getDate() < birthDate.getDate())) {
            age--
        }
    }
    return age
}

/**
 * UTC시간 -> korea 시간으로 컨버팅 (+9hour)
 */
export const convertUtcToKoreaTime = utcDateString => {
    // 작성월 UTC 시간
    const utcTime = dayjs(utcDateString).utc()
    // UTC에서 KST로 변환 (UTC+9) --- supabse는 utc시간을 사용하므로 자동 변환되면 -9시간이 되므로 그만큼 +를 해줌
    const koreaTime = utcTime.add(9, 'hour').toISOString()
    return koreaTime
}

// 주민번호 성별 구별
export const getGenderFromResidentNumber = residentNumber => {
    const lastDigit = parseInt(residentNumber.charAt(residentNumber.length - 1))
    if (lastDigit % 2 === 1) {
        return '남'
    } else {
        return '여'
    }
}

/**
 * 정규표현식을 사용하여 괄호 및 괄호 내의 숫자 제거
 * @param str
 * @returns {*}
 */
export const removeParentheses = str => {
    return str.replace(/\(\d+\)/g, '').trim()
}

/**
 * 엑셀 대량 등록시
 * 빈칸/문자열/배열 데이터 예외처리
 * @param item
 * @returns {*}
 */
export const processItem = item => {
    let result
    if (typeof item === 'string') {
        result = [item]
    } else if (Array.isArray(item)) {
        result = item.split(',')
    } else {
        result = null
    }
    return result
}

/**
 * 주민번호 유효성 검증
 * @param rule
 * @param value
 * @param callback
 */
export const validateJuminNumber = (rule, value, callback) => {
    if (!value) {
        callback('주민등록번호를 입력해주세요.')
    } else if (!/^\d{6}-\d{7}$/.test(value)) {
        callback('올바른 주민등록번호를 입력해주세요.')
    } else {
        callback()
    }
}

/**
 * 이메일 유효성 검증
 * @param rule
 * @param value
 * @param callback
 */
export const validateEmail = (rule, value, callback) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (!value) {
        callback('이메일 주소를 입력해주세요.')
    } else if (!emailRegex.test(value)) {
        callback('올바른 이메일 주소를 입력해주세요.')
    } else {
        callback()
    }
}

/**
 * 주민등록번호 뒷자리 마스킬
 * ex) 900101-1******
 * @param residentNumber
 * @returns {*}
 */
export const hideResidentNumber = residentNumber => {
    return residentNumber.slice(0, 8) + residentNumber.slice(8).replace(/\d/g, '*')
}

/**
 * 교육/강사 관리 페이지 필터링
 */
export const handlePageCountCommon = (search, list, pageSize) => {
    const currentPage = Number(new URLSearchParams(search).get('page')) || 1
    return {
        currentPage,
        totalItems: list.count,
        pageSize,
        totalPages: Math.ceil(list.count / pageSize),
    }
}

export const convertToolsInventorySnakeToCamelCase = item => {
    const convertedItem = {
        toolsId: item.tools_id,
        toolsName: item.tools_name,
        toolsType: item.tools_type,
        toolsNum: item.tools_num,
        acquireDate: item.acquire_date,
        purchasePrice: item.purchase_price.toLocaleString(),
        purchasePlace: item.purchase_place,
        toolsState: item.tools_state,
        defectReason: item.defect_reason,
        repairResult: item.repair_result,
        centerName: item.center_name,
        stockLocation: item.stock_location,
        batteryState: item.battery_state,
        disposeDate: item.dispose_date,
        manufacturer: item.manufacturer,
        subject: item.subject,
        regionId: item.region_id,
        isDelete: item.is_delete,
    }
    return convertedItem
}

export const convertToolsInventoryCamelToSnakeCase = item => {
    const convertedItem = {
        tools_name: item.toolsName,
        tools_type: item.toolsType,
        tools_num: item.toolsNum,
        acquire_date: item.acquireDate,
        purchase_price: Number(item.purchasePrice.replace(/,/g, '')),
        purchase_place: item.purchasePlace,
        tools_state: item.toolsState,
        defect_reason: item.defectReason,
        repair_result: item.repairResult ? item.repairResult : null,
        center_name: item.centerName,
        stock_location: item.stockLocation,
        battery_state: item.batteryState ? item.batteryState : null,
        dispose_date: item.disposeDate ? item.disposeDate : null,
        manufacturer: item.manufacturer,
        subject: item.subject,
    }
    return convertedItem
}

/**
 * 날짜 형식 검증 함수
 * @param {string} date - 검증할 날짜 값
 * @returns {boolean} - 날짜 형식이 올바르면 true, 아니면 false
 */
export const isValidDateFormat = date => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/
    return dateRegex.test(date)
}
