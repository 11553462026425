import { Link, useLocation } from 'react-router-dom'

import { HomeIcon } from 'assets/Icons'

import SelectBox from '../Select'

import styles from './breadcrumb.module.scss'

// 정규식
const programPathPattern = /^\/program\/\d+$/
const editProgramPathPattern = /^\/program\/edit\/(\d+)$/
const registerProgramBulkPathPattern = /^\/program\/register\/bulk$/
const registerProgramPathPattern = /^\/program\/register\/one$/

const tutorsPathPattern = /^\/tutors\/\d+$/
const editTutorsPathPattern = /^\/tutors\/edit\/(\d+)$/
const registertutorBulkPathPattern = /^\/tutors\/register\/bulk$/
const registertutorsPathPattern = /^\/tutors\/register\/one$/

const reportPathPattern = /^\/report\/\d+$/
const registerReportPathPattern = /^\/report\/register$/
const editReportPathPattern = /^\/report\/edit\/(\d+)$/

const registerToolPathPattern = /^\/tools\/register$/
const inventoryPathPattern = /^\/tools\/inventory$/
const inventoryDetailPathPattern = /^\/tools\/inventory\/(\d+)$/
const inventoryEditPathPattern = /^\/tools\/inventory\/edit\/\d+$/

const rentalPathPattern = /^\/tools\/rental$/
const rentalTransferPathPattern = /^\/tools\/rental\/transfer\/\d+$/

const statisticsTotalPathPattern = /^\/statistics\/total$/

const titleMap = [
    { pattern: programPathPattern, title: '교육계획 관리', altTitle: '교육계획 상세' },
    { pattern: registerProgramBulkPathPattern, title: '교육계획 대량등록' },
    { pattern: registerProgramPathPattern, title: '교육계획 단건등록' },
    { pattern: editProgramPathPattern, title: '교육계획 수정' },

    { pattern: tutorsPathPattern, title: '강사관리', altTitle: '강사정보 상세' },
    { pattern: registertutorBulkPathPattern, title: '강사정보 대량등록' },
    { pattern: registertutorsPathPattern, title: '강사정보 단건등록' },
    { pattern: editTutorsPathPattern, title: '강사정보 수정' },

    { pattern: reportPathPattern, title: '월간보고 상세' },
    { pattern: registerReportPathPattern, title: '월간보고 등록' },
    { pattern: editReportPathPattern, title: '월간보고 수정' },

    { pattern: statisticsTotalPathPattern, title: '성과 분석', altTitle: '통합 데이터 분석' },

    { pattern: inventoryPathPattern, title: '재고 관리' },
    { pattern: inventoryDetailPathPattern, title: '재고 관리 상세' },
    { pattern: registerToolPathPattern, title: '재고 등록' },
    { pattern: inventoryEditPathPattern, title: '재고정보 수정' },

    { pattern: rentalPathPattern, title: '대여반납 관리' },
    { pattern: rentalTransferPathPattern, title: '교구재 반출 리스트' },
]

// TODO: 지역관리자, 어드민 계정관리 분기처리
const firstRoutes = [
    {
        path: '/program',
        breadcrumbName: '교육계획 관리',
    },
    {
        path: '/tutors',
        breadcrumbName: '강사정보 관리',
    },
    {
        path: '/tools/inventory',
        breadcrumbName: '교구재 관리',
    },
    {
        path: '/statistics',
        breadcrumbName: '성과 분석',
    },
    {
        path: '/report',
        breadcrumbName: '월간보고',
    },
    // {
    //     path: '/survey',
    //     breadcrumbName: '수요조사',
    // },
    // {
    //     path: '/accounts',
    //     breadcrumbName: '계정관리',
    // },
    {
        path: '/contact',
        breadcrumbName: '문의하기',
    },
]

// 사이트 라우팅명 모음
const validPathTitles = ['program', 'tutors', 'tools', 'statistics', 'report', 'survey', 'accounts', 'contact']

/**
 * URL pathname에 따라 색상 및 default value 분기처리
 * @param selectedItems
 * @param setSelectedItems
 * @returns {JSX.Element}
 * @constructor
 */
const Breadcrumb = ({ selectedItems, setSelectedItems }) => {
    const { pathname } = useLocation()
    const paths = pathname.split('/').filter(path => path !== '')

    const getBreadcrumbColor = pathName => {
        const pathMap = {
            program: `${styles.layout} ${styles.program}`,
            tutors: `${styles.layout} ${styles.tutors}`,
            tools: `${styles.layout} ${styles.tools}`,
            statistics: `${styles.layout} ${styles.statistics}`,
            report: `${styles.layout} ${styles.report}`,
            survey: `${styles.layout} ${styles.survey}`,
            accounts: `${styles.layout} ${styles.accounts}`,
            contact: `${styles.layout} ${styles.contact}`,
        }

        return pathMap[pathName[0]] || ''
    }

    const getPathNameTitle = pathTitle => {
        if (!validPathTitles.includes(pathTitle)) {
            const pathResult = pathname.split('/')[0]

            const matchingTitle = titleMap.find(({ pattern }) => pattern.test(pathname))
            if (matchingTitle) {
                return pathResult === pathTitle ? matchingTitle.title : matchingTitle.altTitle || matchingTitle.title
            }
        }

        const pathMap = {
            program: '교육계획 관리',
            tutors: '강사정보 관리',
            tools: '교구재 관리',
            statistics: '성과 분석',
            report: '월간보고',
            survey: '수요조사',
            accounts: '계정관리',
            contact: '문의하기',
        }

        return pathMap[paths[0]] || ''
    }

    const pathArray = firstRoutes.map(item => ({
        value: item.path,
        label: item.breadcrumbName,
    }))

    return (
        <div className={getBreadcrumbColor(paths)}>
            <div className="breadcrumb_wrap">
                <Link to="/" className="home_btn" type="button">
                    <HomeIcon />
                </Link>
                <SelectBox
                    options={pathArray}
                    onChange={setSelectedItems}
                    value={selectedItems}
                    defaultValue={getPathNameTitle(paths[0])}
                    style={{ display: 'block' }}
                />
                {paths[1] && (
                    <div className={styles.second}>
                        <div className={styles.item}>{getPathNameTitle(paths[1])}</div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Breadcrumb
