import { useLocation } from 'react-router-dom'

import { Col, Form, InputNumber, Row } from 'antd'
import { formatCurrency, formatNumberToThousands, numberParser } from 'utils/utilCommon'

import styles from 'components/Report/report.module.scss'

/**
 * 총 집행률
 * @param totalExecutionRate
 * @returns {JSX.Element}
 * @constructor
 */
const TotalExecutionRate = ({ executionData, monthlyBudget, reportTotal }) => {
    const { pathname } = useLocation()
    const paths = pathname.split('/').filter(path => path !== '')

    const cash = Number(monthlyBudget?.governmentFunds) + Number(executionData?.executionGovernmentFunds || 0)
    const funds = Number(monthlyBudget?.localCashFunds) + Number(executionData?.executionLocalCashFunds || 0)
    // const total = (cash + funds).toLocaleString()

    const percent = (((cash + funds) / Number(reportTotal)) * 100).toFixed(2)
    const commonStyles = `${styles.right} ${styles.padding_10} ${styles.align_center_inline}`

    return (
        <div className={`${styles.card} ${styles.no_border_radius} ${styles.no_top_left_border_radius}`}>
            <div className={`${styles.head} ${styles.bottom_line}`}>
                <span className="h7">총 집행 금액</span>
            </div>

            <Row className={`${styles.bottom_line} ${styles.section_table} ${styles.space_between} ${styles.h245}`}>
                <Col span={9} className={`${styles.padding_10} ${styles.label} ${styles.align_center}`}>
                    현금
                    {(paths[1] === 'register' || paths[1] === 'edit') && <span className={styles.red}>*</span>}
                </Col>
                <Col span={15} className={`${commonStyles} ${styles.ellipsis}`}>
                    <Form.Item name="totalExecutionRateCash" rules={[{ required: true }]} noStyle>
                        {paths[1] === 'register' || paths[1] === 'edit' ? (
                            <InputNumber
                                name="totalExecutionRateCash"
                                className={`${styles.ant_input} ${styles.w160} ${styles.no_number_handler}`}
                                initialvalues={cash || 0}
                                formatter={formatNumberToThousands}
                                parser={numberParser}
                                placeholder="현금을 입력해주세요."
                                onKeyDown={e => (e.keyCode === 13 ? e.preventDefault() : '')}
                                readOnly
                            />
                        ) : (
                            <span className="sub_title2">{formatCurrency(cash || 0)}</span>
                        )}
                    </Form.Item>
                </Col>

                <Col span={9} className={`${styles.padding_10} ${styles.label} ${styles.align_center}`}>
                    현물
                    {(paths[1] === 'register' || paths[1] === 'edit') && <span className={styles.red}>*</span>}
                </Col>
                <Col span={15} className={`${commonStyles} ${styles.ellipsis}`}>
                    <Form.Item name="totalExecutionRateInKind" rules={[{ required: true }]} noStyle>
                        {paths[1] === 'register' || paths[1] === 'edit' ? (
                            <InputNumber
                                name="totalExecutionRateInKind"
                                className={`${styles.ant_input} ${styles.w160} ${styles.no_number_handler}`}
                                initialvalues={funds || 0}
                                formatter={formatNumberToThousands}
                                parser={numberParser}
                                placeholder="현물을 입력해주세요."
                                onKeyDown={e => (e.keyCode === 13 ? e.preventDefault() : '')}
                                readOnly
                            />
                        ) : (
                            <span className="sub_title2">{formatCurrency(funds || 0)}</span>
                        )}
                    </Form.Item>
                </Col>
            </Row>
            <Row className={`${styles.right} ${styles.section_total}`}>
                <Col span={24} className={styles.margin_b10}>
                    <span className="h7">집행률</span>
                </Col>
                <Col span={24}>
                    {paths[1] === 'register' || paths[1] === 'edit' ? (
                        <>
                            <span className={`sub_title1 ${styles.red}`}>
                                <Form.Item name="totalExecutionRateTotal" noStyle>
                                    <InputNumber
                                        className={`${styles.right} ${styles.total} ${styles.no_number_handler}`}
                                        size="large"
                                        style={{ width: '150px' }}
                                        formatter={value =>
                                            Number(value)
                                                .toFixed(2)
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                        }
                                        parser={value => value.replace(/(,*)/g, '')}
                                        initialvalues={percent || 0}
                                        onKeyDown={e => (e.keyCode === 13 ? e.preventDefault() : '')}
                                        disabled
                                    />
                                </Form.Item>
                            </span>
                            <span className={`sub_title1 ${styles.red}`}>%</span>
                        </>
                    ) : (
                        <span className="h7">{percent}%</span>
                    )}
                </Col>
            </Row>
        </div>
    )
}

export default TotalExecutionRate
