import { convertKeysToCamelCase } from 'utils/utilCommon'
import { supabase } from '../axios.instance'

/**
 * 대여 반납
 * 교구재 반출 리스트
 * @param regionId
 * @returns {Promise<PostgrestResponseSuccess<GetResult<any, any, R | unknown, "*">[]>|PostgrestResponseFailure|null>}
 */
export const getTransferToolsInventory = async regionId => {
    try {
        const response = await supabase
            .rpc('get_tools_inventory_rental', {
                region_id_param: regionId,
            })
            .select()
        return convertKeysToCamelCase(response)
    } catch (error) {
        console.error(error)
        return null
    }
}

/**
 * 대여 확정
 * @returns {Promise<{}|*|null>}
 * @param rentalId
 * @param toolsIds
 */
export const registerToolsRental = async ({ rentalId, toolsIds }) => {
    try {
        const response = await supabase
            .rpc('register_tools_rental', {
                rental_id_param: rentalId,
                tools_ids_param: toolsIds,
            })
            .select()
        return convertKeysToCamelCase(response)
    } catch (error) {
        console.error(error)
        return null
    }
}
