import { useLocation } from 'react-router-dom'

import FilterCount from './FilterCount'
import FilterArea from '../FilterArea'

import styles from '../filter.module.scss'

const FilterLayout = ({
    filterCount,
    updateQueryStringAndNavigate,
    handleResetQuerystring,
    startDateRange,
    setStartDateRange,
    endDateRange,
    setEndDateRange,
    isActiveLastMonth,
    setIsActiveLastMonth,
    setIsActiveThisMonth,
    isActiveThisMonth,
}) => {
    const { pathname } = useLocation()
    const paths = pathname.split('/').filter(path => path !== '')

    let propertyMap = {}
    if (paths.length > 1) {
        propertyMap = {
            rental: {
                first: 'totalCount',
                second: 'rentalApplicationCount',
                third: 'rentalConfirmationCount',
                fourth: 'rentalReturnedCount',
            },
            inventory: { first: 'totalCount', second: 'stock', third: 'repair', fourth: 'dispose' },
            survey: { first: 'totalCount', second: 'scheduled', third: 'progress', fourth: 'complete' },
        }
    } else {
        propertyMap = {
            program: { first: 'totalCount', second: 'scheduled', third: 'progress', fourth: 'complete' },
            tutors: {
                first: 'totalCount',
                second: 'newCount',
                third: 'rehiredCount',
                fourth: 'advancedCount',
            },
        }
    }

    const isSurvey = pathname.includes('survey')
    const { first, second, third, fourth } = isSurvey
        ? propertyMap.survey
        : propertyMap[paths.length > 1 ? paths[1] : paths[0]] || {}

    return (
        <div className={styles.wrap}>
            {paths[0] !== 'statistics' && paths[0] !== 'report' && (
                <FilterCount
                    firstCount={filterCount ? filterCount[first] : 0}
                    secondCount={filterCount ? filterCount[second] : 0}
                    thirdCount={filterCount ? filterCount[third] : 0}
                    forthCount={filterCount ? filterCount[fourth] : 0}
                />
            )}
            <FilterArea
                updateQueryStringAndNavigate={updateQueryStringAndNavigate}
                handleResetQuerystring={handleResetQuerystring}
                startDateRange={startDateRange}
                setStartDateRange={setStartDateRange}
                endDateRange={endDateRange}
                setEndDateRange={setEndDateRange}
                isActiveLastMonth={isActiveLastMonth}
                setIsActiveLastMonth={setIsActiveLastMonth}
                isActiveThisMonth={isActiveThisMonth}
                setIsActiveThisMonth={setIsActiveThisMonth}
            />
        </div>
    )
}

export default FilterLayout
