import React from 'react'
import { useSelector } from 'react-redux'
import { getAllUserInfo } from 'store/Slices/user'
import { Col, Row, Space, Form, Flex } from 'antd'
import Title from 'components/Ui/Title'
import Label from 'components/Ui/Label'
import TextField from 'components/AntD/TextField'
import styles from 'assets/scss/detail.module.scss'
import ColorButton from 'components/AntD/Button/ColorButton'
import { useNavigate, useLocation } from 'react-router-dom'
import SelectUi from 'components/Ui/Select/SelectUi'
import Value from 'components/Ui/Value'
import DatePickerInput from 'components/AntD/DatePicker'

const RegistrationAndEditFormTools = ({ toolsInventory, id, onFinish, onFinishFailed }) => {
    const navigate = useNavigate()
    const { userInfo } = useSelector(getAllUserInfo)
    const { pathname } = useLocation()

    const paths = pathname.split('/').filter(path => path !== '')
    const editOrRegisterPage = paths[2] === 'edit' || paths[2] === 'register'

    return (
        <Form initialvalues={toolsInventory} name="toolsInventory" onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <div className={styles.area}>
                <Row className={styles.mb_lg}>
                    <Col span={16}>
                        <Space direction="vertical" size={60}>
                            <div>
                                <Title title="교구 정보" />
                                <div className={`${styles.box_md} ${styles.border_style}`}>
                                    <Row className={styles.row_item}>
                                        <Col span={12}>
                                            <Row>
                                                <Col span={10}>
                                                    <Label label="교구명" required={editOrRegisterPage} />
                                                </Col>
                                                {editOrRegisterPage ? (
                                                    <Col span={12} className={styles.padding_top}>
                                                        <Form.Item
                                                            name="toolsName"
                                                            noStyle
                                                            rules={[{ required: true }]}
                                                        >
                                                            <TextField
                                                                name="toolsName"
                                                                val={toolsInventory?.toolsName}
                                                                placeholder="교구명을 입력해주세요."
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                ) : (
                                                    <Col span={12} className={styles.padding_y_medium}>
                                                        <Value value={toolsInventory?.toolsName} />
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className={styles.row_item}>
                                        <Col span={12}>
                                            <Row>
                                                <Col span={10}>
                                                    <Label label="품목분류" required={editOrRegisterPage} />
                                                </Col>
                                                {editOrRegisterPage ? (
                                                    <Col span={12} className={styles.padding_top}>
                                                        <Form.Item
                                                            name="toolsType"
                                                            noStyle
                                                            rules={[{ required: true }]}
                                                        >
                                                            <TextField
                                                                name="toolsType"
                                                                val={toolsInventory?.toolsType}
                                                                placeholder="품목분류를 입력해주세요."
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                ) : (
                                                    <Col span={12} className={styles.padding_y_medium}>
                                                        <Value value={toolsInventory?.toolsType} />
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                        <Col span={12}>
                                            <Row>
                                                <Col span={10}>
                                                    <Label label="자산번호" required={editOrRegisterPage} />
                                                </Col>
                                                {editOrRegisterPage ? (
                                                    <Col span={12} className={styles.padding_top}>
                                                        <Form.Item name="toolsNum" noStyle rules={[{ required: true }]}>
                                                            <TextField
                                                                name="toolsNum"
                                                                val={toolsInventory?.toolsNum}
                                                                placeholder="자산번호를 입력해주세요."
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                ) : (
                                                    <Col span={12} className={styles.padding_y_medium}>
                                                        <Value value={toolsInventory?.toolsNum} />
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div>
                                <Title title="자산 정보" />
                                <div className={`${styles.box_md} ${styles.border_style}`}>
                                    <Row className={styles.row_item}>
                                        <Col span={12}>
                                            <Row>
                                                <Col span={10}>
                                                    <Label label="취득일" required={editOrRegisterPage} />
                                                </Col>
                                                {editOrRegisterPage ? (
                                                    <Col span={12}>
                                                        <Form.Item
                                                            name="acquireDate"
                                                            noStyle
                                                            rules={[{ required: true }]}
                                                            initialValue={toolsInventory?.acquireDate}
                                                        >
                                                            <DatePickerInput name="acquireDate" />
                                                        </Form.Item>
                                                    </Col>
                                                ) : (
                                                    <Col className={`${styles.padding_y_medium}`}>
                                                        <Value type="date" value={toolsInventory?.acquireDate} />
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                        <Col span={12}>
                                            <Row>
                                                <Col span={10}>
                                                    <Label label="취득단가" required={editOrRegisterPage} />
                                                </Col>
                                                {editOrRegisterPage ? (
                                                    <Col span={12} className={styles.padding_top}>
                                                        <Form.Item
                                                            name="purchasePrice"
                                                            noStyle
                                                            rules={[{ required: true }]}
                                                        >
                                                            <TextField
                                                                name="purchasePrice"
                                                                val={toolsInventory?.purchasePrice}
                                                                placeholder="취득단가를 입력해주세요."
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                ) : (
                                                    <Col className={`${styles.padding_y_medium}`}>
                                                        <Value value={toolsInventory?.purchasePrice} />
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className={styles.row_item}>
                                        <Col span={5}>
                                            <Label label="구매처" required={editOrRegisterPage} />
                                        </Col>
                                        {editOrRegisterPage ? (
                                            <Col span={18} className={styles.padding_top}>
                                                <Form.Item name="purchasePlace" noStyle rules={[{ required: true }]}>
                                                    <TextField
                                                        name="purchasePlace"
                                                        val={toolsInventory?.purchasePlace}
                                                        placeholder="구매처를 입력해주세요."
                                                    />
                                                </Form.Item>
                                            </Col>
                                        ) : (
                                            <Col className={`${styles.padding_y_medium}`}>
                                                <Value value={toolsInventory?.purchasePlace} />
                                            </Col>
                                        )}
                                    </Row>
                                </div>
                            </div>
                            <div>
                                <Title title="품목별 교구 상태" />
                                <div className={`${styles.box_md} ${styles.border_style}`}>
                                    <Row className={styles.row_item}>
                                        <Col span={5}>
                                            <Label label="교구 상태" required={editOrRegisterPage} />
                                        </Col>
                                        {editOrRegisterPage ? (
                                            <Col span={12}>
                                                <Form.Item
                                                    name="toolsState"
                                                    noStyle
                                                    rules={[{ required: true }]}
                                                    initialValue={toolsInventory?.toolsState}
                                                >
                                                    <SelectUi
                                                        name="toolsState"
                                                        size="lg"
                                                        options={['정상', '고장', '폐기', '분실', '수리', '부품부족']}
                                                        value={toolsInventory?.toolsState}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        ) : (
                                            <Col className={`${styles.padding_y_medium}`}>
                                                <Value value={toolsInventory?.toolsState} />
                                            </Col>
                                        )}
                                    </Row>
                                    <Row className={styles.row_item}>
                                        <Col span={5}>
                                            <Label label="불량 사유" />
                                        </Col>
                                        {editOrRegisterPage ? (
                                            <Col span={13} className={styles.padding_top}>
                                                <Form.Item name="defectReason" noStyle>
                                                    <TextField
                                                        name="defectReason"
                                                        val={toolsInventory?.defectReason}
                                                        placeholder="불량 사유를 입력해주세요."
                                                    />
                                                </Form.Item>
                                            </Col>
                                        ) : (
                                            <Col className={`${styles.padding_y_medium}`}>
                                                <Value value={toolsInventory?.defectReason} />
                                            </Col>
                                        )}
                                    </Row>
                                    <Row className={styles.row_item}>
                                        <Col span={5}>
                                            <Label label="수리내역 결과" />
                                        </Col>
                                        {editOrRegisterPage ? (
                                            <Col span={19}>
                                                <Form.Item name="repairResult" noStyle>
                                                    <SelectUi
                                                        name="repairResult"
                                                        size="lg"
                                                        options={['수리중', '수리완료', '수리불가', '주문예정']}
                                                        value={toolsInventory?.repairResult}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        ) : (
                                            <Col>
                                                <SelectUi
                                                    size="lg"
                                                    disabled
                                                    options={['수리중', '수리완료', '수리불가', '주문예정']}
                                                    value={toolsInventory?.repairResult}
                                                />
                                            </Col>
                                        )}
                                    </Row>
                                </div>
                            </div>
                            <div>
                                <Title title="기타" />
                                <div className={`${styles.box_md} ${styles.border_style}`}>
                                    <Row className={styles.row_item}>
                                        <Col span={12}>
                                            <Row>
                                                <Col span={10}>
                                                    <Label label="센터명" />
                                                </Col>
                                                {editOrRegisterPage ? (
                                                    <Col span={12} className={styles.padding_top}>
                                                        <Form.Item name="centerName" noStyle>
                                                            <TextField
                                                                name="centerName"
                                                                val={toolsInventory?.centerName}
                                                                placeholder="센터명을 입력해주세요."
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                ) : (
                                                    <Col className={`${styles.padding_y_medium}`}>
                                                        <Value value={toolsInventory?.centerName} />
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                        <Col span={12}>
                                            <Row>
                                                <Col span={10}>
                                                    <Label label="재고 위치" />
                                                </Col>
                                                {editOrRegisterPage ? (
                                                    <Col span={12} className={styles.padding_top}>
                                                        <Form.Item name="stockLocation" noStyle>
                                                            <TextField
                                                                name="stockLocation"
                                                                val={toolsInventory?.stockLocation}
                                                                placeholder="재고 위치를 입력해주세요."
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                ) : (
                                                    <Col className={`${styles.padding_y_medium}`}>
                                                        <Value value={toolsInventory?.stockLocation} />
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className={styles.row_item}>
                                        <Col span={12}>
                                            <Row>
                                                <Col span={10}>
                                                    <Label label="제조사" />
                                                </Col>
                                                {editOrRegisterPage ? (
                                                    <Col span={12} className={styles.padding_top}>
                                                        <Form.Item name="manufacturer" noStyle>
                                                            <TextField
                                                                name="manufacturer"
                                                                val={toolsInventory?.manufacturer}
                                                                placeholder="제조사를 입력해주세요."
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                ) : (
                                                    <Col className={`${styles.padding_y_medium}`}>
                                                        <Value value={toolsInventory?.manufacturer} />
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                        <Col span={12}>
                                            <Row>
                                                <Col span={10}>
                                                    <Label label="과목(활용)" />
                                                </Col>
                                                {editOrRegisterPage ? (
                                                    <Col span={12} className={styles.padding_top}>
                                                        <Form.Item name="subject" noStyle>
                                                            <TextField
                                                                name="subject"
                                                                val={toolsInventory?.subject}
                                                                placeholder="과목(활용)을 입력해주세요."
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                ) : (
                                                    <Col className={`${styles.padding_y_medium}`}>
                                                        <Value value={toolsInventory?.subject} />
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className={styles.row_item}>
                                        <Col span={12}>
                                            <Row>
                                                <Col span={10}>
                                                    <Label label="배터리 상태" />
                                                </Col>
                                                {editOrRegisterPage ? (
                                                    <Col span={12} className={styles.padding_top}>
                                                        <Form.Item name="batteryState" noStyle>
                                                            <TextField
                                                                name="batteryState"
                                                                val={toolsInventory?.batteryState}
                                                                placeholder="배터리 상태를 입력해주세요."
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                ) : (
                                                    <Col className={`${styles.padding_y_medium}`}>
                                                        <Value value={toolsInventory?.batteryState} />
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                        <Col span={12}>
                                            <Row>
                                                <Col span={10}>
                                                    <Label label="폐기일자" />
                                                </Col>
                                                {editOrRegisterPage ? (
                                                    <Col span={12}>
                                                        <Form.Item
                                                            name="disposeDate"
                                                            noStyle
                                                            initialValue={toolsInventory?.disposeDate}
                                                        >
                                                            <DatePickerInput name="disposeDate" w100 />
                                                        </Form.Item>
                                                    </Col>
                                                ) : (
                                                    <Col className={`${styles.padding_y_medium}`}>
                                                        <Value value={toolsInventory?.disposeDate} />
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className={styles.row_item}>
                                        <Col span={5}>
                                            <Label label="비고" />
                                        </Col>
                                        {editOrRegisterPage ? (
                                            <Col span={18} className={styles.padding_top}>
                                                <Form.Item name="etc" noStyle>
                                                    <TextField
                                                        name="etc"
                                                        val={toolsInventory?.etc}
                                                        placeholder="비고를 입력해주세요."
                                                    />
                                                </Form.Item>
                                            </Col>
                                        ) : (
                                            <Col className={`${styles.padding_y_medium}`}>
                                                <Value value={toolsInventory?.etc} />
                                            </Col>
                                        )}
                                    </Row>
                                </div>
                            </div>
                        </Space>
                    </Col>
                </Row>
                <Flex justify="center" align="center" gap={8}>
                    {userInfo.regionId !== 'all' && (
                        <ColorButton
                            size="large"
                            type="primary"
                            htmlType="submit"
                            onClick={
                                editOrRegisterPage ? () => 'submit' : () => navigate(`/tools/inventory/edit/${id}`)
                            }
                        >
                            {editOrRegisterPage ? '저장' : '수정'}
                        </ColorButton>
                    )}
                    <ColorButton size="large" onClick={() => navigate('/tools/inventory')}>
                        목록
                    </ColorButton>
                </Flex>
            </div>
        </Form>
    )
}

export default RegistrationAndEditFormTools
