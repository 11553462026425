import { useLocation, useNavigate } from 'react-router-dom'

import {
    AdvancedTutorChipsIcon,
    ColorGraduationIcon,
    ColorLocationIcon,
    ContractBadgeIcon,
    ExpireBadgeIcon,
    NormalTutorChipsIcon,
    OnWorkingIcon,
} from 'assets/Icons'
import { colorTheme } from 'assets/colorTheme'
import { Col, Flex, Form, Row, Space } from 'antd'

import { useSelector } from 'react-redux'
import { getUser } from 'store/Slices/user'

import TutorClassification from 'components/Tutor/Edit/TutorClassification'
import TutorEducation from 'components/Tutor/Edit/TutorEducation'
import TutorCertification from 'components/Tutor/Edit/TutorCertification'
import ContractInfo from 'components/Tutor/Edit/ContractInfo'
import DepositInfo from 'components/Tutor/Edit/DepositInfo'
import FoundedInfo from 'components/Tutor/Edit/FoundedInfo'
import EtcInfo from 'components/Tutor/Edit/EtcInfo'
import TutorPersonalInfo from 'components/Tutor/Edit/TutorPersonalInfo'
import TutorName from 'components/Tutor/Edit/TutorName'

import IconCard from 'components/AntD/Card/IconCard'
import ColorButton from 'components/AntD/Button/ColorButton'
import Chips from 'components/AntD/Chips'
import TutorExperience from 'components/Tutor/Detail/TutorExperience'

import styles from 'assets/scss/detail.module.scss'

const RegistrationAndEditFormTutors = ({ tutor, onFinish, onFinishFailed }) => {
    const { pathname } = useLocation()
    const paths = pathname.split('/').filter(path => path !== '')
    const { isSubMaster } = useSelector(getUser)

    const isEditOrRegisterPage = paths[1] === 'edit' || paths[1] === 'register'

    const { gray, yellow, blue, green, red } = colorTheme
    const navigate = useNavigate()

    return (
        <div className={styles.area}>
            <TutorName tutorName={tutor?.tutorName || ''}>
                {tutor && tutor?.tutorContractInfo?.employmentStatus === '대기' && (
                    <Chips
                        color={yellow['800']}
                        icon={<ContractBadgeIcon />}
                        tooltip={tutor?.tutorContractInfo?.employmentStatus}
                    />
                )}
                {tutor && tutor?.tutorContractInfo?.employmentStatus === '재직중' && (
                    <Chips
                        color={blue['600']}
                        icon={<OnWorkingIcon />}
                        tooltip={tutor?.tutorContractInfo?.employmentStatus}
                    />
                )}
                {tutor && tutor?.tutorContractInfo?.employmentStatus === '계약만료' && (
                    <Chips
                        color={gray['500']}
                        icon={<ExpireBadgeIcon />}
                        tooltip={tutor?.tutorContractInfo?.employmentStatus}
                    />
                )}
                {/*TODO: 아이콘 변경*/}
                {tutor && (
                    <Chips
                        color={tutor?.tutorSortation?.isAdvancedInstructor ? green['400'] : red['700']}
                        icon={
                            tutor?.tutorSortation?.isAdvancedInstructor ? (
                                <AdvancedTutorChipsIcon />
                            ) : (
                                <NormalTutorChipsIcon />
                            )
                        }
                        tooltip={tutor?.tutorSortation?.isAdvancedInstructor ? '고급강사' : '일반강사'}
                    />
                )}
            </TutorName>
            <Form initialvalues={tutor} name="tutor" onFinish={onFinish} onFinishFailed={onFinishFailed}>
                <Row className={styles.mb_lg}>
                    <Col span={16}>
                        <Space direction="vertical" size={60}>
                            <TutorClassification
                                tutor_sortation={tutor?.tutorSortation}
                                isEditOrRegisterPage={isEditOrRegisterPage}
                            />
                            <TutorEducation
                                tutor_info={{
                                    educationLevel: tutor?.educationLevel || '',
                                    major: tutor?.major || '',
                                }}
                                isEditOrRegisterPage={isEditOrRegisterPage}
                            />
                            <TutorCertification
                                qualifications={tutor?.tutorQualifications}
                                isEditOrRegisterPage={isEditOrRegisterPage}
                            />

                            {!isEditOrRegisterPage && (
                                <TutorExperience matchingList={tutor?.tutorProgramInstructorMatchingList} />
                            )}

                            <ContractInfo
                                contract_info={tutor?.tutorContractInfo}
                                isEditOrRegisterPage={isEditOrRegisterPage}
                            />
                            <DepositInfo
                                deposit_info={tutor?.tutorDepositInfo}
                                isEditOrRegisterPage={isEditOrRegisterPage}
                            />
                            <FoundedInfo
                                founded_info={tutor?.tutorFoundedInfo}
                                isEditOrRegisterPage={isEditOrRegisterPage}
                            />
                            <EtcInfo
                                etc_info={{
                                    ...tutor?.tutorEtcInfo,
                                    cohortStartYear: tutor?.cohortStartYear || '',
                                    areaName: tutor?.areaName || '',
                                }}
                                isEditOrRegisterPage={isEditOrRegisterPage}
                            />
                        </Space>
                    </Col>
                    <Col span={8} className={styles.padding_left_large}>
                        <Space direction="vertical" size={32}>
                            <TutorPersonalInfo
                                tutor={{
                                    gender: tutor?.gender || '',
                                    residentNum: tutor?.residentNum || '',
                                    email: tutor?.email || '',
                                    contact: tutor?.contact || '',
                                    sido: tutor?.sido || '',
                                    sigungu: tutor?.sigungu || '',
                                    bname: tutor?.bname || '',
                                }}
                                paths={paths}
                                isEditOrRegisterPage={isEditOrRegisterPage}
                            />
                            <Flex gap={8}>
                                {paths[1] === 'register' ? (
                                    <>
                                        <IconCard
                                            icon={<ColorGraduationIcon />}
                                            label="기수"
                                            val={tutor?.cohortStartYear}
                                            name="cohortStartYear"
                                            placeholder="2023"
                                            paths={paths}
                                            navigate={navigate}
                                        />
                                        <IconCard
                                            icon={<ColorLocationIcon />}
                                            label="권역"
                                            val={tutor?.areaName}
                                            name="areaName"
                                            placeholder="동부권"
                                            paths={paths}
                                            navigate={navigate}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <IconCard
                                            icon={<ColorGraduationIcon />}
                                            label="기수"
                                            val={tutor?.cohortStartYear}
                                        />
                                        <IconCard icon={<ColorLocationIcon />} label="권역" val={tutor?.areaName} />
                                    </>
                                )}
                            </Flex>
                        </Space>
                    </Col>
                </Row>

                {!isEditOrRegisterPage ? (
                    <Flex justify="center" align="center" gap={8}>
                        {!isSubMaster && (
                            <ColorButton
                                size="large"
                                type="primary"
                                onClick={() => navigate(`/tutors/edit/${tutor?.tutorId}`)}
                            >
                                수정
                            </ColorButton>
                        )}
                        <ColorButton onClick={() => navigate(`/tutors`)} size="large">
                            목록
                        </ColorButton>
                    </Flex>
                ) : (
                    <Flex justify="center" align="center" gap={8}>
                        <ColorButton size="large" type="primary" htmlType="submit">
                            확인
                        </ColorButton>
                        {paths[1] === 'edit' && (
                            <ColorButton onClick={() => navigate(`/tutors/${tutor?.tutorId}`)} size="large">
                                취소
                            </ColorButton>
                        )}
                        {paths[1] === 'register' && (
                            <ColorButton onClick={() => navigate(`/tutors`)} size="large">
                                취소
                            </ColorButton>
                        )}
                    </Flex>
                )}
            </Form>
        </div>
    )
}

export default RegistrationAndEditFormTutors
