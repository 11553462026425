import PageBanner from 'layout/PageBanner'

import Register from 'components/Register/Bulk'
import { BannerIconTools } from 'assets/Icons'

const ToolsRentalRegisterBulk = () => {
    return (
        <PageBanner title="대여반납 관리 등록" bannerIcon={<BannerIconTools />}>
            <Register />
        </PageBanner>
    )
}

export default ToolsRentalRegisterBulk
