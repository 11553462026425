import { useLocation } from 'react-router-dom'
import { FilterCloseIcon, FilterResetIcon, OpenFilterIcon } from 'assets/Icons'

import { Tooltip } from 'antd'
import { colorTheme } from 'assets/colorTheme'

import styles from '../filter.module.scss'

const ExtraBtn = ({ handleResetQuerystring, isExpand, handleExpandFilter }) => {
    const { gray } = colorTheme || {}
    const { pathname } = useLocation()
    const path = pathname.split('/')[1]

    return (
        <div className={styles.extra_btn_wrap}>
            <Tooltip title="초기화" color={gray[900]}>
                <button
                    type="button"
                    className={`${styles.reset} button-reset-type`}
                    onClick={() => handleResetQuerystring()}
                >
                    <FilterResetIcon />
                </button>
            </Tooltip>
            {path !== 'tools' &&
                (isExpand ? (
                    <button
                        type="button"
                        className={`${styles.expand} button-reset-type`}
                        onClick={() => handleExpandFilter()}
                    >
                        <FilterCloseIcon />
                    </button>
                ) : (
                    <Tooltip title="세부 검색" color={gray[900]} open>
                        <button
                            type="button"
                            className={`${styles.expand} button-reset-type`}
                            onClick={() => handleExpandFilter()}
                        >
                            <OpenFilterIcon />
                        </button>
                    </Tooltip>
                ))}
        </div>
    )
}

export default ExtraBtn
