import FilterButton from 'components/AntD/Button/FilterButton'
import {
    CancelFilterIcon,
    FinishFilterBottomIcon,
    LongFilterIcon,
    OffFilterIcon,
    OngoingFilterIcon,
    ShortFilterIcon,
} from 'assets/Icons'

import styles from '../filter.module.scss'

const ProgramFilter = ({ queryParams, updateQueryStringAndNavigate }) => {
    const programOperation = 'status'
    const programTime = 'classHours'

    const currentProgramOperation = queryParams.get(programOperation) || ''
    const currentProgramTime = queryParams.get(programTime) || ''
    return (
        <>
            <span className={`body2 ${styles.title}`}>프로그램 운영</span>
            <div className={styles.btn_wrap}>
                <FilterButton
                    icon={<OffFilterIcon />}
                    title="예정"
                    currentParamValue={currentProgramOperation}
                    onClick={() => updateQueryStringAndNavigate(programOperation, '예정')}
                    filterColor="yellow"
                />
                <FilterButton
                    icon={<OngoingFilterIcon />}
                    title="진행"
                    currentParamValue={currentProgramOperation}
                    onClick={() => updateQueryStringAndNavigate(programOperation, '진행')}
                    filterColor="blue"
                />
                <FilterButton
                    icon={<FinishFilterBottomIcon />}
                    title="완료"
                    currentParamValue={currentProgramOperation}
                    onClick={() => updateQueryStringAndNavigate(programOperation, '완료')}
                    filterColor="darkgray"
                />
                <FilterButton
                    icon={<CancelFilterIcon />}
                    title="취소"
                    currentParamValue={currentProgramOperation}
                    onClick={() => updateQueryStringAndNavigate(programOperation, '취소')}
                    filterColor="border"
                />
            </div>
            <div className={styles.btn_wrap}>
                <FilterButton
                    icon={<ShortFilterIcon />}
                    title="단기"
                    currentParamValue={currentProgramTime}
                    onClick={() => updateQueryStringAndNavigate(programTime, '단기')}
                    filterColor="green"
                />
                <FilterButton
                    icon={<LongFilterIcon />}
                    title="장기"
                    currentParamValue={currentProgramTime}
                    onClick={() => updateQueryStringAndNavigate(programTime, '장기')}
                    filterColor="green"
                />
            </div>
        </>
    )
}

export default ProgramFilter
