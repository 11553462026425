import { useNavigate } from 'react-router-dom'

import {
    LongPeriod,
    MiddlePeriod,
    OnCancel,
    OnExpect,
    OnFinish,
    OnGoing,
    RightArrowIcon,
    ShortPeriod,
} from 'assets/Icons'
import Chips from 'components/AntD/Chips'
import { colorTheme } from 'assets/colorTheme'

import styles from './listItem.module.scss'

const STATUS_ICONS = {
    예정: <OnExpect />,
    진행: <OnGoing />,
    완료: <OnFinish />,
    취소: <OnCancel />,
}

const STATUS_COLORS = {
    예정: colorTheme.yellow['800'],
    진행: colorTheme.blue['600'],
    완료: colorTheme.gray['500'],
    취소: colorTheme.gray['300'],
}

const PERIOD_ICONS = {
    short: <ShortPeriod />,
    middle: <MiddlePeriod />,
    long: <LongPeriod />,
}

const PERIOD_COLORS = {
    short: colorTheme.green['400'],
    middle: colorTheme.green['400'],
    long: colorTheme.green['400'],
}

const getStatusChips = status => {
    const icon = STATUS_ICONS[status] || null
    const color = STATUS_COLORS[status] || colorTheme.gray['300']
    return <Chips color={color} icon={icon} tooltip={status} />
}

const getHourChips = hours => {
    let type
    if (hours < 8) type = 'short'
    else type = 'long'

    return <Chips color={PERIOD_COLORS[type]} icon={PERIOD_ICONS[type]} tooltip={type === 'short' ? '단기' : '장기'} />
}

const MainItem = ({ programName, programId, status, classHours, right }) => {
    const navigate = useNavigate()

    const handleClick = () => navigate(`/program/${programId}`)

    const handleKeyDown = e => {
        if (e.key === 'Enter' || e.key === ' ') {
            handleClick()
        }
    }

    const ItemContent = (
        <div className={styles.content}>
            <div className={styles.badge_wrap}>
                <span className={styles.badge}>
                    {getStatusChips(status)}
                    {getHourChips(classHours)}
                </span>
            </div>
            <span className="sub_title1">{programName}</span>
            <div className={styles.arrow}>
                <RightArrowIcon />
            </div>
        </div>
    )

    return right ? (
        <div
            className={styles.item}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            role="button"
            tabIndex="0"
            aria-label={`View details for ${programName}`}
        >
            {ItemContent}
        </div>
    ) : (
        <button type="button" className={styles.item} onClick={handleClick}>
            {ItemContent}
        </button>
    )
}

export default MainItem
