import React from 'react'

import { Col, Row } from 'antd'

import styles from 'components/Report/report.module.scss'

const BalanceSection = ({ total, execution, monthlyBudget }) => {
    const cash = Number(monthlyBudget?.governmentFunds) + Number(execution?.executionGovernmentFunds || 0)
    const funds = Number(monthlyBudget?.localCashFunds) + Number(execution?.executionLocalCashFunds || 0)
    return (
        <div className={`${styles.card} ${styles.top_border_radius} ${styles.no_top_border} `}>
            <Row className={styles.padding_24} gutter={10}>
                <Col span={18} className={styles.height_100}>
                    <span className="h7">잔액</span>
                </Col>
                <Col span={6}>
                    <Row className={styles.right} gutter={16}>
                        <Col span={12} className={`body2 ${styles.margin_b16}`}>
                            <span className={`${styles.label_section}`}>현금</span>
                        </Col>
                        <Col span={12} className={`body2 ${styles.margin_b16}`}>
                            {(total.government_funds + total.local_cash_funds - cash).toLocaleString()}
                        </Col>
                        <Col span={12} className={`body2 ${styles.ellipsis}`}>
                            <span className={`${styles.label_section}`}>현물</span>
                        </Col>
                        <Col span={12} className={`body2 ${styles.ellipsis}`}>
                            {(total.localin_kind_funds - funds).toLocaleString()}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default BalanceSection
