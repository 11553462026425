import { useLocation } from 'react-router-dom'
import { AllFilterIcon, FinishFilterIcon, IngFilterIcon, ReFilterIcon } from 'assets/Icons'

import styles from '../filter.module.scss'

const pageFilters = {
    rental: {
        first: '전체',
        second: '대여 신청',
        third: '대여',
        fourth: '반납',
    },
    inventory: {
        first: '전체',
        second: '정상',
        third: '분실',
        fourth: '고장',
    },
    program: {
        first: '전체',
        second: '예정',
        third: '진행',
        fourth: '완료',
    },
    tutors: {
        first: '전체강사',
        second: '채용인원',
        third: '재고용인원',
        fourth: '고급강사',
    },
    tools: {
        first: '전체',
        second: '재고',
        third: '대여',
        fourth: '폐기',
    },
    survey: {
        first: '전체',
        second: '예정',
        third: '진행',
        fourth: '완료',
    },
}
const FilterCount = ({ firstCount, secondCount, thirdCount, forthCount }) => {
    const { pathname } = useLocation()
    const paths = pathname.split('/').filter(path => path !== '')

    const handlePageFilterTitle = pathName => {
        if (pathName.length > 1) {
            return pathname.includes('survey') ? pageFilters[pathName[0]] : pageFilters[pathName[1]] || ''
        }
        return pageFilters[pathName] || ''
    }

    const filterTitle = handlePageFilterTitle(paths)

    return (
        <div className={styles.count_wrap}>
            <div className={styles.item}>
                <div className={styles.icon}>
                    <AllFilterIcon />
                </div>
                <span className="h5">{filterTitle.first}</span>
                <span className={`h5 ${styles.green}`}>{firstCount}</span>
            </div>
            <div className={styles.item}>
                <div className={styles.icon}>
                    <ReFilterIcon />
                </div>
                <span className="h5">{filterTitle.second}</span>{' '}
                <span className={`h5 ${styles.yellow}`}>{secondCount}</span>
            </div>
            {filterTitle.third && (
                <div className={styles.item}>
                    <div className={styles.icon}>
                        <IngFilterIcon />
                    </div>
                    <span className="h5">{filterTitle.third}</span>
                    <span className={`h5 ${styles.blue}`}>{thirdCount}</span>
                </div>
            )}
            {filterTitle.fourth && (
                <div className={styles.item}>
                    <div className={styles.icon}>
                        <FinishFilterIcon />
                    </div>
                    <span className="h5">{filterTitle.fourth}</span>
                    <span className={`h5 ${styles.gray}`}>{forthCount}</span>
                </div>
            )}
        </div>
    )
}

export default FilterCount
