import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form } from 'antd'

import ColorButton from 'components/AntD/Button/ColorButton'
import InfoDialog from 'components/AntD/Dialog'
import ToolRentalDialog from 'components/AntD/Dialog/ToolRentalDialog'

import { getAllUserInfo, getUser } from 'store/Slices/user'
import { rentalListAsync } from 'store/Slices/rental'

import styles from './listNotFound.module.scss'

const ListNotFound = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const { isMaster } = useSelector(getUser)
    const { userInfo } = useSelector(getAllUserInfo)

    const { pathname } = location
    const paths = pathname.split('/').filter(path => path !== '')
    const firstPath = paths[0]
    const secondPath = paths[1]
    const currentPath = window.location.pathname

    const [rentalModalOpen, setRentalModalOpen] = useState(false)

    const pathMessages = {
        main: '프로그램이',
        program: '프로그램이',
        tutors: '강사가',
        report: '월간보고가',
        survey: '수요조사가',
        contact: '문의가',
    }

    const pahTitle = {
        main: '프로그램',
        program: '프로그램',
        tutors: '강사',
        report: '월간보고',
        survey: '수요조사',
        contact: '문의하기',
    }

    const fetchRentalList = async search => {
        try {
            const { payload } = await dispatch(rentalListAsync({ search, regionId: userInfo.regionId, secondPath }))
            if (payload) {
                // setLoading(false)
                return true
            }
            console.error('계정 목록을 불러오는 데 문제가 발생했습니다.')
            return false
        } catch (error) {
            console.error('계정  목록을 불러오는 동안 오류가 발생했습니다.', error)
            return null
        }
    }

    return (
        <div className={styles.not_found_page_wrap}>
            <div className={styles.not_found_page_top}>
                <span className={styles.not_found_page_icon} />
                <div className={styles.not_found_page_title}>
                    {secondPath === 'rental' ? (
                        <span className="sub_title1">해당하는 대여반납 관리 내역이 존재하지 않습니다.</span>
                    ) : (
                        <span className="sub_title1">해당하는 {pathMessages[firstPath]} 존재하지 않습니다.</span>
                    )}
                </div>
            </div>
            {isMaster === false && (
                <>
                    {firstPath === 'report' && currentPath === '/report' && (
                        <div className={styles.not_found_page_bottom}>
                            <ColorButton type="primary" onClick={() => navigate(`/${firstPath}/register`)}>
                                {pahTitle[firstPath]} 등록
                            </ColorButton>
                        </div>
                    )}
                    {firstPath !== 'main' &&
                        firstPath !== 'survey' &&
                        firstPath !== 'report' &&
                        firstPath !== 'contact' &&
                        firstPath !== 'tools' && (
                            <div className={styles.not_found_page_bottom}>
                                <ColorButton type="primary" onClick={() => navigate(`/${firstPath}/register/bulk`)}>
                                    {pahTitle[firstPath]} 대량 등록
                                </ColorButton>
                                <ColorButton type="primary" onClick={() => navigate(`/${firstPath}/register/one`)}>
                                    {pahTitle[firstPath]} 단건 등록
                                </ColorButton>
                            </div>
                        )}
                    {firstPath === 'tools' && ['inventory'].includes(secondPath) && (
                        <div className={styles.not_found_page_bottom}>
                            <ColorButton
                                type="primary"
                                onClick={() => navigate(`/${firstPath}/${secondPath}/register/bulk`)}
                            >
                                {pahTitle[firstPath]} 대량 등록
                            </ColorButton>
                            <ColorButton
                                type="primary"
                                onClick={() => navigate(`/${firstPath}/${secondPath}/register/one`)}
                            >
                                {pahTitle[firstPath]} 단건 등록
                            </ColorButton>
                        </div>
                    )}
                    {firstPath === 'tools' && ['rental'].includes(secondPath) && (
                        <div className={styles.not_found_page_bottom}>
                            <ColorButton
                                type="primary"
                                onClick={() => navigate(`/${firstPath}/${secondPath}/register/bulk`)}
                            >
                                대량 등록
                            </ColorButton>
                            <ColorButton type="primary" onClick={() => setRentalModalOpen(true)}>
                                신규 대여
                            </ColorButton>
                        </div>
                    )}
                    <InfoDialog
                        content={
                            <ToolRentalDialog
                                form={form}
                                setModalOpen={setRentalModalOpen}
                                modalOpen={rentalModalOpen}
                                fetchList={fetchRentalList}
                            />
                        }
                        centered
                        modalOpen={rentalModalOpen}
                        setModalOpen={setRentalModalOpen}
                        closable={false}
                        isCancel
                        submit
                        hideFooter
                        type={2}
                    />
                    {firstPath === 'survey' && currentPath === '/survey' && (
                        <div className={styles.not_found_page_bottom}>
                            <ColorButton type="primary" onClick={() => navigate(`/${firstPath}/register`)}>
                                {pahTitle[firstPath]} 등록
                            </ColorButton>
                        </div>
                    )}
                    {firstPath === 'contact' && currentPath === '/contact' && (
                        <div className={styles.not_found_page_bottom}>
                            <ColorButton type="primary" onClick={() => navigate(`/${firstPath}/write`)}>
                                {pahTitle[firstPath]} 등록
                            </ColorButton>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default ListNotFound
