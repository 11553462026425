import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getToolsInventoryById, editToolsInventory } from 'api/tools/toolsInventory.api'
import { convertToolsInventoryCamelToSnakeCase } from 'utils/utilCommon'
import PageBanner from 'layout/PageBanner'
import Spinner from 'components/AntD/Spin'
import RegistrationAndEditFormTools from 'components/RegistrationAndEditForm/Tools'
import { BannerIconTools } from 'assets/Icons'
import style from 'assets/scss/detail.module.scss'
import { App } from 'antd'

const ToolsEdit = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const { regionId } = useSelector(state => state.user.userInfo)
    const [toolsInventoryDetail, setToolsInventoryDetail] = useState(null)
    const { message } = App.useApp()

    const fetchToolsInventory = useCallback(async () => {
        try {
            const { result } = await getToolsInventoryById(id, regionId)
            setToolsInventoryDetail(result[0])
        } catch (err) {
            console.error(err)
        } finally {
            setLoading(false)
        }
    }, [])

    const onFinish = value => {
        try {
            const formData = convertToolsInventoryCamelToSnakeCase(value)
            const updateToolsInventory = async () => {
                const response = await editToolsInventory(formData, toolsInventoryDetail.toolsId)
                if (response.status === 200 || response.status === 206) {
                    message.success('성공적으로 수정되었습니다')
                    navigate(`/tools/inventory/${id}`)
                }
            }
            updateToolsInventory()
        } catch (err) {
            console.error(err)
        }
    }
    const onFinishFailed = errorInfo => {
        message.error('필수값을 확인해주세요')
        console.log('Failed:', errorInfo)
    }

    useEffect(() => {
        fetchToolsInventory()
    }, [])

    return (
        <PageBanner title="교구재 재고정보 수정" bannerIcon={<BannerIconTools />}>
            <div className={style.detail_padding}>
                {loading ? (
                    <div className={style.spin_wrap}>
                        <Spinner />
                    </div>
                ) : (
                    <RegistrationAndEditFormTools
                        toolsInventory={toolsInventoryDetail}
                        id={id}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    />
                )}
            </div>
        </PageBanner>
    )
}

export default ToolsEdit
