import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import dayjs from 'dayjs'
import { Flex, Form, Space, Col, Row, Input, DatePicker, message } from 'antd'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import CustomEditor from 'ckeditor5-custom-build/build/ckeditor'
import { uploadPlugin } from 'utils/ckeditorPlugin'

import 'assets/scss/samples/Editor.scss'
import { BannerIconSurvey, RegisterCompleteIcon } from 'assets/Icons'
import { clearBucketFolder, handleRegistrationPost } from 'utils/utilS3Bucket'

import { postSurvey } from 'api/survey/survey.api'

import { useSelector } from 'react-redux'
import { getAllUserInfo } from 'store/Slices/user'

import PageBanner from 'layout/PageBanner'

import ColorButton from 'components/AntD/Button/ColorButton'
import Label from 'components/Ui/Label'
import CopyURLButton from 'components/CopyClipboard'

import style from 'assets/scss/detail.module.scss'

const s3URL = process.env.REACT_APP_S3_URL
const cloudfrontURL = process.env.REACT_APP_CLOUDFRONT_URL
const dateFormat = 'YYYY-MM-DD'
const essentialMessagse = '필수항목을 확인해주세요.'

const SurveyRegister = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const uuid = uuidv4()

    const { userInfo } = useSelector(getAllUserInfo)
    const { userId } = userInfo

    const [content, setContent] = useState('') // 수요조사 내용
    const [isRegistered, setIsRegistered] = useState(false)
    const [surveyUuid, setSurveyUuid] = useState(null)
    const [startDate, setStartDate] = useState(null) // 모집 시작일 & 마감일 최소 일자

    const urlLink = `${process.env.REACT_APP_BASE_URL}/survey/submit/${surveyUuid}`

    const [messageApi, contextHolder] = message.useMessage()

    // const CATEGORY_ID = '000001' // 임시 게시판 카테고리 ID
    // const CONTENT_ID = '1' // 작성글 ID

    const errorMessage = errorMessage => {
        messageApi.open({
            type: 'error',
            content: errorMessage,
            duration: 5,
        })
    }

    const onFinish = async values => {
        if (!content) {
            errorMessage(essentialMessagse)
        } else {
            const { surveyName, startDate, endDate } = values
            const data = {
                survey_uuid: uuid,
                region_id: userInfo.regionId,
                survey_name: surveyName,
                is_delete: false,
                start_date: startDate,
                end_date: endDate,
                sys_reg_dtime: dayjs(),
                sys_regr_id: userId,
                sys_mod_dtime: null,
                sys_modr_id: null,
                url_link: null,
                survey_content: content,
            }

            const response = await postSurvey(data)
            if (response.status === 201) {
                const { data } = response
                setSurveyUuid(data[0]?.survey_uuid)

                const surveyId = data[0]?.survey_id

                await handleRegistrationPost(surveyId, s3URL, cloudfrontURL, content, userId)
                //TODO: 204 status 분기처리 추가
                setIsRegistered(true)
            } else {
                errorMessage(essentialMessagse)
            }
        }
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo)
        errorMessage(essentialMessagse)
    }

    // component unmount 시(라우팅 변경, 뒤로 가기 등) 임시폴더 내 이미지 파일 모두 삭제
    useEffect(() => {
        return async () => {
            await clearBucketFolder(userId)
        }
    }, [])

    const handleStartDateChange = date => {
        setStartDate(date)
        form.setFieldValue('endDate', null)
    }

    return (
        <PageBanner title="수요조사 등록" bannerIcon={<BannerIconSurvey />}>
            {contextHolder}
            {isRegistered ? (
                <div className="complete">
                    <Space direction="vertical" align="center">
                        <RegisterCompleteIcon />
                        <span className="txt">수요조사 등록이 완료 되었습니다. </span>
                        <span>{urlLink} </span>
                        <CopyURLButton url={urlLink} type="button" text="주소 복사하기" />
                    </Space>
                </div>
            ) : (
                <div className="editor-wrap">
                    <Form
                        name="survey"
                        form={form}
                        labelCol={{ span: 8 }}
                        initialvalues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <div className="border">
                            <Row className={style.border_btm}>
                                <Col span={4}>
                                    <Label label="수요조사 제목" required />
                                </Col>
                                <Col className={style.padding_top}>
                                    {/*<TextField name="title" size="lg" />*/}
                                    <Form.Item name="surveyName" rules={[{ required: true, message: '' }]} noStyle>
                                        <Input
                                            name="surveyName"
                                            className={style.ant_input}
                                            placeholder="수요조사 제목을 입력해주세요."
                                            // onKeyDown={e => (e.keyCode === 13 ? e.preventDefault() : '')}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={4}>
                                    <Label label="모집 시작일" required />
                                </Col>
                                <Col span={6} className={style.col}>
                                    <Form.Item name="startDate" rules={[{ required: true, message: '' }]} noStyle>
                                        <DatePicker
                                            name="startDate"
                                            className={style.date_picker}
                                            format={dateFormat}
                                            placeholder="날짜를 선택해주세요"
                                            onChange={handleStartDateChange}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Label label="모집 마감일" required />
                                </Col>
                                <Col span={6} className={style.col}>
                                    <Form.Item name="endDate" rules={[{ required: true, message: '' }]} noStyle>
                                        <DatePicker
                                            name="endDate"
                                            className={style.date_picker}
                                            format={dateFormat}
                                            placeholder="날짜를 선택해주세요"
                                            disabledDate={current =>
                                                current && current < (startDate ? startDate.startOf('day') : null)
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>

                        <Form.Item>
                            <CKEditor
                                editor={CustomEditor}
                                config={{
                                    extraPlugins: [uploadPlugin], // 플러그인 추가
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData()
                                    setContent(data)
                                }}
                                placeholder="예시"
                                onUpload
                            />
                        </Form.Item>
                        <Flex justify="center" gap={8}>
                            <ColorButton type="primary" htmlType="submit">
                                수요조사 등록
                            </ColorButton>
                            <ColorButton onClick={() => navigate('/survey')}>취소</ColorButton>
                        </Flex>
                    </Form>
                </div>
            )}
        </PageBanner>
    )
}
export default SurveyRegister
