import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import PageBanner from 'layout/PageBanner'

import { useSelector } from 'react-redux'
import { getAllUserInfo } from 'store/Slices/user'
import { getToolsInventoryList } from 'api/tools/toolsInventory.api'

import { BannerIconTools } from 'assets/Icons'

import ListView from 'components/List/ListView'
import Pagenation from 'components/Pagenation'

import Spinner from 'components/AntD/Spin'

import FilterAndSearchArea from 'components/Filter'
import ListNotFound from '../../PageNotFound/ListNotFound'

const ToolsInventory = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const path = pathname.split('/')[2]
    const queryParams = new URLSearchParams(window.location.search)
    const { isLogin, userInfo } = useSelector(getAllUserInfo)

    const [isLoading, setLoading] = useState(true)
    const [sortName, setSortName] = useState('createdDt')
    const [inputValue, setInputValue] = useState('')

    const [toolsInventory, setToolsInventoryList] = useState(null)

    const { filterCountInfo, toolsInventoryList, page } = toolsInventory || {}
    const { currentPage, totalPages, totalItems } = page || {}
    const { isMaster, isSubMaster } = userInfo

    const search = 'search'

    /**
     * querystring update
     * @param paramName
     * @param paramValue
     * @param loading
     */
    const updateQueryStringAndNavigate = (paramName, paramValue, loading) => {
        const { pathname } = location
        const previousParams = queryParams.get(paramName)

        // 기존값과 동일할 시 변경하지 않음
        if (paramName === 'regionId' && paramValue === previousParams) {
            return
        }

        // 새로운 파라미터 추가 (단, 값이 비어있지 않을 때)
        if (paramValue) {
            if (previousParams === paramValue) {
                if (paramName !== 'sort' && paramName !== 'search' && paramName !== 'regionId') {
                    queryParams.delete(paramName)
                }
            } else {
                queryParams.set(paramName, paramValue)
            }
        } else {
            queryParams.delete(paramName)
        }
        // 정렬 active
        if (paramName === 'sort') {
            setSortName(paramValue)
        }

        const newQueryString = queryParams.toString()
        const newLocation = newQueryString ? `${pathname}?${newQueryString}` : pathname

        if (loading) {
            loading(false)
        }
        navigate(newLocation)
    }

    const handleSearch = () => {
        setLoading(true)
        updateQueryStringAndNavigate(search, inputValue, setLoading)
    }

    const fetchToolsInventoryList = async search => {
        try {
            const data = await getToolsInventoryList(search, userInfo.regionId, path)
            if (data.status === 200 || data.status === 206) {
                setToolsInventoryList(data.data)
                setLoading(false)
                return true
            }
            console.error('재고 관리 목록을 불러오는 데 문제가 발생했습니다.')
            return false
        } catch (error) {
            console.error('재고 관리 목록을 불러오는 동안 오류가 발생했습니다.', error)
            return null
        }
    }

    useEffect(() => {
        if (isLogin && !userInfo.isServiceManager) {
            const { search } = location
            const searchPath = search.split('?')[1] || ''

            fetchToolsInventoryList(searchPath)
        } else {
            navigate('/')
        }
    }, [location])

    useEffect(() => {
        if ((isMaster || isSubMaster) && !queryParams.get('regionId')) {
            queryParams.set('regionId', 'all')
            navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true })
        }
    }, [isMaster, isSubMaster, location, navigate, queryParams])

    return (
        <PageBanner title="재고 관리" bannerIcon={<BannerIconTools />}>
            {filterCountInfo && (
                <FilterAndSearchArea
                    filterCount={filterCountInfo}
                    updateQueryStringAndNavigate={updateQueryStringAndNavigate}
                    sortName={sortName}
                    setInputValue={setInputValue}
                    inputValue={inputValue}
                    handleSearch={handleSearch}
                />
            )}
            {isLoading ? (
                <Spinner />
            ) : toolsInventoryList.length === 0 ? (
                <ListNotFound />
            ) : (
                <>
                    <ListView
                        list={toolsInventoryList.slice(0, 10)}
                        setLoading={setLoading}
                        fetchList={fetchToolsInventoryList}
                    />
                    <Pagenation
                        title="재고 관리 대량등록"
                        subTitle="재고 관리 단건등록"
                        navigateBtn={() => navigate('/tools/inventory/register/bulk')}
                        subNavigateBtn={() => navigate('/tools/inventory/register/one')}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        totalItems={totalItems}
                        updateFtn={updateQueryStringAndNavigate}
                    />
                </>
            )}
        </PageBanner>
    )
}

export default ToolsInventory
