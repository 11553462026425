import { Divider, Image, Tooltip } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import ReactECharts from 'echarts-for-react'
import ratio1 from 'assets/images/ratio1.png'
import ratio2 from 'assets/images/ratio2.png'
import styles from './chart.module.scss'

const DonutChart = ({ dataSource, dataName, chartTitle, title = '강사', colors, tooltip }) => {
    const getEChartsOption = data => {
        if (!data || data.length === 0) {
            return {}
        }

        // Assuming dataSource has properties totalCount and partOfCount
        const total = data.totalCount
        const partOf = data.partOfCount

        let totalPercentage = 0
        let partOfPercentage = 0

        if (total !== 0) {
            totalPercentage = ((total / (total + partOf)) * 100).toFixed(2)
        }
        if (partOf !== 0) {
            partOfPercentage = ((partOf / (total + partOf)) * 100).toFixed(2)
        }

        return {
            backgroundColor: '#fff',
            color: colors,
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b} : {c}%',
            },
            title: {
                text: chartTitle,
                left: 'center',
                top: 40,
                textStyle: {
                    fontWeight: 'bold',
                    fontSize: 16,
                },
            },

            series: [
                {
                    name: chartTitle,
                    type: 'pie',
                    radius: ['50%', '15%'],
                    center: ['50%', '50%'],
                    data: [
                        { value: totalPercentage, name: dataName[0] },
                        { value: partOfPercentage, name: dataName[1] },
                    ],
                    avoidLabelOverlap: false,
                    label: {
                        fontSize: 18,
                        fontWeight: 700,
                        show: true,
                        align: 'left',
                        formatter(params) {
                            return `{a|${params.name}}\n {b|${params.percent}%}`
                        },
                        rich: {
                            a: {
                                fontSize: 18,
                                fontWeight: 700,
                                lineHeight: 42,
                                textAlign: 'left',
                                color: 'black',
                            },
                            b: {
                                fontSize: 32,
                                fontWeight: 700,
                                lineHeight: 42,
                                textAlign: 'left',
                                color: colors[0],
                            },
                        },
                    },

                    emphasis: {
                        scale: true,
                        label: {
                            show: true,
                        },
                    },
                    tooltip: {
                        show: false,
                    },
                    labelLine: {
                        lineStyle: {
                            color: '#E5E5E5',
                        },
                        smooth: 0,
                        length: 100,
                        length2: 10,
                        minTurnAngle: 70,
                    },
                },
            ],
        }
    }

    return (
        <>
            <h5 className={styles.title}>
                {title}
                <Tooltip placement="top" title={tooltip}>
                    <ExclamationCircleOutlined style={{ fontSize: '24px', color: '#fff', marginLeft: '12px' }} />
                </Tooltip>
            </h5>
            <div className={styles.wrapper}>
                <ReactECharts style={{ height: 320 }} option={getEChartsOption(dataSource)} />
                <div className="hide">
                    <Divider orientation="left">
                        <div className={styles.subTitle}>{title} 변화</div>
                    </Divider>
                    <div className={styles.flexCenter}>
                        <Image src={title === '수혜학생비율' ? ratio1 : ratio2} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default DonutChart
