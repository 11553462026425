import { useCallback, useEffect, useState } from 'react'
import { BannerIconProgram } from 'assets/Icons'

import PageBanner from 'layout/PageBanner'
import { useNavigate, useParams } from 'react-router-dom'

import { editProgram, getProgramById } from 'api/program/program.api'
import { deleteProgramAttachmentById, upsertProgramAttachment } from 'api/program/program_attachment.api'
import {
    softDeleteProgramInstructorById, upsertProgramInstructor
} from 'api/program/program_instructor_matching.api'

import { useDispatch, useSelector } from 'react-redux'
import { addProgram } from 'store/Slices/programs'
import { App } from 'antd'

import Spinner from 'components/AntD/Spin'
import RegistrationAndEditFormPrograms from 'components/RegistrationAndEditForm/Programs'

import { copyBucketFolderList, deleteBucketFolder } from 'utils/utilS3Bucket'
import { convertProgramCamelToSnakeCase } from 'utils/utilCommon'
import styles from 'assets/scss/detail.module.scss'

const ProgramEdit = () => {
    const [loading, setLoading] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false)

    const { id } = useParams()
    const dispatch = useDispatch()
    const { message } = App.useApp()

    const navigate = useNavigate()
    const program = useSelector(state => state.program.program)
    const userId = useSelector(state => state.user?.userInfo?.userId)

    const fetchProgram = useCallback(async () => {
        try {
            const data = await getProgramById(id)
            dispatch(addProgram(data))
            setLoading(false)
        } catch (err) {
            setLoading(false)
        }
    }, [id, dispatch])

    useEffect(() => {
        setTimeout(fetchProgram, 500)
    }, [fetchProgram])

    const matchingFilter = (arr = []) => {
        const newArray = arr.map(item => {
            const { id, instructor_matching_no, attachment_no, ...rest } = item
            return rest
        })

        return newArray
    }

    const convertEmptyStringToNull = data => {
        if (!Array.isArray(data)) {
            console.log('입력된 데이터는 배열이 아닙니다.')
            return false
        }
        return data.map(entry => {
            if (entry.class_hours === '') {
                entry.class_hours = null
            }
            return entry
        })
    }

    const onFinish = async value => {
        const formData = convertProgramCamelToSnakeCase(value)
        const updateProgram = async () => {
            try {
                const editResponse = await editProgram({
                    ...formData,
                    class_hours: program?.programOperationInfo?.classHours,
                    number_of_graduates: program?.programOperationInfo?.numberOfGraduates,
                    number_of_applicants: program?.programOperationInfo?.numberOfApplicants,
                    region_id: program.regionId,
                    program_id: program.programId,
                })
                if (editResponse.status === 204) {
                    // 업로드파일이 동일하지 않으면
                    if (JSON.stringify(program.programAttachmentList) !== JSON.stringify(value.programAttachmentList)) {
                            const deleteAttachmentResponse = await deleteProgramAttachmentById(program.programId)
                            if (deleteAttachmentResponse.status === 204 || deleteAttachmentResponse.status === 200) {
                                const updatedProgramAttachmentList = value.programAttachmentList.map(item => ({
                                    ...item,
                                    is_delete: false,
                                }));
                                const existingAttachments = updatedProgramAttachmentList.filter(item => item.attachment_no);
                                const newAttachments = updatedProgramAttachmentList.filter(item => !item.attachment_no);
                                // 기존 업로드 파일
                                if (existingAttachments.length > 0) {
                                    await upsertProgramAttachment(existingAttachments)
                                }
                                // 새로운 업로드 파일
                                if (newAttachments.length > 0) {
                                    await upsertProgramAttachment(newAttachments)
                                }
                            }
                    }
                    // 강사매칭 정보 soft 삭제
                    const deleteMatchingResponse = await softDeleteProgramInstructorById(program.programId)
                    // 강사매칭 정보 다시 등록
                    if (deleteMatchingResponse.status === 204 || deleteMatchingResponse.status === 200) {
                        const matchingList = matchingFilter(value.programInstructorMatchingList)
                        const convertClassHour = convertEmptyStringToNull(matchingList)
                        const filteredData = convertClassHour.filter(entry => entry.tutor_id !== '')
                        await upsertProgramInstructor(filteredData)
                    }
                }
            } catch (error) {
                // 각 작업에서 오류가 발생했을 때 처리할 코드
                message.error('수정 중 오류가 발생했습니다 : ')
                // 오류 처리
            }

            await copyBucketFolderList(value.programAttachmentList, userId)
            await deleteBucketFolder(value.programAttachmentList, userId)
            message.success('성공적으로 수정되었습니다')
            navigate(`/program/${program.programId}`)
        }

        await updateProgram()
    }

    const onFinishFailed = errorInfo => {
        message.error('필수값을 확인해주세요')
        console.log('Failed:', errorInfo)
    }

    return (
        <PageBanner title="교육계획 수정" icon={<BannerIconProgram />}>
            <div className={styles.area}>
                {program && !loading ? (
                    <RegistrationAndEditFormPrograms
                        program={program}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                    />
                ) : (
                    <div className={styles.spin_wrap}>
                        <Spinner />
                    </div>
                )}
            </div>
        </PageBanner>
    )
}

export default ProgramEdit
