import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    toolsInventory: [],
    toolsInventoryCount: null,
}

const inventorySlice = createSlice({
    name: 'tools',
    initialState,
    reducers: {
        addToolsInventory: (state, action) => {
            state.toolsInventory = action.payload
        },
        addToolsInventoryCount: (state, action) => {
            state.toolsInventoryCount = action.payload
        },
    },
})

export const { addToolsInventory, addToolsInventoryCount } = inventorySlice.actions

export default inventorySlice
