import { combineReducers } from '@reduxjs/toolkit'

import storageSession from 'redux-persist/lib/storage/session'
import { persistReducer } from 'redux-persist'

import * as slices from './Slices'

export const rootReducer = combineReducers({
    tutor: slices.tutorSlice.reducer,
    survey: slices.surveySlice.reducer,
    user: slices.userSlice.reducer,
    program: slices.programSlice.reducer,
    account: slices.accountSlice.reducer,
    attendance: slices.attendanceSlice.reducer,
    report: slices.reportSlice.reducer,
    rental: slices.rentalSlice.reducer,
    inventory: slices.inventorySlice.reducer,
})
const persistConfig = {
    key: 'root', // localStorage에 저장합니다.
    storage: storageSession, // sessionStorage에 저장
    whitelist: ['user', 'report'], // 적용 대상목록 - reducer를 넣는게 아니라 reducer이름을 문자열로 넣어야함
    // blacklist: [""], // 제외 대상목록
}
export const persistedReducer = persistReducer(persistConfig, rootReducer)
