import { Modal } from 'antd'
import ColorButton from 'components/AntD/Button/ColorButton'

import styles from './dialog.module.scss'

/**
 *
 * @param title
 * @param content
 * @param centered
 * @param modalOpen
 * @param setModalOpen
 * @param handleConfirm
 * @param isCancel
 * @param submit htmlType
 * @param hideFooter footer 숨기기
 * @param closable
 * @param type
 * @returns {JSX.Element}
 * @constructor
 */
const InfoDialog = ({
    title = '',
    content = <>내용이 들어갑니다.</>,
    centered,
    modalOpen,
    setModalOpen,
    handleConfirm,
    isCancel,
    submit,
    hideFooter,
    closable = false,
    type = 1,
    maskClosable = false,
}) => {
    const handleCancel = e => {
        e.preventDefault()
        setModalOpen(false)
    }
    return (
        <Modal
            title={title}
            open={modalOpen}
            centered={centered}
            onOk={() => setModalOpen(false)}
            onCancel={() => setModalOpen(false)}
            closable={closable}
            maskClosable={maskClosable}
            className={type === 1 ? styles['ant-modal'] : styles.ant_article}
            footer={
                !hideFooter
                    ? [
                          <ColorButton
                              type="primary"
                              key="back"
                              onClick={handleConfirm}
                              htmlType={submit ? 'submit' : 'button'}
                          >
                              확인
                          </ColorButton>,
                          isCancel && (
                              <ColorButton key="back" onClick={e => handleCancel(e)}>
                                  취소
                              </ColorButton>
                          ),
                      ]
                    : null
            }
        >
            {content}
        </Modal>
    )
}

export default InfoDialog
