import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Select } from 'antd'
import { v4 as uuidv4 } from 'uuid'

import TextField from 'components/AntD/TextField'

import { ArrowBtmIcon, TrashIcon24 } from 'assets/Icons'
import styles from './TeachingMaterialInfo.module.scss'

const TeachingMaterialInfo = ({ number, item, teachingMaterials, stockList, remove, form }) => {
    // 기본값으로 2개의 항목 추가
    const defaultMaterials = [
        { id: uuidv4(), toolsName: '', toolsNum: '', remarks: '' },
        { id: uuidv4(), toolsName: '', toolsNum: '', remarks: '' },
    ]

    // 초기화 여부
    const [initialized, setInitialized] = useState(false)
    const [materials, setMaterials] = useState(defaultMaterials)

    const handleSelectChange = (value, option) => {
        const currentData = form.getFieldValue('teachingMaterials') || []
        const updatedItem = {
            ...currentData[item.name], // 기존 값 유지
            toolsId: option.details.toolsId,
            toolsNum: option.details.toolsNum,
        }
        const updatedList = [...currentData]
        updatedList[item.name] = updatedItem
        form.setFieldsValue({ teachingMaterials: updatedList })
    }

    useEffect(() => {
        if (!initialized && teachingMaterials && teachingMaterials.length > 0) {
            const updatedMaterials = teachingMaterials.map(material => ({
                id: uuidv4(),
                toolsId: material.toolsId,
                toolsName: material.toolsName || '',
                toolsNum: material.toolsNum || '',
                remarks: material.remarks || '',
            }))
            setMaterials(updatedMaterials)
        }
        if (!initialized && stockList && stockList.length > 0 && teachingMaterials && teachingMaterials.length === 0) {
            const updatedMaterials = [
                {
                    id: uuidv4(),
                    toolsId: '',
                    toolsName: '',
                    toolsNum: '',
                    remarks: '',
                },
            ]
            setMaterials(updatedMaterials)
        }
        setInitialized(true)
    }, [teachingMaterials])

    return (
        <Form.Item shouldUpdate className={styles.mb0}>
            {({ getFieldValue }) => {
                const curData = getFieldValue('teachingMaterials') || []
                const selectedToolsIds = materials.map(mat => mat?.toolsId).filter(val => !!val)
                const filteredStockList = stockList.filter(
                    option =>
                        materials?.[item.name]?.toolsId === option.details.toolsId ||
                        !selectedToolsIds.includes(option.details.toolsId),
                )
                return (
                    <div className={`${styles.text_row}`}>
                        <Col span={14}>
                            <Form.Item
                                name={[item.name, 'toolsName']}
                                rules={[{ required: true, message: '' }]}
                                initialValue={curData.toolsName || ''}
                                className={styles.mb0}
                            >
                                {stockList && stockList.length > 0 ? (
                                    <Select
                                        showSearch
                                        placeholder="교구이름"
                                        options={filteredStockList}
                                        onChange={(value, option) => handleSelectChange(value, option)}
                                        style={{ height: 50, width: 180 }}
                                        suffixIcon={<ArrowBtmIcon />}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '')
                                                .toLowerCase()
                                                .localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                    />
                                ) : (
                                    <div className={styles.not_found_page_wrap}>
                                        <div className={styles.not_found_page_top}>
                                            <span className={styles.not_found_page_icon} />
                                            <div className={styles.not_found_page_title}>
                                                <span className="sub_title1">
                                                    매칭되는 대여 반납 교구가 존재하지 않습니다.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name={[item.name, 'toolsNum']}
                                initialValue={curData.toolsNum || ''}
                                className={styles.mb0}
                            >
                                <TextField
                                    name={[item.name, 'toolsNum']}
                                    val={curData?.[number]?.toolsNum || ''}
                                    placeholder="교구번호"
                                    disabled
                                    size="sm"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={2}>
                            <Form.Item
                                name={[item.name, 'remarks']}
                                initialValue={curData.remarks || ''}
                                className={`${styles.mb0}`}
                            >
                                <TextField
                                    name={[item.name, 'remarks']}
                                    placeholder="비고"
                                    val={curData?.[number]?.remarks}
                                    size="sm"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Button className={styles.btn} onClick={() => remove(item.name)}>
                                <TrashIcon24 />
                            </Button>
                        </Col>
                    </div>
                )
            }}
        </Form.Item>
    )
}

export default TeachingMaterialInfo
