import { Col, Row, Form, InputNumber, Flex, Input } from 'antd'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { formatCurrency, formatNumberToThousands, numberParser } from 'utils/utilCommon'
import { useEffect, useState, useRef } from 'react'
import { getAllUserInfo } from 'store/Slices/user'
import { getCountRPC } from '../../api/rpc/rpc.api'
import styles from './report.module.scss'
import SanitizedHTML from '../sanitizeHTML'

const PlanTable = ({ title, info, name, date }) => {
    const { TextArea } = Input
    const { pathname } = useLocation()
    const [LocalDetails, setLocalDetails] = useState([])
    const { userInfo } = useSelector(getAllUserInfo)
    const paths = pathname.split('/').filter(path => path !== '')

    //textarea 길이설정
    const textAreaRefOne = useRef(null)
    const textAreaRefTwo = useRef(null)
    const textAreaRefThree = useRef(null)
    const textAreaRefFour = useRef(null)
    const textAreaRefFive = useRef(null)
    const textAreaRefSix = useRef(null)

    const textAreaAdjust = element => {
        if (element.value === '') {
            //element.style.height = 'inherit' // 초기 높이로 설정
            element.style.height = '90px' // 초기 높이로 설정
            //element.style.height = '100%' // 초기 높이로 설정
        } else {
            element.style.height = '1px'
            element.style.height = `${10 + element.scrollHeight}px`
        }
    }

    // useEffect(() => {
    //     if (textAreaRef.current) {
    //         textAreaAdjust(textAreaRef.current.resizableTextArea.textArea)
    //     }
    // }, [details])

    const handleTextAreaChange = e => {
        textAreaAdjust(e.target)
    }

    const {
        regionId,
        details,
        eventDetails,
        expenditureAmount,
        ratio,
        cumulativePerformanceNumber,
        cumulativeMonthNumber,
        cumulativePerformanceDetails,
        cumulativeMonthDetails,
        performanceDetails,
        performanceMonthDetails,
        monthOfReport,
    } = info || ''

    const { totalProgramCount, totalNumberOfBenefited, currentMonthBenefited, currentMonthCompletedPrograms } =
        LocalDetails || []
    const getTotalStatistics = async () => {
        try {
            const response = await getCountRPC(regionId || userInfo.regionId, monthOfReport || date)
            if (response.error) {
                console.log(response.error.message)
            } else {
                setLocalDetails(response)
            }
        } catch (error) {
            console.log(error.message)
        }
    }
    // useEffect(() => {
    //     getTotalStatistics()
    // }, [userInfo.regionId, date])

    useEffect(() => {
        // getTotalStatistics 호출
        getTotalStatistics()

        // if (textAreaRef.current) {
        //     textAreaAdjust(textAreaRef.current.resizableTextArea.textArea)
        // }
        const adjustTextAreaHeight = () => {
            if (textAreaRefOne.current) {
                textAreaAdjust(textAreaRefOne.current.resizableTextArea.textArea)
            }
            if (textAreaRefTwo.current) {
                textAreaAdjust(textAreaRefTwo.current.resizableTextArea.textArea)
            }
            if (textAreaRefThree.current) {
                textAreaAdjust(textAreaRefThree.current.resizableTextArea.textArea)
            }
            if (textAreaRefFour.current) {
                textAreaAdjust(textAreaRefFour.current.resizableTextArea.textArea)
            }
            if (textAreaRefFive.current) {
                textAreaAdjust(textAreaRefFive.current.resizableTextArea.textArea)
            }
            if (textAreaRefSix.current) {
                textAreaAdjust(textAreaRefSix.current.resizableTextArea.textArea)
            }
        }
        adjustTextAreaHeight()
    }, [userInfo.regionId, date, details])

    return (
        <>
            <div className={`${styles.head} ${styles.left} ${styles.transparent_bg}`}>
                <span className="h7">
                    {title}월 실적 (당월)
                    {(paths[1] === 'register' || paths[1] === 'edit') && <span className={styles.red}>*</span>}
                </span>
            </div>

            {/* 교육관리 */}
            <div className={`${styles.box_line} ${styles.radius_4}`}>
                <div className={`${styles.head} ${styles.grow_1} ${styles.bottom_line}`}>
                    <span className="h8">교육관리</span>
                    <p className={`${styles.red} ${styles.mg0}`}>
                        ※ 아래 항목(교육운영, 수혜학생)은 시스템에서 연동됩니다.
                    </p>
                </div>

                <Row className={styles.padding_2436} gutter={24}>
                    <Col span={18}>
                        <Form.Item name={`${name}Details`} rules={[{ required: true }]} noStyle>
                            {paths[1] === 'register' || paths[1] === 'edit' ? (
                                <TextArea
                                    name={`${name}Details`}
                                    //className={styles.height_100}
                                    className={styles.textarea_min}
                                    placeholder="내용을 입력해주세요"
                                    content={details || ''}
                                    rows={3}
                                    style={{ resize: 'none' }}
                                    onChange={handleTextAreaChange}
                                    onInput={handleTextAreaChange}
                                    ref={textAreaRefOne}
                                />
                            ) : (
                                <SanitizedHTML className={`${styles.text}`} html={details} />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Row className={styles.right} gutter={16}>
                            <Col span={12} className={`body2 ${styles.margin_b16}`}>
                                집행금액{' '}
                                {(paths[1] === 'register' || paths[1] === 'edit') && (
                                    <span className={styles.red}>*</span>
                                )}
                            </Col>
                            <Col span={12} className={`body2 ${styles.margin_b16}`}>
                                비율 (%)
                            </Col>
                            <Col span={12} className={`body2 ${styles.gray} ${styles.ellipsis}`}>
                                <Form.Item name={`${name}ExpenditureAmount`} rules={[{ required: true }]} noStyle>
                                    {paths[1] === 'register' || paths[1] === 'edit' ? (
                                        <InputNumber
                                            name={`${name}ExpenditureAmount`}
                                            className={`${styles.right} ${styles.height_100} ${styles.ant_input} ${styles.w127} ${styles.no_number_handler}`}
                                            initialvalues={expenditureAmount || ''}
                                            formatter={formatNumberToThousands}
                                            parser={numberParser}
                                            placeholder="집행금액 입력"
                                            onKeyDown={e => (e.keyCode === 13 ? e.preventDefault() : '')}
                                        />
                                    ) : (
                                        <span>{formatCurrency(expenditureAmount)}</span>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12} className={`body2 ${styles.gray} ${styles.ellipsis}`}>
                                <Form.Item name={`${name}Ratio`} rules={[{ required: true }]} noStyle>
                                    {paths[1] === 'register' || paths[1] === 'edit' ? (
                                        <InputNumber
                                            name={`${name}Ratio`}
                                            className={`${styles.right} ${styles.height_100} ${styles.ant_input} ${styles.no_number_handler}`}
                                            step={0.1} // 소수점 자릿수 조절
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} // 천 단위 구분 기호 추가
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')} // 입력 값에서 구분 기호 제거
                                            maxLength={3}
                                            initialvalues={0}
                                            onKeyDown={e => (e.keyCode === 13 ? e.preventDefault() : '')}
                                            readOnly
                                        />
                                    ) : (
                                        <span>{ratio}</span>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col span={12}>
                        <div className={`${styles.head} ${styles.bottom_line} ${styles.top_line} ${styles.right_line}`}>
                            <span className="body2">교육운영</span>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className={`${styles.head} ${styles.bottom_line} ${styles.top_line}`}>
                            <span className="body2">수혜학생</span>
                        </div>
                    </Col>
                    <Col span={12} className={`${styles.right_line} ${styles.padding_2436}`}>
                        <Row gutter={28}>
                            <Col span={12}>
                                <Flex align="center" justify="center">
                                    <span className={`body2 ${styles.w80} ${styles.shrink_0}`}>누적</span>
                                    {paths[1] === 'register' || paths[1] === 'edit' ? (
                                        <InputNumber
                                            disabled
                                            value={totalProgramCount}
                                            className={`${styles.right} ${styles.height_100} ${styles.ant_input} ${styles.width_100} ${styles.no_number_handler}`}
                                        />
                                    ) : (
                                        <span className={`sub_title2 ${styles.width_100}`}>
                                            {totalProgramCount || '- '}건
                                        </span>
                                    )}
                                </Flex>
                            </Col>
                            <Col span={12}>
                                <Flex align="center" justify="center">
                                    <span className={`body2 ${styles.w80} ${styles.shrink_0}`}>당월기준</span>
                                    {paths[1] === 'register' || paths[1] === 'edit' ? (
                                        <InputNumber
                                            disabled
                                            value={currentMonthCompletedPrograms}
                                            className={`${styles.right} ${styles.height_100} ${styles.ant_input} ${styles.width_100} ${styles.no_number_handler}`}
                                        />
                                    ) : (
                                        <span className={`sub_title2 ${styles.width_100}`}>
                                            {currentMonthCompletedPrograms || '- '}건
                                        </span>
                                    )}
                                </Flex>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12} className={styles.padding_2436}>
                        <Row gutter={28}>
                            <Col span={12}>
                                <Flex align="center" justify="center">
                                    <span className={`body2 ${styles.w80} ${styles.shrink_0}`}>누적</span>
                                    {paths[1] === 'register' || paths[1] === 'edit' ? (
                                        <InputNumber
                                            disabled
                                            value={totalNumberOfBenefited}
                                            className={`${styles.right} ${styles.height_100} ${styles.ant_input} ${styles.width_100} ${styles.no_number_handler}`}
                                        />
                                    ) : (
                                        <span className={`sub_title2 ${styles.width_100}`}>
                                            {totalNumberOfBenefited || '- '}명
                                        </span>
                                    )}
                                </Flex>
                            </Col>
                            <Col span={12}>
                                <Flex align="center" justify="center">
                                    <span className={`body2 ${styles.w80} ${styles.shrink_0}`}>당월기준</span>
                                    {paths[1] === 'register' || paths[1] === 'edit' ? (
                                        <InputNumber
                                            disabled
                                            value={currentMonthBenefited}
                                            className={`${styles.right} ${styles.height_100} ${styles.ant_input} ${styles.width_100} ${styles.no_number_handler}`}
                                        />
                                    ) : (
                                        <span className={`sub_title2 ${styles.width_100}`}>
                                            {currentMonthBenefited || '- '}명
                                        </span>
                                    )}
                                </Flex>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>

            {/* 행사관리 */}
            <div className={`${styles.box_line} ${styles.radius_4} ${styles.box_outer}`}>
                <div className={`${styles.head} ${styles.grow_1}`}>
                    <span className="h8">행사관리</span>
                </div>
                <div className={`${styles.basic_box} ${styles.padding_2436}`}>
                    <Form.Item name={`${name}EventDetails`} rules={[{ required: true }]} noStyle>
                        {paths[1] === 'register' || paths[1] === 'edit' ? (
                            <TextArea
                                name={`${name}EventDetails`}
                                //className={styles.height_100}
                                className={styles.textarea_min}
                                placeholder="내용을 입력해주세요"
                                content={eventDetails || ''}
                                rows={3}
                                style={{ resize: 'none' }}
                                onChange={handleTextAreaChange}
                                onInput={handleTextAreaChange}
                                ref={textAreaRefTwo}
                            />
                        ) : (
                            <SanitizedHTML className={`${styles.text}`} html={eventDetails} />
                        )}
                    </Form.Item>
                </div>
            </div>

            {/* 강사 실적 관리 */}
            <div className={`${styles.box_line} ${styles.radius_4} ${styles.box_outer} ${styles.section_md}`}>
                <div className={`${styles.head} ${styles.grow_1}`}>
                    <span className="h8">강사 실적 관리</span>
                </div>
                <Row>
                    <Col span={12}>
                        <div className={`${styles.head} ${styles.bottom_line} ${styles.top_line} ${styles.right_line}`}>
                            <span className="body2">실적</span>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className={`${styles.head} ${styles.bottom_line} ${styles.top_line}`}>
                            <span className="body2">세부내용</span>
                        </div>
                    </Col>
                    <Col span={12} className={`${styles.right_line}`}>
                        <Flex className={`${styles.height_100}`}>
                            <div className={`${styles.count_box} ${styles.right_line} ${styles.padding_2436}`}>
                                {paths[1] === 'register' || paths[1] === 'edit' ? (
                                    <>
                                        <p className="body2 center">누적인원</p>
                                        <Form.Item
                                            name={`${name}CumulativePerformanceNumber`}
                                            rules={[{ required: true }]}
                                            noStyle
                                        >
                                            {/* <Input
                                                className={`${styles.ant_input}`}
                                                initialvalues={cumulativePerformanceNumber || ''}
                                                placeholder="총 인원을 입력하세요"
                                            /> */}
                                            <InputNumber
                                                className={`${styles.ant_input} ${styles.width_100}`}
                                                initialvalues={cumulativePerformanceNumber || ''}
                                                placeholder="총 인원을 입력하세요"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name={`${name}CumulativePerformanceDetails`}
                                            rules={[{ required: true }]}
                                            noStyle
                                        >
                                            <TextArea
                                                //className={`${styles.ant_input}`}
                                                className={styles.textarea_lg}
                                                content={cumulativePerformanceDetails || ''}
                                                placeholder="내용을 입력해주세요"
                                                rows={5}
                                                style={{ resize: 'none' }}
                                                onChange={handleTextAreaChange}
                                                onInput={handleTextAreaChange}
                                                ref={textAreaRefThree}
                                            />
                                        </Form.Item>
                                    </>
                                ) : (
                                    <>
                                        <p className={`body2 ${styles.graybox}`}>누적인원</p>
                                        <div className={`${styles.gray_sub_box}`}>
                                            {cumulativePerformanceNumber ? (
                                                <p className={`sub_title2 ${styles.left}`}>
                                                    총 {cumulativePerformanceNumber}명
                                                </p>
                                            ) : null}
                                            <div className={`${styles.text}`}>{cumulativePerformanceDetails}</div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className={`${styles.count_box} ${styles.padding_2436}`}>
                                {paths[1] === 'register' || paths[1] === 'edit' ? (
                                    <>
                                        <p className="body2">당월기준</p>
                                        <Form.Item
                                            name={`${name}CumulativeMonthNumber`}
                                            rules={[{ required: true }]}
                                            noStyle
                                        >
                                            {/* <Input
                                                className={`${styles.ant_input}`}
                                                initialvalues={cumulativeMonthNumber || ''}
                                                placeholder="총 인원을 입력하세요"
                                            /> */}
                                            <InputNumber
                                                className={`${styles.ant_input} ${styles.width_100}`}
                                                initialvalues={cumulativePerformanceNumber || ''}
                                                placeholder="총 인원을 입력하세요"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name={`${name}CumulativeMonthDetails`}
                                            rules={[{ required: true }]}
                                            noStyle
                                        >
                                            <TextArea
                                                //className={`${styles.ant_input}`}
                                                className={styles.textarea_lg}
                                                content={cumulativeMonthDetails || ''}
                                                placeholder="내용을 입력해주세요"
                                                rows={5}
                                                style={{ resize: 'none' }}
                                                onChange={handleTextAreaChange}
                                                onInput={handleTextAreaChange}
                                                ref={textAreaRefFour}
                                            />
                                        </Form.Item>
                                    </>
                                ) : (
                                    <>
                                        <p className={`body2 ${styles.graybox}`}>당월기준</p>
                                        <div className={`${styles.gray_sub_box}`}>
                                            {cumulativeMonthNumber ? (
                                                <p className={`sub_title2 ${styles.left}`}>
                                                    총 {cumulativeMonthNumber}명
                                                </p>
                                            ) : null}
                                            <div className={`${styles.text}`}>{cumulativeMonthDetails}</div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </Flex>
                    </Col>
                    <Col span={12}>
                        <Flex className={`${styles.height_100}`}>
                            <div className={`${styles.count_box} ${styles.right_line} ${styles.padding_2436}`}>
                                {paths[1] === 'register' || paths[1] === 'edit' ? (
                                    <>
                                        <p className="body2 center">누적인원</p>
                                        <Form.Item
                                            name={`${name}PerformanceDetails`}
                                            rules={[{ required: true }]}
                                            noStyle
                                        >
                                            <TextArea
                                                //className={`${styles.ant_input}`}
                                                className={styles.textarea_xlg}
                                                content={performanceDetails || ''}
                                                placeholder="내용을 입력해주세요"
                                                rows={5}
                                                style={{ resize: 'none' }}
                                                onChange={handleTextAreaChange}
                                                onInput={handleTextAreaChange}
                                                ref={textAreaRefFive}
                                            />
                                        </Form.Item>
                                    </>
                                ) : (
                                    <>
                                        <p className={`body2 ${styles.graybox}`}>누적인원</p>
                                        <div className={`${styles.gray_sub_box}`}>
                                            <div className={`${styles.text}`}>{performanceDetails}</div>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className={`${styles.count_box} ${styles.padding_2436}`}>
                                {paths[1] === 'register' || paths[1] === 'edit' ? (
                                    <>
                                        <p className="body2">당월기준</p>
                                        <Form.Item
                                            name={`${name}PerformanceMonthDetails`}
                                            rules={[{ required: true }]}
                                            noStyle
                                        >
                                            <TextArea
                                                //className={`${styles.ant_input}`}
                                                className={styles.textarea_xlg}
                                                content={performanceMonthDetails || ''}
                                                placeholder="내용을 입력해주세요"
                                                rows={5}
                                                style={{ resize: 'none' }}
                                                onChange={handleTextAreaChange}
                                                onInput={handleTextAreaChange}
                                                ref={textAreaRefSix}
                                            />
                                        </Form.Item>
                                    </>
                                ) : (
                                    <>
                                        <p className={`body2 ${styles.graybox}`}>당월기준</p>
                                        <div className={`${styles.gray_sub_box}`}>
                                            <div className={`${styles.text}`}>{performanceMonthDetails}</div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </Flex>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default PlanTable
