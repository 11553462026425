import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { fetchTutorList } from 'api/tutors/tutors.api'

import { BannerIconTutor } from 'assets/Icons'
import PageBanner from 'layout/PageBanner'

import Spinner from 'components/AntD/Spin'
import GridList from 'components/List/GridList'
import Pagenation from 'components/Pagenation'
import ListView from 'components/List/ListView'
import FilterAndSearchArea from 'components/Filter'

import { useSelector } from 'react-redux'
import { getAllUserInfo } from 'store/Slices/user'

import ListNotFound from '../PageNotFound/ListNotFound'

import styles from './tutor.module.scss'

const search = 'search'

const Tutors = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { isLogin, userInfo } = useSelector(getAllUserInfo)
    const path = location.pathname.split('/')[1]
    const queryParams = new URLSearchParams(window.location.search)

    const [sortName, setSortName] = useState('createdDt')
    const [isGridView, setIsGridView] = useState(true)
    const [tutorList, setTutorList] = useState(null)
    const [isLoading, setLoading] = useState(true)

    const [inputValue, setInputValue] = useState('') // 검색

    const { filterCountInfo, tutorInfoList, page } = tutorList || {}
    const { currentPage, totalPages, totalItems } = page || {}
    const { isMaster, isSubMaster } = userInfo

    /**
     * querystring update
     * @param paramName
     * @param paramValue
     */
    const updateQueryStringAndNavigate = (paramName, paramValue) => {
        const { pathname } = location
        const previousParams = queryParams.get(paramName)

        // 기존값과 동일할 시 변경하지 않음
        if (paramName === 'regionId' && paramValue === previousParams) {
            return
        }

        // 새로운 파라미터 추가 (단, 값이 비어있지 않을 때)
        if (paramValue) {
            if (previousParams === paramValue) {
                if (paramName !== 'sort' && paramName !== 'search' && paramName !== 'regionId') {
                    queryParams.delete(paramName)
                }
            } else {
                queryParams.set(paramName, paramValue)
            }
        }
        // 정렬 active
        if (paramName === 'sort') {
            setSortName(paramValue)
        }
        if (paramName === 'search' && paramValue === '') {
            queryParams.delete(paramName)
        }
        // 필터 선택 시 쿼리스트링에 포함된 페이지 정보 제거
        if (paramName !== 'page') {
            queryParams.delete('page')
        }

        const newQueryString = queryParams.toString()
        const newLocation = newQueryString ? `${pathname}?${newQueryString}` : pathname
        navigate(newLocation)
    }

    // 검색 함수
    const handleSearch = () => {
        setLoading(true)
        updateQueryStringAndNavigate(search, inputValue, setLoading)
    }

    const getTutorList = async search => {
        try {
            setLoading(true)
            const data = await fetchTutorList(search, userInfo.regionId, path)
            if (data.status === 200 || data.status === 206) {
                setTutorList(data.data)
                setLoading(false)
                return true
            }
            console.error('강사 목록을 불러오는 데 문제가 발생했습니다.')
            setLoading(false)
            navigate('/')
            return false
        } catch (error) {
            console.error('강사 목록을 불러오는 동안 오류가 발생했습니다.', error)
            return null
        }
    }

    const fetchTutor = () => {
        const { search } = location
        const searchPath = search.split('?')[1] || ''
        getTutorList(searchPath)
    }

    useEffect(() => {
        if (isLogin) {
            if (isMaster || isSubMaster) {
                const regionId = queryParams.get('regionId')
                if (!regionId) {
                    queryParams.set('regionId', 'all')
                    const updatedQuery = queryParams.toString()
                    const newLocation = `${location.pathname}?${updatedQuery}`
                    navigate(newLocation, { replace: true })
                } else {
                    fetchTutor()
                }
            } else {
                fetchTutor()
            }
        } else {
            navigate('/')
        }
    }, [isMaster, isSubMaster, location, navigate])

    return (
        <PageBanner title="강사정보 관리" bannerIcon={<BannerIconTutor />}>
            <FilterAndSearchArea
                filterCount={filterCountInfo}
                updateQueryStringAndNavigate={updateQueryStringAndNavigate}
                handleSearch={handleSearch}
                sortName={sortName}
                isGridView={isGridView}
                setIsGridView={setIsGridView}
                setInputValue={setInputValue}
                inputValue={inputValue}
            />
            {isLoading ? (
                <div className={styles.spin_wrap}>
                    <Spinner />
                </div>
            ) : !tutorInfoList || tutorInfoList.length === 0 ? (
                <ListNotFound />
            ) : (
                <>
                    {isGridView ? (
                        <GridList list={tutorInfoList} setLoading={setLoading} fetchList={getTutorList} />
                    ) : (
                        <ListView list={tutorInfoList.slice(0, 10)} setLoading={setLoading} fetchList={getTutorList} />
                    )}
                    <Pagenation
                        title="강사 대량 등록"
                        subTitle="강사 단건 등록"
                        currentPage={currentPage}
                        totalPages={totalPages}
                        totalItems={totalItems}
                        navigateBtn={() => navigate('/tutors/register/bulk')}
                        subNavigateBtn={() => navigate('/tutors/register/one')}
                        updateFtn={updateQueryStringAndNavigate}
                    />
                </>
            )}
        </PageBanner>
    )
}

export default Tutors
