import { createYearRange } from 'utils/utilCommon'

import { supabase } from './axios.instance'

/**
 * 필수 쿼리 모음
 * 삭제여부
 * 어드민 분기처리
 * @param baseQuery
 * @param regionId
 * @returns {*}
 */
export const supabaseEssentialFilter = (baseQuery, regionId, path) => {
    let query = baseQuery
    // 삭제 여부 추가 (필수)
    query = query.eq('is_delete', false)

    // 어드민 & 대여반납 분기
    if (regionId && regionId !== 'all' && path !== 'rental') {
        query = query.eq('region_id', regionId)
    }
    return query
}

/**
 * supabase
 * 지역값으로 초기 설정
 * @param table
 * @param regionId
 * @returns {PostgrestFilterBuilder<any, *, GetResult<any, any, R | unknown, "*">[], R | unknown>}
 */
export const createSupabaseQuery = (table, regionId, path) => {
    // const query = supabase.from(table).select('*', { count: 'exact' })

    let query

    // 대여 반납일 때
    if (path === 'rental') {
        query = supabase.from(table).select(
            `
          rental_id,
          rental_status,
          rental_application_date,
          rental_dispatch_date,
          rental_return_date,
          tutor_info (
            tutor_name,
            region_id
          )
        `,
            { count: 'exact' },
        )
    } else {
        // 그렇지 않으면 기존 로직으로
        query = supabase.from(table).select('*', { count: 'exact' })
    }
    // 필수 쿼리
    const newQuery = supabaseEssentialFilter(query, regionId, path)

    return newQuery
}

/**
 * 페이지 정보
 * @param table
 * @param currentPage
 * @param pageSize
 * @param regionId
 * @returns {Promise<{totalItems: number, totalPages: number, pageSize: *, currentPage: *}>}
 */
export const getPaginationInfo = async (table, currentPage, pageSize, regionId) => {
    try {
        let query = createSupabaseQuery(table, regionId)
        query = query.select('*', { count: 'exact', head: true })
        const { data } = await query

        const paginationInfo = {
            currentPage,
            totalPages: Math.ceil(data.length / pageSize) || 1,
            totalItems: data.length,
            pageSize,
        }

        return paginationInfo
    } catch (error) {
        console.error('Error fetching data:', error.message)
        throw error
    }
}

/**
 * 필터 카운트 영역
 * @param table
 * @param item
 * @param status
 * @param regionId
 * @returns {Promise<number|number>}
 */
export const getCount = async (table, item, status, regionId) => {
    const query = createSupabaseQuery(table, regionId)
    query.select('*', { count: 'exact' }).eq(item, status)
    const { data } = await query

    return data.length || 0
}

/**
 * 당해연도 해당 지역 채용인원 카운트
 * @param table
 * @param item
 * @param status
 * @param regionId
 * @returns {Promise<number>}
 */
export const getRecruitmentTutorCount = async (table, regionId) => {
    const {
        startDate,
        // endDate
    } = createYearRange()

    let query = createSupabaseQuery(table, regionId)
    query = query.gte('start_date', startDate)
    // query = query.lte('end_date', endDate)
    // .lte('end_date', endDate)
    const { data } = await query

    return data.length || 0
}

/**
 * 총 갯수 필터
 * @returns {Promise<number|number>}
 * @param table
 * @param regionId
 */
export const getTotalCount = async (table, regionId) => {
    const query = createSupabaseQuery(table, regionId)
    query.select('*', { count: 'exact' })
    const { data } = await query

    return data.length || 0
}

/**
 * 성과관리 (statistic) 테이블 필수 쿼리
 * @param table
 * @param regionId
 * @returns {PostgrestFilterBuilder<any, *, GetResult<any, any, R | unknown, "*">[], R | unknown>}
 */
export const statisticEssentialQuery = async (table, regionId) => {
    let query = supabase.from(table).select()
    if (regionId && regionId !== 'all') {
        query = query.eq('region', regionId)
    }
    return query
}
