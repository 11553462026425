import React, { useEffect, useState, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { supabase } from 'api/axios.instance'

import { getAllUserInfo } from 'store/Slices/user'
import { getIsFetching, getRentalDetail, rentalDetailAsync } from 'store/Slices/rental'

import { Row, Col, Space, Flex, Form, App } from 'antd'
import { v4 as uuidv4 } from 'uuid'

import PageBanner from 'layout/PageBanner'

import TeachingMaterialInfo from 'pages/Tools/Rent/Edit/TeachingMaterialInfo'

import Title from 'components/Ui/Title'
import Label from 'components/Ui/Label'
import TextField from 'components/AntD/TextField'
import SelectUi from 'components/Ui/Select/SelectUi'
import IconCard from 'components/AntD/Card/IconCard'
import ColorButton from 'components/AntD/Button/ColorButton'
import DatePickerInput from 'components/AntD/DatePicker'
import Spinner from 'components/AntD/Spin'

import { getTransferToolsInventory } from 'api/tools/toolsTransfer.api'

import { BannerIconTools, ColorDocIcon, ColorLocationIcon, PlusIcon } from 'assets/Icons'
import styles from 'assets/scss/detail.module.scss'

const ToolsRentEdit = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const { message } = App.useApp()

    const { isLogin, userInfo } = useSelector(getAllUserInfo)
    const isLoading = useSelector(getIsFetching)

    const rentalDetail = useSelector(getRentalDetail)
    const {
        tutorName,
        contact,
        usingInstitution,
        rentalStatus,
        rentalApplicationDate,
        rentalDispatchDate,
        rentalReturnDate,
        rentalUsePurpose,
        toolsInfo,
        toolsNum,
        stockLocation,
        classification,
        affiliatedRegion,
        specification,
        locationCode,
        managementNumber,
        stockInSequence,
        regionClassification,
        buildingClassification,
        // tutorId,
        // rentalId,
        // remarks,
        // quantity,
        // toolsId,
        // toolsName,
        // etc,
    } = rentalDetail || {}
    const { isMaster, isSubMaster, regionId } = userInfo

    const [teachingMaterials, setTeachingMaterials] = useState(toolsInfo)
    const [stockList, setStockList] = useState([])

    const handleTeachingMaterialChange = updatedMaterials => {
        setTeachingMaterials(updatedMaterials)
    }

    const onFinish = async value => {
        const teachingMaterialData = form.getFieldValue('teachingMaterials') || []
        // 삭제 시 비교
        const oldRentalItemIds = toolsInfo.map(item => item.rentalItemId).filter(id => !!id) // 기존 교구 정보 배열
        const newRentalItemIds = teachingMaterialData.map(item => item.rentalItemId).filter(id => !!id) // 변경된 교구 정보 배열
        const toDeleteRentalItemIds = oldRentalItemIds.filter(oldId => !newRentalItemIds.includes(oldId)) // 삭제 대상: 기존에는 있었는데, 새 데이터에서 사라진 rentalItemId

        // 변경된 값
        const toolsIds = value.teachingMaterials.map(item => item.toolsId) // 선택한 교구 ID 배열
        const remarks = value.teachingMaterials.map(item => item.remarks) // 교구 정보 비고란 배열
        const rentalItemIds = teachingMaterialData.map(item => item.rentalItemId) // 수정한 교구정보의 rental_item_id

        const params = {
            rental_id_param: id,
            using_institution_param: value.usingInstitution,
            rental_status_param: value.rentalStatus,
            rental_application_date_param: value.rentalApplicationDate,
            rental_dispatch_date_param: value.rentalDispatchDate,
            rental_return_date_param: value.rentalReturnDate,
            rental_use_purpose_param: value.rentalUsePurpose, // 없으면 null
            classification_param: value.classification,
            affiliated_region_param: value.affiliatedRegion,
            specification_param: value.specification,
            location_code_param: value.locationCode,
            stock_in_sequence_param: value.stockInSequence, // 생략된 값은 null 전달
            management_number_param: value.managementNumber,
            region_classification_param: value.regionClassification,
            building_classification_param: value.buildingClassification,
            tools_ids_param: toolsIds,
            remarks_param: remarks,
            rental_item_ids_param: rentalItemIds,
            rental_item_ids_to_delete: toDeleteRentalItemIds, // 삭제할 교구정보 ID (rental_item_id)
        }

        try {
            const response = await supabase.rpc('update_rental_and_items', params).select()
            if (response.status === 204) {
                message.success('대여반납 정보 수정이 완료되었습니다.')
                await dispatch(rentalDetailAsync({ regionId: userInfo.regionId, rentalId: id }))
                navigate(`/tools/rental/${id}`)
            } else {
                message.error('대여반납 정보 수정 실패했습니다.')
            }
            // console.log('onFinish response : ', response)
        } catch (err) {
            console.log('err : ', err)
        }
    }

    const onFinishFailed = errorInfo => {
        message.error('필수값을 확인해주세요')
        console.log('Failed:', errorInfo)
    }

    const fetchToolsInventoryList = async () => {
        try {
            const data = await getTransferToolsInventory(userInfo.regionId)
            if (data.status === 200 || data.status === 206) {
                // 중복 제거
                const uniqueData = Array.from(new Map(data.data.map(item => [item.toolsId, item])).values())
                const formattedList = uniqueData.map(item => ({
                    label: `${item.toolsName} (${item.toolsNum})`, // 드롭다운에 표시될 텍스트
                    // value: item.toolsId, // 선택 시 전달될 값
                    value: item.toolsName, // 선택 시 전달될 값
                    details: item, // 추가 정보를 저장 (number, remarks 등)
                }))
                setStockList(formattedList)
            }
            console.error('재고관리 목록을 불러오는 데 문제가 발생했습니다.')
        } catch (error) {
            console.error('재고관리 목록을 불러오는 동안 오류가 발생했습니다.', error)
        }
    }

    const fetchRentalDetail = useCallback(async () => {
        if (isLogin) {
            dispatch(rentalDetailAsync({ regionId: userInfo.regionId, rentalId: id }))
        } else {
            navigate('/')
        }
    }, [id, dispatch])

    useEffect(() => {
        fetchToolsInventoryList()
        setTimeout(fetchRentalDetail, 500)
    }, [fetchRentalDetail])

    useEffect(() => {
        if (toolsInfo) {
            setTeachingMaterials(toolsInfo)
        }
    }, [toolsInfo])

    return (
        <PageBanner title="교구재 대여정보 수정" bannerIcon={<BannerIconTools />}>
            <div className={styles.area}>
                {isLoading ? (
                    <div className={styles.spin_wrap}>
                        <Spinner />
                    </div>
                ) : (
                    rentalDetail && (
                        <Form
                            form={form}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            initialValues={{
                                teachingMaterials: toolsInfo,
                            }}
                        >
                            <Row className={styles.mb_lg}>
                                <Col span={16}>
                                    <Space direction="vertical" size={60}>
                                        <div>
                                            <Title title="대여자 정보" />
                                            <div className={`${styles.box_md} ${styles.border_style}`}>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="성명 (대여자)" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>{tutorName}</span>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="연락처" />
                                                    </Col>
                                                    <Col span={19} className={styles.padding_y_medium}>
                                                        {contact}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div>
                                            <Title title="대여 및 사용정보" />
                                            <div className={`${styles.box_md} ${styles.border_style}`}>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="사용기관" />
                                                            </Col>
                                                            <Col
                                                                span={14}
                                                                className={`${styles.padding_y_xs} ${styles.padding_right}`}
                                                            >
                                                                <Form.Item
                                                                    name="usingInstitution"
                                                                    initialValue={usingInstitution}
                                                                >
                                                                    <TextField
                                                                        name="usingInstitution"
                                                                        val={usingInstitution}
                                                                        size="sm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="대여상태" />
                                                    </Col>
                                                    <Col span={19}>
                                                        <Form.Item
                                                            initialValue={rentalStatus}
                                                            name="rentalStatus"
                                                            rules={[{ required: true, message: '' }]}
                                                        >
                                                            <SelectUi
                                                                size="xl"
                                                                value={rentalStatus}
                                                                options={[
                                                                    '대여신청',
                                                                    '신청취소',
                                                                    '반납완료',
                                                                    '대여확정',
                                                                    '대여불가능',
                                                                ]}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="대여 신청일자" />
                                                            </Col>
                                                            <Col span={14}>
                                                                <Form.Item
                                                                    initialValue={rentalApplicationDate}
                                                                    name="rentalApplicationDate"
                                                                    rules={[{ required: true, message: '' }]}
                                                                >
                                                                    <DatePickerInput
                                                                        name="rentalApplicationDate"
                                                                        value={rentalApplicationDate}
                                                                        // defaultValue={localOperationInfo.start_date}
                                                                        // onChange={handleStateChange('start_date')}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="대여 일자" />
                                                            </Col>
                                                            <Col span={14}>
                                                                <Form.Item
                                                                    initialValue={rentalDispatchDate}
                                                                    name="rentalDispatchDate"
                                                                >
                                                                    <DatePickerInput
                                                                        value={rentalDispatchDate}
                                                                        name="rentalDispatchDate"
                                                                        // defaultValue={localOperationInfo.end_date}
                                                                        // onChange={handleStateChange('end_date')}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="반납일자" />
                                                            </Col>
                                                            <Col span={14}>
                                                                <Form.Item
                                                                    initialValue={rentalReturnDate}
                                                                    name="rentalReturnDate"
                                                                >
                                                                    <DatePickerInput
                                                                        value={rentalReturnDate}
                                                                        // defaultValue={localOperationInfo.start_date}
                                                                        // onChange={handleStateChange('start_date')}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="대여/사용목적" />
                                                    </Col>
                                                    <Col
                                                        span={19}
                                                        className={`${styles.padding_y_xs} ${styles.padding_right}`}
                                                    >
                                                        <Form.Item
                                                            initialValue={rentalUsePurpose}
                                                            name="rentalUsePurpose"
                                                        >
                                                            <TextField
                                                                name="rentalUsePurpose"
                                                                val={rentalUsePurpose}
                                                                size="xl"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div>
                                            <Title title="교구 정보" />
                                            <div className={`${styles.width_100}`}>
                                                <Form.List name="teachingMaterials">
                                                    {(fields, { add, remove }) => {
                                                        return (
                                                            <>
                                                                {stockList.length === 0 ? (
                                                                    <div className={styles.not_found_page_wrap}>
                                                                        <div className={styles.not_found_page_top}>
                                                                            <span
                                                                                className={styles.not_found_page_icon}
                                                                            />
                                                                            <div
                                                                                className={styles.not_found_page_title}
                                                                            >
                                                                                <span className="sub_title1">
                                                                                    등록된 교구재가 존재하지 않습니다.
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        key={uuidv4()}
                                                                        className={`${styles.box_md} ${styles.border_style}`}
                                                                    >
                                                                        <div className={styles.list}>
                                                                            {/* Header */}
                                                                            <Row
                                                                                className={styles.header}
                                                                                align="middle"
                                                                            >
                                                                                <Col span={8} className={styles.col}>
                                                                                    품목 (교구명)
                                                                                </Col>
                                                                                <Col span={6} className={styles.col}>
                                                                                    교구번호 (제품번호)
                                                                                </Col>
                                                                                <Col span={8} className={styles.col}>
                                                                                    비고
                                                                                </Col>
                                                                                <Col span={2} className={styles.col}>
                                                                                    삭제
                                                                                </Col>
                                                                            </Row>
                                                                            {fields.map((field, index) => (
                                                                                <TeachingMaterialInfo
                                                                                    key={uuidv4()}
                                                                                    number={index}
                                                                                    item={field}
                                                                                    form={form}
                                                                                    add={add}
                                                                                    remove={remove}
                                                                                    length={fields.length}
                                                                                    teachingMaterials={
                                                                                        teachingMaterials
                                                                                    }
                                                                                    stockList={stockList}
                                                                                    onChange={
                                                                                        handleTeachingMaterialChange
                                                                                    }
                                                                                />
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {stockList.length > 0 && (
                                                                    <Form.Item
                                                                        className={`${styles.mb0} ${styles.btno}`}
                                                                    >
                                                                        <button
                                                                            type="button"
                                                                            className={styles.btn_add}
                                                                            onClick={() => {
                                                                                add()
                                                                            }}
                                                                        >
                                                                            <PlusIcon />
                                                                        </button>
                                                                    </Form.Item>
                                                                )}
                                                            </>
                                                        )
                                                    }}
                                                </Form.List>
                                            </div>
                                        </div>
                                        <div>
                                            <Title title="비고" />
                                            <div className={`${styles.border_style} ${styles.box_md}`}>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="구분" />
                                                    </Col>
                                                    <Col span={19} className={`${styles.padding_y_xs}`}>
                                                        <Form.Item initialValue={classification} name="classification">
                                                            {/*<SelectUi*/}
                                                            {/*    // onChange={handleStateChange('operational_status')}*/}
                                                            {/*    value="지역아동센터"*/}
                                                            {/*    options={['지역아동센터', '섬마을']}*/}
                                                            {/*    // value={localOperationInfo?.operational_status}*/}
                                                            {/*/>*/}
                                                            <Form.Item
                                                                name="classification"
                                                                initialValue={classification}
                                                            >
                                                                <TextField
                                                                    name="classification"
                                                                    val={classification}
                                                                    size="xl"
                                                                />
                                                            </Form.Item>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="권역" />
                                                    </Col>
                                                    <Col span={19} className={`${styles.padding_y_xs}`}>
                                                        <Form.Item
                                                            name="affiliatedRegion"
                                                            initialValue={affiliatedRegion}
                                                        >
                                                            <TextField
                                                                name="affiliatedRegion"
                                                                val={affiliatedRegion}
                                                                size="xl"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="규격" />
                                                    </Col>
                                                    <Col span={19} className={`${styles.padding_y_xs}`}>
                                                        <Form.Item name="specification" initialValue={specification}>
                                                            <TextField
                                                                name="specification"
                                                                val={specification}
                                                                size="xl"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div>
                                            <Title title="선택 항목" />
                                            <div className={`${styles.box_md} ${styles.border_style}`}>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="위치코드" />
                                                            </Col>
                                                            <Col
                                                                span={14}
                                                                className={`${styles.padding_y_xs} ${styles.padding_right}`}
                                                            >
                                                                <Form.Item
                                                                    name="locationCode"
                                                                    initialValue={locationCode}
                                                                >
                                                                    <TextField
                                                                        name="locationCode"
                                                                        val={locationCode}
                                                                        size="sm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="관리번호" />
                                                            </Col>
                                                            <Col
                                                                span={14}
                                                                className={`${styles.padding_y_xs} ${styles.padding_right}`}
                                                            >
                                                                <Form.Item
                                                                    name="managementNumber"
                                                                    initialValue={managementNumber}
                                                                >
                                                                    <TextField
                                                                        name="managementNumber"
                                                                        val={managementNumber}
                                                                        size="sm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>

                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="입고순번" />
                                                    </Col>
                                                    <Col
                                                        span={19}
                                                        className={`${styles.padding_y_xs} ${styles.padding_right}`}
                                                    >
                                                        <Form.Item
                                                            initialValue={stockInSequence}
                                                            name="stockInSequence"
                                                        >
                                                            <TextField
                                                                name="stockInSequence"
                                                                val={stockInSequence}
                                                                size="xl"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="지역구분" />
                                                    </Col>
                                                    <Col span={19} className={`${styles.padding_y_xs}`}>
                                                        <Form.Item
                                                            name="regionClassification"
                                                            initialValue={regionClassification}
                                                        >
                                                            <TextField
                                                                name="regionClassification"
                                                                val={regionClassification}
                                                                size="xl"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="건물구분" />
                                                            </Col>
                                                            <Col
                                                                span={14}
                                                                className={`${styles.padding_y_xs} ${styles.padding_right}`}
                                                            >
                                                                <Form.Item
                                                                    name="buildingClassification"
                                                                    initialValue={buildingClassification}
                                                                >
                                                                    <TextField
                                                                        name="buildingClassification"
                                                                        val={buildingClassification}
                                                                        size="sm"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Space>
                                </Col>
                                <Col span={8} className={styles.padding_left_large}>
                                    <Space direction="horizontal" className={styles.mt}>
                                        <IconCard icon={<ColorDocIcon />} label="자산번호" val={toolsNum} />
                                        <IconCard icon={<ColorLocationIcon />} label="재고위치" val={stockLocation} />
                                    </Space>
                                </Col>
                            </Row>
                            <Flex justify="center" align="center" gap={8}>
                                {!isMaster && !isSubMaster && regionId !== 'all' && (
                                    <ColorButton size="large" type="primary" htmlType="submit">
                                        저장
                                    </ColorButton>
                                )}
                                <ColorButton
                                    size="large"
                                    onClick={() => {
                                        navigate(`/tools/rental/${id}`)
                                    }}
                                >
                                    취소
                                </ColorButton>
                            </Flex>
                        </Form>
                    )
                )}
            </div>
        </PageBanner>
    )
}

export default ToolsRentEdit
