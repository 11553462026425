import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getAllUserInfo } from 'store/Slices/user'

import { Row, Col, Image, Tooltip } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { BannerIconSystem } from 'assets/Icons'

import PageBanner from 'layout/PageBanner'

import map from 'assets/images/map.svg'
import map_incheon from 'assets/images/map_incheon.svg'
import map_gyeonggi from 'assets/images/map_gyeonggi.svg'
import map_gangwon from 'assets/images/map_gangwon.svg'
import map_chungnam from 'assets/images/map_chungnam.svg'
import map_chungbug from 'assets/images/map_chungbug.svg'
import map_kyeongbuk from 'assets/images/map_kyeongbuk.svg'
import map_jeonbuk from 'assets/images/map_jeonbuk.svg'
import map_daegu from 'assets/images/map_daegu.svg'
import map_jeonnam from 'assets/images/map_jeonnam.svg'
import map_gwangju from 'assets/images/map_gwangju.svg'
import map_gyeongnam from 'assets/images/map_gyeongnam.svg'
import map_ulsan from 'assets/images/map_ulsan.svg'
import map_jeju from 'assets/images/map_jeju.svg'

import { getCountRPC } from 'api/rpc/rpc.api'

import FilterLayout from 'components/Filter/FilterLayout'
import StudentBarChart from 'components/Statistic/Chart/StudentBarChart'
import PieChart from 'components/Statistic/Chart/PieChart'
import DonutChart from 'components/Statistic/Chart/DonutChart'
import styles from './statistics.module.scss'

const Statistics = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { isLogin, userInfo } = useSelector(getAllUserInfo)
    const { regionId, isMaster, isSubMaster } = userInfo || {}
    const queryParams = new URLSearchParams(window.location.search)
    const sidoQueryParam = isMaster || isSubMaster ? queryParams.get('sido') || '' : regionId
    // const [sidoQueryParamState, setSidoQueryParamState] = useState(sidoQueryParam)
    const [localStatisticsData, setLocalStatisticsData] = useState([])

    const isNotAllRegion = sidoQueryParam !== '' && sidoQueryParam !== 'all'

    const {
        totalTutorCount,
        totalHiredTutorCount,
        totalApplicants,
        totalVisit,
        totalCenter,
        monthApplicants,
        totalProgramCount,
        totalUnderPrivileged, // 소외
        totalRegularStudents, // 일반
        totalNumberOfBenefited, //수혜 학생
        totalNewEmployee, // 신규 강사수
        totalReemployee, // 재고용 강사수
    } = localStatisticsData

    /**
     * total local 데이터 ??
     * @returns {Promise<void>}
     */
    const getTotalStatistics = async () => {
        try {
            const response = await getCountRPC(sidoQueryParam)
            if (response.error) {
                console.log(response.error.message)
            } else {
                setLocalStatisticsData(response)
            }
        } catch (error) {
            console.log(error.message)
        }
    }

    /**
     * querystring update
     * @param paramName
     * @param paramValue
     * @param loading
     */
    const updateQueryStringAndNavigate = (paramName, paramValue, loading) => {
        const { pathname } = location
        const previousParams = queryParams.get(paramName)

        // 기존값과 동일할 시 변경하지 않음
        if (paramName === 'sido' && paramValue === previousParams) {
            return
        }

        if (paramValue) {
            if (previousParams === paramValue && paramName !== 'sido') {
                queryParams.delete(paramName)
            } else {
                queryParams.set(paramName, paramValue)
                // setSidoQueryParamState(paramName, paramValue)
            }
        }

        const newQueryString = queryParams.toString()
        const newLocation = newQueryString ? `${pathname}?${newQueryString}` : pathname

        if (loading) {
            loading(false)
        }
        navigate(newLocation)
    }

    const imgName = () => {
        switch (sidoQueryParam) {
            case '강원':
                return map_gangwon
            case '광주':
                return map_gwangju
            case '경기':
                return map_gyeonggi
            case '경남':
                return map_gyeongnam
            case '경북':
                return map_kyeongbuk
            case '대구':
                return map_daegu
            case '울산':
                return map_ulsan
            case '인천':
                return map_incheon
            case '전남':
                return map_jeonnam
            case '전북':
                return map_jeonbuk
            case '제주':
                return map_jeju
            case '충남':
                return map_chungnam
            case '충북':
                return map_chungbug
            default:
                return map_gangwon
        }
    }
    const aggregateMonthApplicants = data => {
        const aggregated = Array(12).fill(0)

        if (Array.isArray(data)) {
            if (sidoQueryParam === 'all') {
                data.forEach(region => {
                    if (region.monthApplicants && Array.isArray(region.monthApplicants)) {
                        region.monthApplicants.forEach((count, index) => {
                            aggregated[index] += count
                        })
                    }
                })
            }
        }
        return aggregated
    }

    const aggregatedMonthApplicants = aggregateMonthApplicants(localStatisticsData || [])

    useEffect(() => {
        if (isLogin) {
            if ((isMaster || isSubMaster) && !queryParams.get('sido')) {
                queryParams.set('sido', 'all')
                navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true })
            } else {
                getTotalStatistics()
            }
        } else {
            navigate('/')
        }
    }, [isMaster, isSubMaster, location, navigate])

    return (
        <PageBanner title="성과 분석" bannerIcon={<BannerIconSystem />}>
            {regionId === 'all' && <FilterLayout updateQueryStringAndNavigate={updateQueryStringAndNavigate} />}
            <div className={styles.wrapper}>
                <h6 className={styles.title} style={{ marginBottom: 20, padding: 40, textAlign: 'center' }}>
                    {sidoQueryParam === '' || sidoQueryParam === 'all' ? '' : sidoQueryParam} 지역정보
                </h6>
                <Row gutter={18}>
                    <Col span={12}>
                        <div className={styles.mapArea}>
                            <h5 className={styles.subTitle}>
                                2024년 진행완료 교육
                                <Tooltip
                                    placement="top"
                                    title={
                                        <div>
                                            <strong>2024년 진행완료 교육</strong>
                                            <ul style={{ marginTop: 10, color: 'gray' }}>
                                                <li>삭제되지 않은 프로그램만 포함됩니다.</li>
                                                <li>프로그램 운영 상태값이 완료인 프로그램만 포함됩니다.</li>
                                                <li>프로그램의 종료 연도가 현재 연도와 동일해야 합니다. </li>
                                            </ul>
                                        </div>
                                    }
                                >
                                    <ExclamationCircleOutlined
                                        style={{
                                            fontSize: '24px',
                                            color: '#fff',
                                            margin: '8px 0 0 12px',
                                        }}
                                    />
                                </Tooltip>
                            </h5>

                            {sidoQueryParam === '' || sidoQueryParam === 'all' ? (
                                <div className={styles.mapWrap}>
                                    <div className={styles.image_wrap}>
                                        <Image src={map} preview={false} />
                                        {/* 지역 데이터 순서 - 인천 > 경기 > 강원 > 충남 > 충북 > 경북 > 전북 > 대구 > 전남 > 광주 > 경남 > 울산 > 제주 으로 데이터 바인딩 */}
                                        <p className={`${styles.region} ${styles.incheon}`}>
                                            {localStatisticsData[7]?.totalProgramCount?.toLocaleString()}
                                        </p>
                                        <p className={`${styles.region} ${styles.gyeonggi}`}>
                                            {localStatisticsData[1]?.totalProgramCount?.toLocaleString()}
                                        </p>
                                        <p className={`${styles.region} ${styles.gangwon}`}>
                                            {localStatisticsData[0]?.totalProgramCount?.toLocaleString()}
                                        </p>
                                        <p className={`${styles.region} ${styles.chungnam}`}>
                                            {localStatisticsData[11]?.totalProgramCount?.toLocaleString()}
                                        </p>
                                        <p className={`${styles.region} ${styles.chungbug}`}>
                                            {localStatisticsData[12]?.totalProgramCount?.toLocaleString()}
                                        </p>
                                        <p className={`${styles.region} ${styles.kyeongbuk}`}>
                                            {localStatisticsData[3]?.totalProgramCount?.toLocaleString()}
                                        </p>
                                        <p className={`${styles.region} ${styles.jeonbuk}`}>
                                            {localStatisticsData[9]?.totalProgramCount?.toLocaleString()}
                                        </p>
                                        <p className={`${styles.region} ${styles.daegu}`}>
                                            {localStatisticsData[5]?.totalProgramCount?.toLocaleString()}
                                        </p>
                                        <p className={`${styles.region} ${styles.jeonnam}`}>
                                            {localStatisticsData[8]?.totalProgramCount?.toLocaleString()}
                                        </p>
                                        <p className={`${styles.region} ${styles.gwangju}`}>
                                            {localStatisticsData[4]?.totalProgramCount?.toLocaleString()}
                                        </p>
                                        <p className={`${styles.region} ${styles.gyeongnam}`}>
                                            {localStatisticsData[2]?.totalProgramCount?.toLocaleString()}
                                        </p>
                                        <p className={`${styles.region} ${styles.ulsan}`}>
                                            {localStatisticsData[6]?.totalProgramCount?.toLocaleString()}
                                        </p>
                                        <p className={`${styles.region} ${styles.jeju}`}>
                                            {localStatisticsData[10]?.totalProgramCount?.toLocaleString()}
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                <div className={`${styles.mapWrap} ${styles.zone}`}>
                                    <div
                                        className={`${styles.image_wrap} ${
                                            sidoQueryParam === '인천'
                                                ? styles.incheon
                                                : sidoQueryParam === '경기'
                                                ? styles.gyeonggi
                                                : sidoQueryParam === '충북'
                                                ? styles.chungbug
                                                : ''
                                        }`}
                                    >
                                        <Image src={imgName()} preview={false} />
                                        <div className={styles.inner_wrap}>
                                            <span> {sidoQueryParam}</span>
                                            <p className={styles.region}>{totalProgramCount?.toLocaleString()}</p>
                                        </div>
                                    </div>
                                    <div className={styles.ongoing_items}>
                                        <div className={styles.item}>
                                            <i className={styles.file}>파일 아이콘</i>
                                            <span>전체 강사</span>
                                            <strong>{totalTutorCount}명</strong>
                                        </div>
                                        <div className={styles.item}>
                                            <i className={styles.prize}>상장 아이콘</i>
                                            <span>채용 강사</span>
                                            <strong>{totalHiredTutorCount}명</strong>
                                        </div>
                                        <div className={styles.item}>
                                            <i className={styles.mortarboard}>학사모 아이콘</i>
                                            <span>수혜 학생</span>
                                            <strong>{totalNumberOfBenefited}명</strong>
                                        </div>
                                        <div className={styles.item}>
                                            <i className={styles.book}>책 아이콘</i>
                                            <span>찾아가는 교육</span>
                                            <strong>{totalVisit}%</strong>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Col>
                    <Col span={12}>
                        <PieChart
                            name="instructor"
                            dataSource={{
                                totalCount: isNotAllRegion
                                    ? totalNewEmployee || 0
                                    : Array.isArray(localStatisticsData) && localStatisticsData.length > 0
                                    ? localStatisticsData.reduce((acc, data) => acc + (data.totalNewEmployee || 0), 0)
                                    : 0,
                                partOfCount: isNotAllRegion
                                    ? totalReemployee || 0
                                    : Array.isArray(localStatisticsData) && localStatisticsData.length > 0
                                    ? localStatisticsData.reduce((acc, data) => acc + (data.totalReemployee || 0), 0)
                                    : 0,
                            }}
                            dataName={['신규 강사수', '재고용 강사수']}
                            chartTitle="채용율"
                            title="강사"
                            tooltip={
                                <>
                                    <div>
                                        <strong>신규 강사수</strong>
                                        <ul style={{ marginTop: 10, color: 'gray' }}>
                                            <li>삭제되지 않은 강사만 포함됩니다.</li>
                                            <li>신규강사 여부 값이 Y인 강사만 포함됩니다. </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <strong>재고용 강사수</strong>
                                        <ul style={{ marginTop: 10, color: 'gray' }}>
                                            <li>삭제되지 않은 강사만 포함됩니다.</li>
                                            <li>재고용 여부 값이 Y인 강사만 포함됩니다. </li>
                                        </ul>
                                    </div>
                                </>
                            }
                        />
                        <PieChart
                            name="program"
                            dataSource={{
                                totalCount: isNotAllRegion
                                    ? totalRegularStudents || 0
                                    : Array.isArray(localStatisticsData) && localStatisticsData.length > 0
                                    ? localStatisticsData.reduce(
                                          (acc, data) => acc + (data.totalRegularStudents || 0),
                                          0,
                                      )
                                    : 0,
                                partOfCount: isNotAllRegion
                                    ? totalUnderPrivileged || 0
                                    : Array.isArray(localStatisticsData) && localStatisticsData.length > 0
                                    ? localStatisticsData.reduce(
                                          (acc, data) => acc + (data.totalUnderPrivileged || 0),
                                          0,
                                      )
                                    : 0,
                            }}
                            dataName={['일반학교 프로그램수', '그 외 프로그램수', '전체 프로그램수']}
                            chartTitle="소외학생비율"
                            title="프로그램"
                            tooltip={
                                <>
                                    <div>
                                        <strong>일반학교 프로그램수</strong>
                                        <ul style={{ marginTop: 10, color: 'gray' }}>
                                            <li>삭제되지 않은 프로그램만 포함됩니다.</li>
                                            <li>수요기관이 일반학교인 프로그램만 포함됩니다.</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <strong>그 외 프로그램수</strong>
                                        <ul style={{ marginTop: 10, color: 'gray' }}>
                                            <li>삭제되지 않은 프로그램만 포함됩니다.</li>
                                            <li>수요기관이 일반학교가 아닌 프로그램만 포함됩니다.</li>
                                        </ul>
                                    </div>
                                </>
                            }
                        />
                    </Col>
                </Row>
                <Row className={styles.chartArea}>
                    <Col span={24}>
                        <div className={styles.bar}>
                            <StudentBarChart
                                dataSource={
                                    isNotAllRegion
                                        ? [
                                              { key: '1', month: '1월', completedProgramCount: monthApplicants?.[0] },
                                              { key: '2', month: '2월', completedProgramCount: monthApplicants?.[1] },
                                              { key: '3', month: '3월', completedProgramCount: monthApplicants?.[2] },
                                              { key: '4', month: '4월', completedProgramCount: monthApplicants?.[3] },
                                              { key: '5', month: '5월', completedProgramCount: monthApplicants?.[4] },
                                              { key: '6', month: '6월', completedProgramCount: monthApplicants?.[5] },
                                              { key: '7', month: '7월', completedProgramCount: monthApplicants?.[6] },
                                              { key: '8', month: '8월', completedProgramCount: monthApplicants?.[7] },
                                              { key: '9', month: '9월', completedProgramCount: monthApplicants?.[8] },
                                              { key: '10', month: '10월', completedProgramCount: monthApplicants?.[9] },
                                              {
                                                  key: '11',
                                                  month: '11월',
                                                  completedProgramCount: monthApplicants?.[10],
                                              },
                                              {
                                                  key: '12',
                                                  month: '12월',
                                                  completedProgramCount: monthApplicants?.[11],
                                              },
                                          ]
                                        : aggregatedMonthApplicants.map((count, index) => ({
                                              key: String(index + 1),
                                              month: `${index + 1}월`,
                                              completedProgramCount: count,
                                          }))
                                }
                                tooltip={
                                    <div>
                                        <strong>월별 진행완료 교육수</strong>
                                        <ul style={{ marginTop: 10, color: 'gray' }}>
                                            <li>삭제되지 않은 프로그램만 포함됩니다.</li>
                                            <li>프로그램 운영 상태값이 완료인 프로그램만 포함됩니다.</li>
                                            <li>프로그램의 종료 연도가 현재 연도와 동일해야 합니다. </li>
                                            <li>각 월에 종료된 프로그램만 포함됩니다.</li>
                                        </ul>
                                    </div>
                                }
                            />
                        </div>
                    </Col>
                </Row>
                <Row className={styles.chartArea} gutter={18}>
                    <Col span={12}>
                        <DonutChart
                            dataSource={{
                                totalCount: isNotAllRegion
                                    ? totalApplicants || 0
                                    : Array.isArray(localStatisticsData) && localStatisticsData.length > 0
                                    ? localStatisticsData.reduce((acc, data) => acc + (data.totalApplicants || 0), 0)
                                    : 0,
                                partOfCount: isNotAllRegion
                                    ? totalNumberOfBenefited || 0
                                    : Array.isArray(localStatisticsData) && localStatisticsData.length > 0
                                    ? localStatisticsData.reduce(
                                          (acc, data) => acc + (data.totalNumberOfBenefited || 0),
                                          0,
                                      )
                                    : 0,
                            }}
                            dataName={['일반 학생수', '수혜 학생수']}
                            title="수혜학생비율"
                            colors={['#FF6F4B', '#67ADDD']}
                            tooltip={
                                <>
                                    <div>
                                        <strong>수혜 학생수</strong>
                                        <ul style={{ marginTop: 10, color: 'gray' }}>
                                            <li>삭제되지 않은 프로그램만 포함됩니다.</li>
                                            <li>각 프로그램의 교육 수료 인원을 모두 합산합니다.</li>
                                            <li>
                                                수혜 학생 수 = (교육 수료 인원의 총합 / (교육 신청 인원의 총합 + 교육
                                                수료 인원의 총합)) × 100
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <strong>일반 학생수 </strong>
                                        <ul style={{ marginTop: 10, color: 'gray' }}>
                                            <li>삭제되지 않은 프로그램만 포함됩니다.</li>
                                            <li>각 프로그램의 교육 신청 인원을 모두 합산합니다.</li>
                                            <li>
                                                일반 학생 수 = (교육 신청 인원의 총합 / (교육 신청 인원의 총합 + 교육
                                                수료 인원의 총합)) × 100
                                            </li>
                                        </ul>
                                    </div>
                                </>
                            }
                        />
                    </Col>
                    <Col span={12}>
                        <DonutChart
                            dataSource={{
                                totalCount: isNotAllRegion
                                    ? totalCenter || 0
                                    : Array.isArray(localStatisticsData) && localStatisticsData.length > 0
                                    ? localStatisticsData.reduce((acc, data) => acc + (data.totalCenter || 0), 0)
                                    : 0,
                                partOfCount: isNotAllRegion
                                    ? totalVisit || 0
                                    : Array.isArray(localStatisticsData) && localStatisticsData.length > 0
                                    ? localStatisticsData.reduce((acc, data) => acc + (data.totalVisit || 0), 0)
                                    : 0,
                            }}
                            dataName={['센터 교육', '찾아가는 교육']}
                            title="찾아가는 교육 비율"
                            colors={['#00A886', '#67ADDD']}
                            tooltip={
                                <>
                                    <div>
                                        <strong>찾아가는 교육 </strong>
                                        <ul style={{ marginTop: 10, color: 'gray' }}>
                                            <li>교육 유형이 방문 교육인 프로그램만 포함됩니다.</li>
                                            <li>기타로 분류되지 않은 모든 프로그램이 모수로 사용됩니다.</li>
                                            <li>
                                                방문 교육 비율 = (방문 교육 프로그램 수 / 전체 프로그램 수) * 100
                                                (소수점 버림)
                                            </li>
                                            <li>
                                                찾아가는 교육 = (방문 교육 비율 / (방문 교육 비율 + 센터 교육 비율)) *
                                                100
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <strong>센터교육</strong>
                                        <ul style={{ marginTop: 10, color: 'gray' }}>
                                            <li>교육 유형이 센터 교육인 프로그램만 포함됩니다.</li>
                                            <li>기타로 분류되지 않은 모든 프로그램이 모수로 사용됩니다.</li>
                                            <li>
                                                센터 교육 비율 = (센터 교육 프로그램 수 / 전체 프로그램 수) * 100
                                                (소수점 버림)
                                            </li>
                                            <li>
                                                센터 교육 = (센터 교육 비율 / (방문 교육 비율 + 센터 교육 비율)) * 100
                                            </li>
                                        </ul>
                                    </div>
                                </>
                            }
                        />
                    </Col>
                </Row>
            </div>
        </PageBanner>
    )
}

export default Statistics
