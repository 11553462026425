import { Col, Row } from 'antd'
import SectionRow from 'components/Report/SectionRow'
import TotalExecutionRate from 'pages/Report/Detail/TotalExecutionRate'
import BalanceSection from 'pages/Report/Detail/BalanceSection'

import { useLocation } from 'react-router-dom'

const ProjectExpenseSection = ({
    totalBudget,
    reportTotal,
    monthlyBudget,
    monthlyBudgetExecution,
    accumulated,
    executionData,
}) => {
    const { pathname } = useLocation()
    const paths = pathname.split('/').filter(path => path !== '')

    return (
        <>
            <Row>
                <Col span={6}>
                    {/* 총 사업비 */}
                    <SectionRow
                        title={totalBudget.title}
                        rows={totalBudget.rows}
                        total={reportTotal?.total}
                        name="totalBudgetTotal"
                        length={3}
                    />
                </Col>
                <Col span={6}>
                    {/* 당월 예산 집행 */}
                    <SectionRow
                        title={monthlyBudget.title}
                        rows={monthlyBudget.rows}
                        total={monthlyBudgetExecution?.total}
                        name="monthlyBudgetTotal"
                        length={2}
                    />
                </Col>
                <Col span={6}>
                    {/* 누적 집행 금액 */}
                    <SectionRow
                        title={accumulated.title}
                        rows={accumulated.rows}
                        total={executionData?.executionTotal || 0}
                        name="accumulatedTotal"
                        length={2}
                    />
                </Col>
                {/* 총 집행률 */}
                <Col span={6}>
                    <TotalExecutionRate
                        // totalExecutionRate={totalExecutionRate}
                        monthlyBudget={monthlyBudgetExecution}
                        executionData={executionData}
                        reportTotal={reportTotal?.total || 1}
                    />
                </Col>
            </Row>
            {paths[1] !== 'register' && paths[1] !== 'edit' ? (
                <Row>
                    <Col span={24}>
                        <BalanceSection
                            total={reportTotal}
                            execution={executionData}
                            monthlyBudget={monthlyBudgetExecution}
                        />
                    </Col>
                </Row>
            ) : null}
        </>
    )
}

export default ProjectExpenseSection
