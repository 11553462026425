import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import dayjs from 'dayjs'

import { getSurveyById } from 'api/survey/survey.api'
import { getSurveyDemanderList, patchSurveyDemanderById } from 'api/survey/surveyDemander.api'

import { BannerIconSurvey } from 'assets/Icons'
import { Col, Row, Flex, message } from 'antd'

import PageBanner from 'layout/PageBanner'

import Label from 'components/Ui/Label'
import ColorButton from 'components/AntD/Button/ColorButton'
import Spinner from 'components/AntD/Spin'
import ListView from 'components/List/ListView'
import CopyClipBoard from 'components/CopyClipboard'
import SanitizedHTML from 'components/sanitizeHTML'
import Pagenation from 'components/Pagenation'

import styles from 'assets/scss/detail.module.scss'

import ListNotFound from 'pages/PageNotFound/ListNotFound'
import FilterAndSearchArea from 'components/Filter'

import { useSelector } from 'react-redux'
import { getAllUserInfo } from 'store/Slices/user'

/**
 * 수요처 목록
 * @returns {JSX.Element}
 * @constructor
 */
const SurveyDetail = () => {
    const success = 'success'
    const error = 'error'
    const [messageApi, contextHolder] = message.useMessage()
    const { userInfo } = useSelector(getAllUserInfo)
    const { regionId } = userInfo

    const queryParams = new URLSearchParams(window.location.search)
    const location = useLocation()
    const { pathname } = useLocation()

    const navigate = useNavigate()
    const { id } = useParams()

    const path = pathname.split('/')[1]

    const [isLoading, setLoading] = useState(true)
    const [surveyDetail, setSurveyDetail] = useState({
        survey: null,
        surveyDemander: null,
        page: null,
        counter: null,
    })
    const [switchStates, setSwitchStates] = useState(null)

    const { survey, surveyDemander, page, counter } = surveyDetail || {}
    const { surveyName, startDate, endDate, surveyContent, surveyUuid } = survey || {}

    const urlLink = `${process.env.REACT_APP_BASE_URL}/survey/submit/${surveyUuid}`

    const startDateFormat = dayjs(startDate).format('YYYY년 MM월 DD일')
    const endDateFormat = dayjs(endDate).format('YYYY년 MM월 DD일')

    // const { result } = surveyDetailData
    // const { page, sourceOfDemand } = result || {}
    const { currentPage, totalPages, totalItems } = page || {}

    const updateQueryStringAndNavigate = (paramName, paramValue) => {
        const { pathname } = location
        const previousParams = queryParams.get(paramName)

        // 기존값과 동일할 시 변경하지 않음
        if (paramName === 'regionId' && paramValue === previousParams) {
            return
        }

        // 새로운 파라미터 추가 (단, 값이 비어있지 않을 때)
        if (paramValue) {
            if (previousParams === paramValue) {
                queryParams.delete(paramName)
            } else {
                queryParams.set(paramName, paramValue)
            }
        }

        if (paramName === 'startDate' && paramValue === '') {
            queryParams.delete(paramName)
        }

        if (paramName === 'endDate' && paramValue === '') {
            queryParams.delete(paramName)
        }

        // 필터 선택 시 쿼리스트링에 포함된 페이지 정보 제거
        if (paramName !== 'page') {
            queryParams.delete('page')
        }

        const newQueryString = queryParams.toString()
        const newLocation = newQueryString ? `${pathname}?${newQueryString}` : pathname

        navigate(newLocation)
    }

    const fetchSurveyDetail = async search => {
        // setLoading(true)
        const survey = await getSurveyById(id)
        const { data } = await getSurveyDemanderList(survey?.surveyUuid, search, path)
        const surveyDemander = data && data.surveys ? data.surveys : null

        if (Array.isArray(surveyDemander)) {
            surveyDemander.forEach(v => {
                const sDate = dayjs(v.startDate)
                const eDate = dayjs(v.endDate)
                const diffMonth = eDate.diff(sDate, 'month')
                const diffDay = eDate.diff(sDate, 'day')
                const period = diffMonth ? `${diffMonth}개월` : diffDay ? `${diffDay}일` : '미정'

                v.period = period
            })

            setSwitchStates(surveyDemander.map(v => v.isSelected))
        }

        setSurveyDetail({
            survey,
            surveyDemander,
            page: data.page,
            counter: data.counter,
        })
        setLoading(false)
    }

    const handleChangeSwitch = async (checked, e, record, i) => {
        const demanderId = e.currentTarget.getAttribute('data-id')
        const originalState = switchStates[i]

        setSwitchStates(prevStates => prevStates.map((state, _i) => (_i === i ? checked : state)))

        const res = await patchSurveyDemanderById(demanderId, checked)

        if (res) {
            messageApi.open({
                type: success,
                content: '채택유무 수정이 완료되었습니다.',
                duration: 3,
            })
        } else {
            //error
            setSwitchStates(prevStates => prevStates.map((state, _i) => (_i === i ? originalState : state)))

            messageApi.open({
                type: error,
                content: '채택유무 수정이 실패하였습니다.',
                duration: 3,
            })
        }
    }

    const formattedSurveyDemander = surveyDemander?.map(item => ({
        ...item,
        startDate: startDateFormat,
        endDate: endDateFormat,
    }))

    useEffect(() => {
        const { search } = location
        const searchPath = search.split('?')[1] || ''

        fetchSurveyDetail(searchPath)
    }, [location])

    useEffect(() => {
        console.log('switchStates')
    }, [switchStates])

    return (
        <>
            {contextHolder}
            <PageBanner title="수요조사 상세" bannerIcon={<BannerIconSurvey />}>
                <div className={`${styles.area}`}>
                    <span className={styles.title_lg}>{surveyName}</span>
                    <span className={`${styles.title_sm}`}>수요조사 정보</span>
                    <div className={`${styles.box_lg} ${styles.border_style} ${styles.mb_lg}`}>
                        <Row className={styles.row_item}>
                            <Col span={12}>
                                <Row>
                                    <Col span={10}>
                                        <Label label="모집 시작일" />
                                    </Col>
                                    <Col span={14} className={styles.padding_y_medium}>
                                        <span>{startDateFormat}</span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={10}>
                                        <Label label="모집 마감일" />
                                    </Col>
                                    <Col span={14} className={styles.padding_y_medium}>
                                        <span>{endDateFormat}</span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className={styles.row_item}>
                            <Col span={5}>
                                <Label label="URL 정보" />
                            </Col>
                            <Col span={19} className={styles.padding_y_medium}>
                                <CopyClipBoard url={urlLink} text="URL 복사하기" />
                            </Col>
                        </Row>
                    </div>
                    <div className={styles.mb_xl}>
                        <span className={styles.title_sm}>수요조사 공고</span>
                        <div
                            className={`${styles.text_dark_gray} ${styles.border_style} ${styles.padding_x_medium} ${styles.padding_y_medium} ${styles.mb_lg}`}
                        >
                            <SanitizedHTML html={surveyContent} />
                        </div>
                    </div>
                    {isLoading ? (
                        <div className={styles.spin_wrap}>
                            <Spinner />
                        </div>
                    ) : (
                        <>
                            <FilterAndSearchArea
                                filterCount={counter}
                                updateQueryStringAndNavigate={updateQueryStringAndNavigate}
                            />

                            {surveyDemander === null || !surveyDemander || surveyDemander.length === 0 ? (
                                <ListNotFound />
                            ) : (
                                <>
                                    <ListView
                                        list={formattedSurveyDemander.slice(0, 10)}
                                        setLoading={setLoading}
                                        fetchList={fetchSurveyDetail}
                                        handleChangeSwitch={handleChangeSwitch}
                                        switchStates={switchStates}
                                    />
                                    <Pagenation
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        totalItems={totalItems}
                                        updateFtn={updateQueryStringAndNavigate}
                                    />
                                </>
                            )}
                        </>
                    )}

                    {/*{surveyDemander && surveyDemander.length > 0 && (*/}
                    <Flex justify="center" align="center" gap={8}>
                        {regionId !== 'all' && (
                            <ColorButton
                                size="large"
                                type="primary"
                                active
                                filterColor="navy"
                                onClick={() => navigate(`/survey/edit/${id}`)}
                            >
                                수정
                            </ColorButton>
                        )}
                        <ColorButton
                            size="large"
                            style={{
                                borderColor: '#024383',
                                color: 'rgba(2, 67, 131)',
                                height: '60px',
                                padding: '19px 64px',
                                fontSize: '18px',
                                lineHeight: '22px',
                                textAlign: 'center',
                                fontFamily: 'Noto Sans KR',
                                display: 'inline-flex',
                                fontStyle: 'normal',
                                fontWeight: 700,
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '10px',
                                boxShadow: 'none',
                            }}
                            onClick={() => navigate('/survey')}
                        >
                            목록
                        </ColorButton>
                    </Flex>
                    {/*)}*/}
                </div>
            </PageBanner>
        </>
    )
}

export default SurveyDetail
