import ColorButton from 'components/AntD/Button/ColorButton'
import { colorTheme } from 'assets/colorTheme'
import styles from './filter.module.scss'

const FilterButton = ({ icon, title, onClick, currentParamValue, filterColor, disabled }) => {
    const isActiveTab = (currenValue, tit) => {
        if (currenValue === tit) {
            return true
        }
        if (tit === '신규' && currenValue === 'Y') {
            return true
        }
        if (tit === '재고용' && currenValue === 'Y') {
            return true
        }
        if (tit === '외국인' && currenValue === 'Y') {
            return true
        }
        if (tit === '장애인' && currenValue === 'Y') {
            return true
        }
        if (tit === '보훈대상' && currenValue === 'Y') {
            return true
        }
        if (tit === '교원자격' && currenValue === 'Y') {
            return true
        }
        if (tit === '강의경험' && currenValue === 'Y') {
            return true
        }
        if (tit === '지난달' && currenValue) {
            return true
        }
        if (tit === '이번달' && currenValue) {
            return true
        }
        if (tit === '센터' && currenValue === '센터교육') {
            return true
        }
        if (tit === '방문' && currenValue === '방문교육') {
            return true
        }
        if (tit === '일반강사' && currenValue === 'N') {
            return true
        }
        if (tit === '고급강사' && currenValue === 'Y') {
            return true
        }
        if (tit === '청년' && currenValue === 'Y') {
            return true
        }
        if (tit === '전문퇴직자' && currenValue === 'Y') {
            return true
        }
        if (tit === '경력단절여성' && currenValue === 'Y') {
            return true
        }
        // 대여반납
        if (tit === '방문' && currenValue === '방문교육') {
            return true
        }
        if (tit === '반납' && currenValue === '반납완료') {
            return true
        }
        if (tit === '대여' && currenValue === '대여확정') {
            return true
        }
        if (tit === '전체' && currenValue === 'all') {
            return true
        }

        return false
    }

    const { gray } = colorTheme

    const getButtonColor = colorValue => {
        const colorMap = {
            yellow: styles.yellow,
            green: styles.green,
            blue: styles.blue,
            darkgray: styles.darkgray,
            black: styles.black,
            border: styles.border,
            red: styles.red,
        }
        return colorMap[colorValue] || ''
    }

    const getFilterColor = () => {
        if (filterColor === 'yellow') {
            return styles.yellow
        }
        if (filterColor === 'green') {
            return styles.green
        }
        if (filterColor === 'blue') {
            return styles.blue
        }
        if (filterColor === 'darkgray') {
            return styles.darkgray
        }
        if (filterColor === 'border') {
            return styles.border
        }
        if (filterColor === 'red') {
            return styles.red
        }
        return ''
    }

    return (
        <div
            className={`${styles.btn} ${isActiveTab(currentParamValue, title) ? styles.active : ''} ${
                filterColor === 'black' && styles.border_color
            } ${getFilterColor()}`}
        >
            <ColorButton
                onClick={onClick}
                active={isActiveTab(currentParamValue, title)}
                filterColor={filterColor}
                override={gray['400']}
                size="filter"
                disabled={disabled}
            >
                {icon}
                <div className={`${getButtonColor(filterColor)}`}>{title}</div>
            </ColorButton>
        </div>
    )
}
export default FilterButton
