import React, { useEffect, useCallback, useState } from 'react'
import PageBanner from 'layout/PageBanner'
import { BannerIconTools } from 'assets/Icons'

import { useParams } from 'react-router-dom'
import { getToolsInventoryById } from 'api/tools/toolsInventory.api'
import { useSelector } from 'react-redux'
import Spinner from 'components/AntD/Spin'
import RegistrationAndEditFormTools from 'components/RegistrationAndEditForm/Tools'
import styles from 'assets/scss/detail.module.scss'

const ToolsDetail = () => {
    const [loading, setLoading] = useState(true)
    const { id } = useParams()
    const { regionId } = useSelector(state => state.user.userInfo)
    const [toolsInventoryDetail, setToolsInventoryDetail] = useState(null)
    const fetchToolsInventory = useCallback(async () => {
        try {
            const { result } = await getToolsInventoryById(id, regionId)
            setToolsInventoryDetail(result[0])
        } catch (err) {
            console.error(err)
        } finally {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        fetchToolsInventory()
    }, [])

    return (
        <PageBanner title="재고 관리 상세" bannerIcon={<BannerIconTools />}>
            <div className={styles.detail_padding}>
                {loading ? (
                    <div className={styles.spin_wrap}>
                        <Spinner />
                    </div>
                ) : (
                    <RegistrationAndEditFormTools toolsInventory={toolsInventoryDetail} id={id} />
                )}
            </div>
        </PageBanner>
    )
}

export default ToolsDetail
