import dayjs from 'dayjs'

import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { message } from 'antd'
import Alert from 'components/AntD/Alert'
import InfoDialog from 'components/AntD/Dialog'
import { formatDate, getStatus, processDateRange, processItem } from 'utils/utilCommon'

import { useSelector } from 'react-redux'
import { getUser } from 'store/Slices/user'

import SubContent from './SubContent'
import RegisterSection from './RegisterSection'

import styles from './register.module.scss'

const registerError = `등록에 실패하였습니다.`
const duplicatedError = `동일한 강사명이 있습니다.`
const duplicatedToolsNumError = `동일한 자산번호가 있습니다.`
const unregisterError = `등록되지 않은 강사명이 있습니다. (ex: 주강사, 보조강사)`
const requiredNullError = `누락된 필수값이 있습니다.`

const RegisterBulk = () => {
    const { pathname } = useLocation()
    const paths = pathname.split('/').filter(path => path !== '')
    const navigate = useNavigate()
    const { regionId, userId } = useSelector(getUser)

    const [modalOpen, setModalOpen] = useState(false)
    const [alertOpen, setAlertOpen] = useState(false)

    const [dataState, setDataState] = useState({
        isUploaded: false,
        jsonData: [],
        modifiedData: [],
        fileName: '',
        convertType: 'sheet_to_json',
        hasDuplicateTutorName: null, // 강사관리 등록 시 중복 강사명 여부
        unRegisteredTutorName: null, // 교육관리 등록 시 주강사, 보조강사 등록 여부
        matchingTutorInfo: null, // 교육관리 등록 시 매칭되는 강사정보 (주강사, 보조강사)
        requiredNull: null, // 필수 데이터 누락 확인
        isDuplicateToolsNum: null, // 재고관리 등록 시 자산번호 중복 여부
        isInvalidDate: null, // 날짜 포맷 확인 (대여반납)
        isValidRentalStatus: null, // 대여반납 : 대여상태 포맷 확인
        isAnyToolUnmatched: null, // 대여반납 : 교구재 매칭 여부 확인
        isAnyTutorUnmatched: null, // 대여반납 : 대여자(강사) 매칭 여부 확인
    })

    const {
        jsonData,
        fileName,
        modifiedData,
        hasDuplicateTutorName,
        isUploaded,
        unRegisteredTutorName,
        matchingTutorInfo,
        requiredNull,
        isInvalidDate,
        isDuplicateToolsNum,
        isAnyToolUnmatched,
        isAnyTutorUnmatched,
    } = dataState

    const [messageApi, contextHolder] = message.useMessage()
    const errorMessage = errorMessage => {
        messageApi.open({
            type: 'error',
            content: errorMessage,
            duration: 5,
        })
    }

    const title = ''
    const content = (
        <>
            <p>
                요청하신 <span className={styles.green}>총 {jsonData.length}건</span>의 데이터 입력이
            </p>
            <p>완료 되었습니다.</p>
        </>
    )

    const handleConfirm = () => {
        setModalOpen(false)
        if (paths[0] === 'tutors' && paths[1] === 'register') {
            navigate('/tutors/')
        }
        if (paths[0] === 'program' && paths[1] === 'register') {
            navigate('/program/')
        }
        if (paths[0] === 'tools' && paths[1] === 'inventory') {
            navigate('/tools/inventory/')
        }
        if (paths[0] === 'tools' && paths[1] === 'rental') {
            navigate('/tools/rental/')
        }
    }

    /**
     * json 데이터 컨버팅
     * @param data
     * @returns {[]}
     */
    const handleConvertjsonData = data => {
        // 변환된 데이터를 담을 배열
        const transformedData = []
        const timestamp = dayjs().format('YYYY-MM-DD HH:mm:ss.SSSZ')
        if (paths[0] === 'program') {
            // 데이터 변환
            data.forEach(item => {
                const month = dayjs().format('MM')
                const transformedItem = {
                    sys_reg_dtime: timestamp,
                    is_delete: false,
                    sys_regr_id: userId,
                    sys_mod_dtime: null,
                    sys_modr_id: null,
                    region_id: regionId,
                    education_type:
                        item['유형*'] === '센터내 교육 (찾아오는 교육)'
                            ? '센터교육'
                            : item['유형*'] === '찾아가는 교육 (방문교육)'
                            ? '방문교육'
                            : '기타', // 센터내교육(찾아오는교육), 찾아가는교육(방문교육), 기타
                    course: item['정규구분*']?.trim() === '정규과정' ? '정규과정' : '비정규과정', // 정규과정 여부에 따라 설정
                    method: item['형태*'] === '온라인 교육' ? '온라인' : '오프라인', // 온라인 여부에 따라 설정
                    program_name: item['제목*'], // 프로그램명
                    detail: item['상세*'] || null, // 모델학교
                    category: item['구분*'] || null, // 수요기관 분류
                    grade: item['학년*'] || null, // 학년
                    classroom: item['학급'] || null, // 학급
                    organization_name: item['기관명*'], // 기관명
                    address_sido: item['주소(시/도)*'], // 시/도
                    address_sigungu: item['주소(시/군/구)*'], // 시/군/구
                    address_bname: item['주소(읍/면/동)*'], // 읍/면/동
                    area_name: item['권역'] || '', // 권역
                    status: getStatus(String(item['교육 시작일*']), String(item['교육 종료일*'])),
                    start_date: formatDate(String(item['교육 시작일*'])), // 교육 시작일
                    end_date: formatDate(String(item['교육 종료일*'])), // 교육 종료일
                    month_of_edu: Number(month),
                    class_hours: item['교육시간 (교시)*'], // 수업교시
                    number_of_session: item['교육횟수 (회)*'], // 교육횟수
                    number_of_applicants: item['교육 신청인원(명)*'], // 신청인원
                    number_of_graduates: item['교육수료인원 (명)'], // 수료인원
                    // 하드코딩 ; item['단기 / 중기 / 장기*'], // 교육프로그램 시간
                    time_number: item['교육시간 (교시)*'], //TODO: 하드코딩 변경해야 함. (테이블 삭제 필요)
                    manager_name: item['성명'] || null, // 수요처 담당자
                    manager_position: item['직위/직책'] || null, // 수요처 담당자 직책
                    manager_tel_number: item['대표번호'] || null, // 수요처 대표번호
                    manager_phone_number: item['휴대전화'] || null, // 수요처 담당자 전화번호
                    manager_email: item['이메일'] || null, // 수요처 이메일
                    manager_remarks: item['수요처 특이사항'] || null, // 빈 값으로 설정 (비고란)
                    teaching_materials: item['교보재'] || null, // 교보재
                    computer_yn: item['기관 컴퓨터 구비여부'] === 'Y', // 컴퓨터구비여부
                    number_of_attendance: item['출석인원'] || null, // 출석인원
                    lead_Instructors: item['주강사'], // 주강사 목록
                    assistants: item['보조강사'], // 보조강사 목록
                }
                transformedData.push(transformedItem)
            })
            return transformedData
        }

        if (paths[0] === 'tutors') {
            // 데이터 변환
            data.forEach(item => {
                const transformedItem = {
                    region_id: regionId,
                    is_delete: false,
                    sys_reg_dtime: timestamp,
                    sys_regr_id: userId,
                    sys_mod_dtime: null,
                    sys_modr_id: null,
                    tutor_name: item['성명*'],
                    sido: item['주소(시/도)*'],
                    sigungu: item['주소(시/군/구)*'],
                    bname: item['주소(읍/면/동)*'],
                    resident_num: item['주민등록번호*'],
                    contact: item['연락처*'],
                    email: item['이메일*'],
                    gender: item['성별*'],
                    area_name: item['권역'] || '',
                    cohort_start_year: String(item['기수 (시작년도)']) || '',
                    major: item['전공 (학과명)*'],
                    education_level: item['최종학력*'],
                    employment_type: item['고용형태'] || '',
                    employment_status: processDateRange(String(item['계약시작*']), String(item['계약만료*'])), // 하드코딩
                    start_date: formatDate(String(item['계약시작*'])),
                    end_date: formatDate(String(item['계약만료*'])),
                    account_holder_nm: item['예금주'] || '',
                    bank_nm: item['입금은행'] || '',
                    account_num: item['계좌번호'] || '',
                    tax_status: item['홈텍스 확인현황'] === 'Y',
                    business_type: item['사업장형태'] || '',
                    business_address: item['사업장주소'] || '',
                    business_name: item['사업장 상호'] || '',
                    date_of_establish: item['창업일자'] ? formatDate(String(item['창업일자'])) : null,
                    is_founded: item['창업구분'] || '',
                    business_registration_number: item['사업자등록번호'] || '',
                    appointment_num: item['위촉번호'] || '',
                    teacher_certification: item['교원자격보유'] === 'Y',
                    teaching_experience_general: processItem(item['일반 강의 경력']),
                    teaching_experience_sw: processItem(item['SW 강의경력']),
                    sw_certification: processItem(item['SW 자격증*']),
                    sign_language_yn: item['수화 가능 여부'] === 'Y',
                    teaching_experience_yn: item['강의경험 유무*'] === 'Y',
                    private_certification: item['SW 미래채움사업 민간자격 취득*'],
                    // classification: item['대상자 분류*'] === '경력단절여성' ? '경단여' : item['대상자 분류*'],
                    is_youth: item['청년*'],
                    is_retiree: item['전문퇴직자*'],
                    is_career_gap_female: item['경력단절여성*'],
                    is_foreign: item['외국인*'] === 'Y',
                    has_disability: item['장애여부*'] === 'Y',
                    is_new: item['신규강사 여부*'] === 'Y',
                    is_rehired: item['재고용여부*'] === 'Y',
                    is_advanced_instructor: item['고급강사 여부*'] === '고급강사' ? '고급강사' : '일반강사',
                    is_veterans: item['보훈여부'] === 'Y',
                    own_car_yn: item['자차여부'] === 'Y',
                    unique_num: item['고유번호'] || '',
                    local_talent: item['지역인재'] === 'Y',
                    continu_placement: item['연속배치'] === 'Y',
                    evaluation: item['강사평가'] || '',
                    remarks: item['비고'] || '',
                }
                transformedData.push(transformedItem)
            })
            return transformedData
        }

        if (paths[0] === 'tools' && paths[1] === 'inventory') {
            // 데이터 변환
            data.forEach(item => {
                const transformedItem = {
                    region_id: regionId,
                    is_delete: false,
                    tools_type: item['품목분류*'],
                    tools_name: item['교구명*'],
                    tools_num: item['자산번호*'],
                    acquire_date: formatDate(String(item['취득일*'])),
                    purchase_price: item['취득단가 (원)*'],
                    purchase_place: item['구매처'] || '',
                    tools_state: item['교구 상태*'],
                    defect_reason: item['불량 사유'] || '',
                    repair_result: item['수리내역 결과'] || '',
                    center_name: item['센터명'] || '',
                    stock_location: item['재고 위치'] || '',
                    manufacturer: item['제조사'] || '',
                    subject: item['과목 (활용)'] || '',
                    battery_state: item['배터리 상태'] || '',
                    dispose_date: formatDate(String(item['폐기일자'])) || '',
                    etc: item['비고'] || '',
                }
                transformedData.push(transformedItem)
            })
            return transformedData
        }

        if (paths[0] === 'tools' && paths[1] === 'rental') {
            data.forEach(item => {
                const transformedItem = {
                    region_id: regionId,
                    is_delete: false,
                    tools_name: item['품목 (교구명)*'],
                    tools_num: item['자산번호(제품번호)*'],
                    tutor_name: item['성명 (대여자)*'],
                    location_code: item['위치코드'],
                    rental_status: item['대여상태*'] || '',
                    using_institution: item['사용기관'],
                    rental_application_date: formatDate(String(item['대여 신청일자*'])) || '',
                    rental_dispatch_date: formatDate(String(item['대여 반출일자'])) || '',
                    rental_return_date: formatDate(String(item['대여 반납일자'])) || '',
                    rental_use_purpose: item['대여/사용목적*'] || '',
                    classification: item['구분'] || '',
                    affiliated_region: item['소속 권역'] || '',
                    specification: item['규격'] || '',
                    management_number: item['관리번호'] || '',
                    stock_in_sequence: item['입고순번'] || '',
                    region_classification: item['지역구분'] || '',
                    building_classification: item['건물구분'] || '',
                    remarks: item['비고'] || '',
                }
                transformedData.push(transformedItem)
            })
            return transformedData
        }
        return false
    }

    useEffect(() => {
        if (alertOpen) {
            setTimeout(() => {
                setAlertOpen(false)
            }, [3000])
        }
    }, [alertOpen])

    useEffect(() => {
        if (hasDuplicateTutorName) {
            errorMessage(duplicatedError)
        }
        if (unRegisteredTutorName) {
            errorMessage(unregisterError)
        }
    }, [isUploaded])

    return (
        <>
            {contextHolder}
            <div className={styles.all_content_wrap}>
                <RegisterSection
                    jsonData={jsonData}
                    fileName={fileName}
                    modifiedData={modifiedData}
                    setDataState={setDataState}
                    setAlertOpen={setAlertOpen}
                    setModalOpen={setModalOpen}
                    handleConvertjsonData={handleConvertjsonData}
                    errorMessage={errorMessage}
                    registerError={registerError}
                    duplicatedError={duplicatedError}
                    unregisterError={unregisterError}
                    requiredNullError={requiredNullError}
                    isInvalidDate={isInvalidDate}
                    hasDuplicateTutorName={hasDuplicateTutorName}
                    unRegisteredTutorName={unRegisteredTutorName}
                    matchingTutorInfo={matchingTutorInfo}
                    requiredNull={requiredNull}
                    isDuplicateToolsNum={isDuplicateToolsNum}
                    isAnyToolUnmatched={isAnyToolUnmatched}
                    isAnyTutorUnmatched={isAnyTutorUnmatched}
                    duplicatedToolsNumError={duplicatedToolsNumError}
                />
                <SubContent />
            </div>
            {alertOpen && (
                <Alert showIcon close isShow={alertOpen} onClose={setAlertOpen} message="엑셀 파일을 추가해주세요" />
            )}
            <InfoDialog
                title={title}
                content={content}
                centered
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                closable={false}
                maskClosable={false}
                handleConfirm={handleConfirm}
            />
        </>
    )
}

export default RegisterBulk
