import PageBanner from 'layout/PageBanner'

import Register from 'components/Register/Bulk'
import { BannerIconTools } from 'assets/Icons'

const ToolsInventoryRegisterBulk = () => {
    return (
        <PageBanner title="재고 관리 등록" bannerIcon={<BannerIconTools />}>
            <Register />
        </PageBanner>
    )
}

export default ToolsInventoryRegisterBulk
