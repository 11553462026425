import FilterButton from 'components/AntD/Button/FilterButton'
import { CheckFilterIcon } from 'assets/Icons'
import { Flex } from 'antd'
import styles from '../filter.module.scss'

const LocalStatisticsFilter = ({ queryParams, updateQueryStringAndNavigate, type }) => {
    let content = 'sido'
    if (type) {
        if (type === 'tutors' || type === 'program' || type === 'report' || type === 'tools') {
            content = 'regionId'
        }
    }

    const currentSido = queryParams.get(content) || ''
    const regions = [
        '전체',
        '강원',
        '광주',
        '경기',
        '경남',
        '경북',
        '대구',
        '울산',
        '인천',
        '전남',
        '전북',
        '제주',
        '충남',
        '충북',
    ]

    return (
        <Flex align="start">
            <span className={`body2 ${styles.title}`}>지역선택</span>
            <div className={styles.btn_wrap}>
                {regions.map(region => (
                    <FilterButton
                        key={region}
                        icon={<CheckFilterIcon />}
                        title={region}
                        currentParamValue={currentSido}
                        onClick={() => updateQueryStringAndNavigate(content, region === '전체' ? 'all' : region)}
                        filterColor="black"
                    />
                ))}
            </div>
        </Flex>
    )
}

export default LocalStatisticsFilter
