import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAllUserInfo } from 'store/Slices/user'

import PageBanner from 'layout/PageBanner'
import { Col, Flex, message, Row } from 'antd'

// import inventories from 'mocks/data/tools/transfer/stock_transfer.json'
// import rentals from 'mocks/data/tools/transfer/rental_transfer.json'

import { getTransferToolsInventory, registerToolsRental } from 'api/tools/toolsTransfer.api'

import ColorButton from 'components/AntD/Button/ColorButton'
import ListView from 'components/List/ListView'
import Spinner from 'components/AntD/Spin'

import { BannerIconTools, LeftArrow48Icon, RightArrow48Icon } from 'assets/Icons'

import styles from './transfer.module.scss'

const RentalTransfer = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { search } = useLocation()

    const { isLogin, userInfo } = useSelector(getAllUserInfo)
    // const { rentalList } = rentals.result || {}

    const [stockList, setStockList] = useState([])
    const [rentalList, setRentalList] = useState([])
    const [isLoading, setLoading] = useState([])

    const [checkedStockItems, setCheckedStockItems] = useState([])
    const [checkedRentalItems, setCheckedRentalItems] = useState([])

    const [messageApi, contextHolder] = message.useMessage()

    // 재고 목록에서 체크박스 변경 이벤트
    const handleCheckBoxChange = (toolItem, isChecked) => {
        if (isChecked) {
            setCheckedStockItems(prev => [...prev, toolItem])
        } else {
            setCheckedStockItems(prev => prev.filter(item => item.toolsId !== toolItem.toolsId))
        }
    }

    // 대출 목록에서 체크박스 변경 이벤트
    const handleRentalCheckBoxChange = (toolItem, isChecked) => {
        if (isChecked) {
            setCheckedRentalItems(prev => [...prev, toolItem])
        } else {
            setCheckedRentalItems(prev => prev.filter(item => item.toolsId !== toolItem.toolsId))
        }
    }

    // 오른쪽 화살표 버튼 클릭 -> 재고 목록에서 대출 목록으로 이동
    const moveToRentalList = () => {
        if (checkedStockItems.length === 0) return
        setRentalList(prev => (prev ? [...prev, ...checkedStockItems] : [...checkedStockItems])) // rentalList에 추가
        setStockList(prev => prev.filter(item => !checkedStockItems.some(c => c.toolsId === item.toolsId))) // stockList에서 제거
        setCheckedStockItems([]) // 선택 초기화
    }

    // 왼쪽 화살표 버튼 클릭 -> 대출 목록에서 재고 목록으로 이동
    const moveToStockList = () => {
        if (checkedRentalItems.length === 0) return
        setStockList(prev => (prev ? [...prev, ...checkedRentalItems] : [...checkedRentalItems]))
        setRentalList(prev => prev.filter(item => !checkedRentalItems.some(c => c.toolsId === item.toolsId)))
        setCheckedRentalItems([]) // 선택 초기화
    }

    const fetchToolsInventoryList = async () => {
        try {
            const data = await getTransferToolsInventory(userInfo.regionId)
            if (data.status === 200 || data.status === 206) {
                let modifiedData = data.data
                if (data.data && data.data.length > 0) {
                    // 중복 제거된 배열 생성
                    modifiedData = data.data.filter(
                        (item, index, array) =>
                            array.findIndex(innerItem => innerItem.tools_id === item.tools_id) === index,
                    )
                }
                setStockList(modifiedData)
                setLoading(false)
                return true
            }
            console.error('재고관리 목록을 불러오는 데 문제가 발생했습니다.')
            return false
        } catch (error) {
            console.error('재고관리 목록을 불러오는 동안 오류가 발생했습니다.', error)
            return null
        }
    }

    /**
     * 대출 등록
     */
    const handleRegisterRental = async () => {
        const toolsIds = rentalList.map(item => item.toolsId)
        await registerToolsRental({ rentalId: id, toolsIds })
        await navigate(`/tools/rental/${id}`)
        messageApi.open({
            type: 'success',
            content: `대여 신청이 완료되었습니다.`,
            duration: 5,
        })
    }

    useEffect(() => {
        if (isLogin) {
            const searchPath = search.split('?')[1] || ''
            fetchToolsInventoryList(searchPath)
        } else {
            navigate('/')
        }
    }, [id, dispatch])

    return (
        <>
            {contextHolder}
            <PageBanner title="교구재 반출 리스트" bannerIcon={<BannerIconTools />}>
                <div className={styles.container}>
                    <Row>
                        <Col span={11}>
                            <Flex align="center" gap={24} className={styles.margin_b45}>
                                <span className={`${styles.inventory} ${styles.icon}`} />
                                <span className="h5">
                                    재고 목록{' '}
                                    <span className={styles.count}>{stockList?.length ? stockList.length : 0}</span>
                                </span>
                            </Flex>
                            {isLoading ? (
                                <Spinner />
                            ) : stockList && stockList.length > 0 ? (
                                <div className={styles.rental_list_wrap}>
                                    <ListView list={stockList} onCheckBoxChange={handleCheckBoxChange} />
                                </div>
                            ) : (
                                <div className={styles.rental_list_wrap}>
                                    <div className={styles.not_found_page_wrap}>
                                        <div className={styles.not_found_page_top}>
                                            <span className={styles.not_found_page_icon} />
                                            <div className={styles.not_found_page_title}>
                                                <span className="sub_title1">재고 목록이 존재하지 않습니다.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Col>
                        <Col span={2}>
                            <Flex align="center" gap={24} justify="center" className={`${styles.height_100}`}>
                                <div>
                                    <button
                                        type="button"
                                        className={`${styles.btn} ${styles.margin_b12} button-reset-type ${
                                            checkedStockItems.length === 0 ? styles.disabled : ''
                                        }`}
                                        onClick={moveToRentalList}
                                    >
                                        <RightArrow48Icon disabled={checkedStockItems.length === 0} />
                                    </button>
                                    <button
                                        type="button"
                                        className={`${styles.btn} ${
                                            checkedRentalItems.length === 0 ? styles.disabled : ''
                                        } button-reset-type`}
                                        onClick={moveToStockList}
                                    >
                                        <LeftArrow48Icon disabled={checkedRentalItems.length === 0} />
                                    </button>
                                </div>
                            </Flex>
                        </Col>
                        <Col span={11}>
                            <Flex align="center" gap={24} className={styles.margin_b45}>
                                <span className={`${styles.rental} ${styles.icon}`} />
                                <span className="h5">
                                    대출 목록{' '}
                                    <span className={styles.count}>{rentalList?.length ? rentalList.length : 0}</span>
                                </span>
                            </Flex>
                            {rentalList && rentalList.length > 0 ? (
                                <div className={styles.rental_list_wrap}>
                                    <ListView list={rentalList} onCheckBoxChange={handleRentalCheckBoxChange} />
                                </div>
                            ) : (
                                <div className={styles.rental_list_wrap}>
                                    <div className={styles.not_found_page_wrap}>
                                        <div className={styles.not_found_page_top}>
                                            <span className={styles.not_found_page_icon} />
                                            <div className={styles.not_found_page_title}>
                                                <span className="sub_title1">
                                                    대여할 목록을 재고 목록에서 선택 후 추가해주세요.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Flex justify="center" className={styles.rental_register}>
                        <ColorButton type="primary" disabled={rentalList.length === 0} onClick={handleRegisterRental}>
                            대출 등록
                        </ColorButton>
                    </Flex>
                </div>
            </PageBanner>
        </>
    )
}

export default RentalTransfer
