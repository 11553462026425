import FilterButton from 'components/AntD/Button/FilterButton'
import { CheckFilterIcon, FinishFilterBottomIcon, OffFilterIcon, OngoingFilterIcon } from 'assets/Icons'

import { DatePicker, Flex } from 'antd'
import styles from '../filter.module.scss'

const InventoryFilter = ({ queryParams, updateQueryStringAndNavigate, startDateRange, handleStartDateChange }) => {
    const toolsState = 'tools_state'

    const currentToolsState = queryParams.get(toolsState) || ''
    return (
        <>
            <span className={`body2 ${styles.title}`}>교구상태</span>
            <div className={styles.btn_wrap}>
                <FilterButton
                    icon={<OffFilterIcon />}
                    title="정상"
                    currentParamValue={currentToolsState}
                    onClick={() => updateQueryStringAndNavigate(toolsState, '정상')}
                    filterColor="yellow"
                />
                <FilterButton
                    icon={<OngoingFilterIcon />}
                    title="수리"
                    currentParamValue={currentToolsState}
                    onClick={() => updateQueryStringAndNavigate(toolsState, '수리')}
                    filterColor="blue"
                />
                <FilterButton
                    icon={<FinishFilterBottomIcon />}
                    title="고장"
                    currentParamValue={currentToolsState}
                    onClick={() => updateQueryStringAndNavigate(toolsState, '고장')}
                    filterColor="black"
                />
                <FilterButton
                    icon={<CheckFilterIcon />}
                    title="폐기"
                    currentParamValue={currentToolsState}
                    onClick={() => updateQueryStringAndNavigate(toolsState, '폐기')}
                    filterColor="darkgray"
                />
                <FilterButton
                    icon={<CheckFilterIcon />}
                    title="부품부족"
                    currentParamValue={currentToolsState}
                    onClick={() => updateQueryStringAndNavigate(toolsState, '부품부족')}
                    filterColor="black"
                />
            </div>
            <Flex align="middle">
                <span className={`body2 ${styles.title}`}>취득일</span>
                <div className={styles.btn_wrap}>
                    <DatePicker
                        format="YYYY-MM-DD"
                        value={startDateRange}
                        onChange={handleStartDateChange}
                        placeholder="날짜선택"
                    />
                </div>
            </Flex>
        </>
    )
}

export default InventoryFilter
