import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Col, Divider, Form, Input, message, Row, Select } from 'antd'

import ColorButton from 'components/AntD/Button/ColorButton'
import InputSection from 'components/AntD/InputSection'

import { supabase } from 'api/axios.instance'
import { fetchMatchTutorList } from 'api/tutors/tutors.api'

import { getAllUserInfo } from 'store/Slices/user'

import { ArrowBtmIcon } from 'assets/Icons'
import DatePickerInput from '../DatePicker'
import styles from './dialog.module.scss'

const success = 'success'
const error = 'error'
/**
 * Modal
 * 대여 관리
 * 신규 대여 신청
 */
const ToolRentalDialog = ({
    form,
    setModalOpen,
    fetchList,
    // accountInfo,
}) => {
    const location = useLocation()
    const { TextArea } = Input
    const [messageApi, contextHolder] = message.useMessage()

    const { userInfo } = useSelector(getAllUserInfo)

    const [tutorList, setTutorList] = useState([])
    const [activeOption, setActiveOption] = useState(null)

    const handleTutorChange = (id, newValue) => {
        const updatedList = tutorList.map(tutor =>
            tutor.id === newValue.tutor_id ? { ...tutor, tutor_name: newValue, tutor_id: newValue.tutor_id } : tutor,
        )
        setTutorList(updatedList)
    }

    const alertMessage = boolean => {
        if (boolean) {
            messageApi.open({
                type: success,
                content: `대여 신청이 완료되었습니다.`,
                duration: 5,
            })
        } else {
            messageApi.open({
                type: error,
                content: `대여 신청이  실패하었습니다.`,
                duration: 5,
            })
        }
    }

    /**
     * submit 제출하여 성공 후 처리
     * 모든 value 확인 가능
     * @param values
     */
    const onFinish = async values => {
        try {
            const { renterId, applicationDate, remarks, rentalItems } = values
            const response = await supabase
                .from('tools_rental')
                .insert({
                    tutor_id: renterId,
                    rental_application_date: applicationDate,
                    remarks,
                    rental_items: rentalItems,
                    rental_status: '대여신청',
                    is_delete: false,
                })
                .single()
            console.log('response : ', response)
            form.resetFields()
            fetchList()
            setModalOpen(false)
            alertMessage(true)
            return false
        } catch (e) {
            console.error(e)
            return false
        }
    }

    /**
     * submit 제출하여 실패 후 처리
     * 모든 value 확인 가능
     * @param errorInfo
     */
    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo)
    }

    const handleChange = value => {
        console.log(`selected ${value}`)
        setActiveOption(value)
    }

    const handleCancel = e => {
        e.preventDefault()
        form.resetFields()
        setModalOpen(false)
    }

    useEffect(() => {
        const fetchAndSetTutors = async search => {
            try {
                const response = await fetchMatchTutorList(search, userInfo.regionId)
                if (response.status === 200) {
                    const tutors = response?.data?.tutorInfoList
                    if (tutors && Array.isArray(tutors)) {
                        const options = tutors.map(tutor => ({
                            key: uuidv4(),
                            tutor_id: tutor?.tutorId,
                            value: tutor?.tutorId,
                            label: tutor?.tutorName,
                        }))
                        setTutorList(options)
                    } else {
                        console.error('Invalid tutor data structure:', tutors)
                    }
                } else {
                    console.error('Failed to fetch tutor list. Status:', response.status)
                }
            } catch (error) {
                console.error('Error while fetching tutor list:', error)
            }
        }

        const { search } = location
        const searchPath = search.split('?')[1] || ''
        fetchAndSetTutors(searchPath)
    }, [])

    return (
        <>
            {contextHolder}
            <Form
                name="basic"
                form={form}
                initialvalues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className={styles.input_section}>
                    <div className={styles.title}>
                        <span className="h6">교구재 대출</span>
                    </div>
                    <Divider style={{ margin: 0, backgroundColor: 'black' }} />
                    <Row align="middle" style={{ borderBottom: '1px solid var(--gray-300)' }}>
                        <Col span={8}>
                            <div className={styles.label}>
                                <span>대여자</span>
                            </div>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="renterId"
                                className={styles.search_renter}
                                rules={[{ required: true, message: '대여자를 입력해주세요.' }]}
                            >
                                {tutorList && tutorList.length > 0 ? (
                                    <Select
                                        className={`${styles.ant_select_selector}`}
                                        showSearch
                                        placeholder="강사선택"
                                        options={tutorList}
                                        onChange={(value, option) => handleTutorChange(value, option)}
                                        style={{ height: '50px', width: '180px !important;' }}
                                        suffixIcon={<ArrowBtmIcon />}
                                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '')
                                                .toLowerCase()
                                                .localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                    />
                                ) : (
                                    <div>강사 정보가 없습니다.</div>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row align="middle" style={{ borderBottom: '1px solid var(--gray-300)' }}>
                        <Col span={8}>
                            <div className={styles.label}>
                                <span>대여 신청일자</span>
                            </div>
                        </Col>
                        <Col span={10}>
                            <Form.Item
                                name="applicationDate"
                                rules={[{ required: true, message: '대여 신청일자를 입력해주세요.' }]}
                            >
                                <DatePickerInput
                                    name="applicationDate"
                                    value={activeOption || null}
                                    onChange={date => handleChange(date)}
                                    defaultValue={null}
                                    noMt
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <InputSection
                        label="신청 물품 목록"
                        name="rentalItems"
                        rules={[{ required: true, message: '신청 물품 목록을 입력해주세요.' }]}
                        leftCol={8}
                        rightCol={16}
                    />
                    <Row align="middle" style={{ borderBottom: '1px solid var(--gray-300)' }}>
                        <Col span={8}>
                            <div className={styles.label_memo}>
                                <span>메모</span>
                            </div>
                        </Col>
                        <Col span={16}>
                            <Form.Item name="remarks">
                                <TextArea val="" rows={3} />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
                <Form.Item className={styles.item}>
                    <ColorButton type="primary" key="back" htmlType="submit">
                        확인
                    </ColorButton>
                    <ColorButton key="back" onClick={e => handleCancel(e)}>
                        취소
                    </ColorButton>
                </Form.Item>
            </Form>
        </>
    )
}

export default ToolRentalDialog
