import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form } from 'antd'

import { clearRentalDetail, getRentalInfo, rentalListAsync } from 'store/Slices/rental'
import { getAllUserInfo } from 'store/Slices/user'
import PageBanner from 'layout/PageBanner'

import ListView from 'components/List/ListView'
import Pagenation from 'components/Pagenation'
import Spinner from 'components/AntD/Spin'
import InfoDialog from 'components/AntD/Dialog'
import ToolRentalDialog from 'components/AntD/Dialog/ToolRentalDialog'
import FilterAndSearchArea from 'components/Filter'

import ListNotFound from 'pages/PageNotFound/ListNotFound'

import { BannerIconTools } from 'assets/Icons'
import styles from 'assets/scss/detail.module.scss'

const search = 'search'

const Rental = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const secondPath = location.pathname.split('/')[2]
    const queryParams = new URLSearchParams(window.location.search)

    const { isLogin, userInfo } = useSelector(getAllUserInfo)
    const { rentalList, filterCountInfo, page } = useSelector(getRentalInfo)

    const [isLoading, setLoading] = useState(true)
    const [inputValue, setInputValue] = useState('') // 검색 input

    const [sortName, setSortName] = useState('createdDt')
    const [modalOpen, setModalOpen] = useState(false)

    const { currentPage, totalPages, totalItems } = page || {}
    const { isMaster, isSubMaster } = userInfo

    /**
     * querystring update
     * @param paramName
     * @param paramValue
     * @param loading
     */
    const updateQueryStringAndNavigate = (paramName, paramValue, loading) => {
        const { pathname } = location
        const previousParams = queryParams.get(paramName)

        // 기존값과 동일할 시 변경하지 않음
        if (paramName === 'regionId' && paramValue === previousParams) {
            return
        }

        // 새로운 파라미터 추가 (단, 값이 비어있지 않을 때)
        if (paramValue) {
            if (previousParams === paramValue) {
                if (paramName !== 'sort' && paramName !== 'search' && paramName !== 'regionId') {
                    queryParams.delete(paramName)
                }
            } else {
                queryParams.set(paramName, paramValue)
            }
        }
        // 정렬 active
        if (paramName === 'sort') {
            setSortName(paramValue)
        }
        if (paramName === 'search' && paramValue === '') {
            queryParams.delete(paramName)
        }
        if (paramName === 'dispatchDate' && paramValue === '') {
            queryParams.delete(paramName)
        }
        if (paramName === 'startDate' && paramValue === '') {
            queryParams.delete('dispatchDate')
        }
        // 필터 선택 시 쿼리스트링에 포함된 페이지 정보 제거
        if (paramName !== 'page') {
            queryParams.delete('page')
        }
        const newQueryString = queryParams.toString()
        const newLocation = newQueryString ? `${pathname}?${newQueryString}` : pathname
        if (loading) {
            loading(false)
        }
        navigate(newLocation)
    }

    const handleSearch = () => {
        setLoading(true)
        updateQueryStringAndNavigate(search, inputValue, setLoading)
    }

    const fetchRentalList = async search => {
        try {
            const { payload } = await dispatch(rentalListAsync({ search, regionId: userInfo.regionId, secondPath }))
            if (payload) {
                setLoading(false)
                return true
            }
            console.error('계정 목록을 불러오는 데 문제가 발생했습니다.')
            return false
        } catch (error) {
            console.error('계정  목록을 불러오는 동안 오류가 발생했습니다.', error)
            return null
        }
    }

    useEffect(() => {
        if (isLogin) {
            const { search } = location
            const searchPath = search.split('?')[1] || ''
            dispatch(clearRentalDetail())
            fetchRentalList(searchPath)
        } else {
            navigate('/')
        }
    }, [location])

    useEffect(() => {
        if ((isMaster || isSubMaster) && !queryParams.get('regionId')) {
            queryParams.set('regionId', 'all')
            navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true })
        }
    }, [isMaster, isSubMaster, location, navigate, queryParams])

    return (
        <PageBanner title="대여반납 관리" bannerIcon={<BannerIconTools />}>
            {filterCountInfo && (
                <FilterAndSearchArea
                    filterCount={filterCountInfo}
                    updateQueryStringAndNavigate={updateQueryStringAndNavigate}
                    sortName={sortName}
                    setInputValue={setInputValue}
                    inputValue={inputValue}
                    handleSearch={handleSearch}
                />
            )}
            {isLoading ? (
                <div className={styles.spin_wrap}>
                    <Spinner />
                </div>
            ) : !rentalList || rentalList.length === 0 ? (
                <ListNotFound />
            ) : (
                <>
                    <ListView list={rentalList.slice(0, 10)} setLoading={setLoading} fetchList={fetchRentalList} />
                    <Pagenation
                        title="대량등록"
                        subTitle="신규 대여"
                        navigateBtn={() => navigate('/tools/rental/register/bulk')}
                        subNavigateBtn={() => setModalOpen(true)}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        totalItems={totalItems}
                        updateFtn={updateQueryStringAndNavigate}
                    />
                </>
            )}
            <InfoDialog
                content={
                    <ToolRentalDialog
                        form={form}
                        setModalOpen={setModalOpen}
                        modalOpen={modalOpen}
                        fetchList={fetchRentalList}
                    />
                }
                centered
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                closable={false}
                isCancel
                submit
                hideFooter
                type={2}
            />
        </PageBanner>
    )
}

export default Rental
