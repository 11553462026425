import { configureStore } from '@reduxjs/toolkit'
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import logger from 'redux-logger'

import { persistedReducer } from './reducers'

const isDevelopment = process.env.NODE_ENV === 'development'
/* eslint-disable no-underscore-dangle */
const devTools = isDevelopment && window.__REDUX_DEVTOOLS_EXTENSION__
const middleware = isDevelopment ? [logger] : []

const Store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(...middleware),
    devTools,
})

export default Store
