import { Col, Divider, Flex, Form, Row, Space } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { getUser } from 'store/Slices/user'

import BasicInfoForm from 'components/Program/Edit/BasicInfoForm'
import DemandAgencyInfo from 'components/Program/Edit/DemandAgencyInfoForm'
import OperationInfo from 'components/Program/Edit/OperationInfoForm'
import DemandManagerForm from 'components/Program/Edit/DemandManagerForm'
import AttendencesDetail from 'components/Program/Detail/AttendencesDetail'
import EtcInfo from 'components/Program/Edit/EtcInfoForm'
import TutorInfoListForm from 'components/Program/Edit/TutorListForm'
import Attendences from 'components/Program/Edit/AttendencesForm'

import Attachment from 'components/Program/Detail/Attachment'
import TutorInfoList from 'components/Program/Detail/TutorList'

import IconCard from 'components/AntD/Card/IconCard'
import UploadAttachment from 'components/Attachment/Upload'
import ColorButton from 'components/AntD/Button/ColorButton'
import AttendenceDialog from 'components/AntD/Dialog/AttendanceDialog'

import { ColorPeopleIcon, ReFilterIcon } from 'assets/Icons'

import styles from 'assets/scss/detail.module.scss'

const RegistrationAndEditFormPrograms = ({ program, onFinish, onFinishFailed, setIsModalOpen, isModalOpen }) => {
    const navigate = useNavigate()

    const { pathname } = useLocation()
    const paths = pathname.split('/').filter(path => path !== '')
    const { isSubMaster } = useSelector(getUser)

    const editOrRegisterPage = paths[1] === 'edit' || paths[1] === 'register'

    return (
        <Form initialvalues={program} name="program" onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Row gutter={50} className={styles.mb_60}>
                {/* 교육 기본정보 */}
                <Col span={16}>
                    <Space direction="vertical">
                        <BasicInfoForm
                            programInfo={program?.programBasicInfo}
                            editOrRegisterPage={editOrRegisterPage}
                        />
                    </Space>
                </Col>

                {/* 교육운영상태, 신청인원 */}
                {paths[1] !== 'register' && (
                    <Col span={8}>
                        <Space direction="horizontal" className={styles.mt}>
                            <IconCard
                                icon={<ReFilterIcon />}
                                label="교육운영상태"
                                val={program?.programOperationInfo?.status}
                            />
                            <IconCard
                                icon={<ColorPeopleIcon />}
                                label="신청인원"
                                val={program?.programOperationInfo?.numberOfApplicants}
                            />
                        </Space>
                    </Col>
                )}
            </Row>
            <Space className={styles.mb_60} direction="vertical">
                {/* 출석부 영역 */}
                {editOrRegisterPage ? (
                    <Attendences attendanceInfo={program?.programOperationInfo} onModal={() => setIsModalOpen(true)} />
                ) : (
                    <AttendencesDetail program_operation_info={program?.programOperationInfo} />
                )}
            </Space>

            <Space direction="vertical" size={60}>
                {/* 수요기간 */}
                <DemandAgencyInfo
                    demand_agency_info={program?.programDemandAgency}
                    editOrRegisterPage={editOrRegisterPage}
                />
                {/* 프로그램 운영정보 */}
                <OperationInfo
                    program_operation_info={program?.programOperationInfo}
                    editOrRegisterPage={editOrRegisterPage}
                />
                {/* 수요처 담당자 */}
                <DemandManagerForm
                    programDemandRepresentative={program?.programDemandRepresentative}
                    editOrRegisterPage={editOrRegisterPage}
                />
                {/* 기타 */}
                <EtcInfo etc_info={program?.programEtcInfo} editOrRegisterPage={editOrRegisterPage} />
            </Space>

            <Divider dashed className={styles.ant_divider} />

            <Space className={styles.mb_lg} direction="vertical" size={60}>
                {/* 파일 업로드 영역 */}
                {editOrRegisterPage ? (
                    <Form.Item name="programAttachmentList" initialValue={program?.programAttachmentList}>
                        <UploadAttachment attachment={program?.programAttachmentList} />
                    </Form.Item>
                ) : (
                    <Attachment attachment={program?.programAttachmentList} />
                )}

                {/* 강사 매칭 등록 영역 */}
                {editOrRegisterPage ? (
                    <Form.Item
                        name="programInstructorMatchingList"
                        initialValue={program?.programInstructorMatchingList}
                        noStyle
                    >
                        <TutorInfoListForm
                            name="programInstructorMatchingList"
                            programId={program?.programId}
                            programStartDate={program?.programOperationInfo.startDate}
                            programEndDate={program?.programOperationInfo.endDate}
                            instructor_matching={program?.programInstructorMatchingList}
                            classHours={program?.programOperationInfo?.classHours}
                        />
                    </Form.Item>
                ) : (
                    <TutorInfoList instructor_matching={program?.programInstructorMatchingList} />
                )}
            </Space>

            <Flex justify="center" align="center" gap={8}>
                {editOrRegisterPage ? (
                    <>
                        <ColorButton size="large" type="primary" htmlType="submit">
                            확인
                        </ColorButton>
                        <ColorButton
                            onClick={() => navigate(`/program/${program ? program?.programId : ''}`)}
                            size="large"
                        >
                            취소
                        </ColorButton>
                    </>
                ) : (
                    <>
                        {!isSubMaster && (
                            <ColorButton
                                size="large"
                                type="primary"
                                onClick={() => navigate(`/program/edit/${program?.programId}`)}
                            >
                                수정
                            </ColorButton>
                        )}
                        <ColorButton size="large" onClick={() => navigate(`/program`)}>
                            목록
                        </ColorButton>
                    </>
                )}
            </Flex>

            {/* 출석부 계산하기 팝업 */}
            <AttendenceDialog
                title="온라인출석부"
                centered
                modalOpen={isModalOpen}
                setModalOpen={setIsModalOpen}
                isCancel
                attendanceInfo={{
                    classHours: program?.programOperationInfo?.classHours,
                    numberOfSutdent: program?.programOperationInfo?.numberOfSutdent,
                    numberOfGraduates: program?.program_operation_info?.numberOfGraduates,
                }}
            />
        </Form>
    )
}

export default RegistrationAndEditFormPrograms
