import ReactECharts from 'echarts-for-react'
import { Tooltip } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useState, useEffect } from 'react'
import styles from './chart.module.scss'

const StudentBarChart = ({ title = '월별 진행완료 교육수', dataSource, tooltip }) => {
    const [localData, setLocalData] = useState(dataSource || [])
    useEffect(() => {
        setLocalData(dataSource || [])
    }, [dataSource])

    const getEChartsOption = data => {
        const months = data.map(item => item.month)
        const completedProgramCount = data.map(item => item.completedProgramCount)
        const colors = ['#00a886', '#FF6F4B']
        return {
            backgroundColor: '#fff',
            color: colors,
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                },
            },
            xAxis: {
                type: 'category',
                data: months,
            },
            yAxis: {
                type: 'value',
                name: 'Count',
            },
            series: [
                {
                    name: '진행완료 교육수',
                    type: 'bar',
                    data: completedProgramCount,
                },
            ],
        }
    }

    return (
        <>
            <p className={styles.barChartTitle}>
                {title}
                <Tooltip placement="top" title={tooltip}>
                    <ExclamationCircleOutlined style={{ fontSize: '24px', color: '#fff', marginLeft: '12px' }} />
                </Tooltip>
            </p>

            <div className={styles.cart_contents}>
                <ReactECharts style={{ height: 470 }} option={getEChartsOption(localData)} />
            </div>
        </>
    )
}

export default StudentBarChart
