import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Space, Flex } from 'antd'
import PageBanner from 'layout/PageBanner'
import { BannerIconTools, ColorDocIcon, ColorLocationIcon } from 'assets/Icons'

import { getRentalDetail, rentalDetailAsync } from 'store/Slices/rental'
import { getAllUserInfo } from 'store/Slices/user'

import Title from 'components/Ui/Title'
import Label from 'components/Ui/Label'
import SelectUi from 'components/Ui/Select/SelectUi'
import IconCard from 'components/AntD/Card/IconCard'
import ColorButton from 'components/AntD/Button/ColorButton'

import styles from 'assets/scss/detail.module.scss'

const ToolsRentDetail = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { isLogin, userInfo } = useSelector(getAllUserInfo)

    const rentalDetail = useSelector(getRentalDetail)
    const {
        // tutorId,
        // rentalId,
        // remarks,
        tutorName,
        contact,
        usingInstitution,
        rentalStatus,
        rentalApplicationDate,
        rentalDispatchDate,
        rentalReturnDate,
        rentalUsePurpose,
        toolsInfo,
        // quantity,
        // toolsId,
        // toolsName,
        // etc,
        toolsNum,
        stockLocation,
        classification,
        affiliatedRegion,
        specification,
        locationCode,
        managementNumber,
        stockInSequence,
        regionClassification,
        buildingClassification,
        moveDate,
    } = rentalDetail || {}

    const { isMaster, isSubMaster, regionId } = userInfo

    useEffect(() => {
        if (isLogin) {
            dispatch(rentalDetailAsync({ regionId: userInfo.regionId, rentalId: id }))
        } else {
            navigate('/')
        }
    }, [id, dispatch])

    return (
        <PageBanner title="교구재 관리" bannerIcon={<BannerIconTools />}>
            <div className={styles.area}>
                {
                    rentalDetail && (
                        <>
                            <Row className={styles.mb_lg}>
                                <Col span={16}>
                                    <Space direction="vertical" size={60}>
                                        <div>
                                            <Title title="대여자 정보" />
                                            <div className={`${styles.box_md} ${styles.border_style}`}>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="성명 (대여자)" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>{tutorName}</span>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    {/*<Col span={12}>*/}
                                                    {/*    <Row>*/}
                                                    {/*        <Col span={10}>*/}
                                                    {/*            <Label label="공동사용자" />*/}
                                                    {/*        </Col>*/}
                                                    {/*        <Col span={14} className={styles.padding_y_medium}>*/}
                                                    {/*            <span>박미래</span>*/}
                                                    {/*            /!* <Value value="010-AD6-610" /> *!/*/}
                                                    {/*        </Col>*/}
                                                    {/*    </Row>*/}
                                                    {/*</Col>*/}
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="연락처" />
                                                    </Col>
                                                    <Col span={19} className={styles.padding_y_medium}>
                                                        {contact}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div>
                                            <Title title="대여 및 사용정보" />
                                            <div className={`${styles.box_md} ${styles.border_style}`}>
                                                <Row className={styles.row_item}>
                                                    {/*<Col span={12}>*/}
                                                    {/*    <Row>*/}
                                                    {/*        <Col span={10}>*/}
                                                    {/*            <Label label="수량" />*/}
                                                    {/*        </Col>*/}
                                                    {/*        <Col span={14} className={styles.padding_y_medium}>*/}
                                                    {/*            /!*<span>{quantity ?? ''}</span>*!/*/}
                                                    {/*        </Col>*/}
                                                    {/*    </Row>*/}
                                                    {/*</Col>*/}
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="사용기관" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>{usingInstitution}</span>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="대여상태" />
                                                    </Col>
                                                    <Col span={19}>
                                                        <SelectUi
                                                            size="lg"
                                                            disabled
                                                            value={rentalStatus}
                                                            options={[
                                                                '대여신청',
                                                                '신청취소',
                                                                '반납완료',
                                                                '대여확정',
                                                                '대여불가능',
                                                            ]}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="대여신청일자" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>{rentalApplicationDate}</span>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="대여일자" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>{rentalDispatchDate}</span>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="반납일자" />
                                                    </Col>
                                                    <Col span={19} className={styles.padding_y_medium}>
                                                        <span>{rentalReturnDate}</span>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="대여/사용목적" />
                                                    </Col>
                                                    <Col span={19} className={styles.padding_y_medium}>
                                                        <span>{rentalUsePurpose}</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div>
                                            <Title title="교구 정보" />
                                            {toolsInfo && toolsInfo.length > 0 ? (
                                                toolsInfo.map(tool => (
                                                    <div
                                                        key={tool.toolsId}
                                                        className={`${styles.box_md} ${styles.border_style} ${styles.mb15}`}
                                                    >
                                                        <Row className={styles.row_item}>
                                                            <Col span={12}>
                                                                <Row>
                                                                    <Col span={10}>
                                                                        <Label label="품목 (교구명)" />
                                                                    </Col>
                                                                    <Col span={14} className={styles.padding_y_medium}>
                                                                        <span>{tool.toolsName ?? '없음'}</span>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Row>
                                                                    <Col span={10}>
                                                                        <Label label="교구번호" />
                                                                    </Col>
                                                                    <Col span={14} className={styles.padding_y_medium}>
                                                                        <span>{tool.toolsId ?? '없음'}</span>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row className={styles.row_item}>
                                                            <Col span={5}>
                                                                <Label label="비고" />
                                                            </Col>
                                                            <Col span={19} className={styles.padding_y_medium}>
                                                                <span>{tool.remarks ?? '내용없음'}</span>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className={styles.not_found_page_wrap}>
                                                    <div className={styles.not_found_page_top}>
                                                        <span className={styles.not_found_page_icon} />
                                                        <div className={styles.not_found_page_title}>
                                                            <span className="sub_title1">
                                                                매칭되는 대여 반납 교구가 존재하지 않습니다.
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div>
                                            <Title title="비고" />
                                            <div className={`${styles.box_md} ${styles.border_style}`}>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="구분" />
                                                    </Col>
                                                    <Col span={19} className={styles.padding_y_medium}>
                                                        {/*<SelectUi*/}
                                                        {/*    disabled*/}
                                                        {/*    value="지역아동센터"*/}
                                                        {/*    options={['지역아동센터', '섬마을']}*/}
                                                        {/*/>*/}
                                                        {classification}
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="소속권역" />
                                                    </Col>
                                                    <Col span={19} className={styles.padding_y_medium}>
                                                        {affiliatedRegion}
                                                        {/* <SelectUi value="1권역" options={['1권역', '2권역']} /> */}
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="규격" />
                                                    </Col>
                                                    <Col span={19} className={styles.padding_y_medium}>
                                                        {specification}
                                                        {/* <SelectUi value="1권역" options={['1권역', '2권역']} /> */}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div>
                                            <Title title="선택항목" />
                                            <div className={`${styles.box_md} ${styles.border_style}`}>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="위치코드" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>{locationCode}</span>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="관리번호" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>{managementNumber}</span>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="입고순번" />
                                                    </Col>
                                                    <Col span={19} className={styles.padding_y_medium}>
                                                        <span>{stockInSequence}</span>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={5}>
                                                        <Label label="지역구분" />
                                                    </Col>
                                                    <Col span={19} className={styles.padding_y_medium}>
                                                        {regionClassification}
                                                    </Col>
                                                </Row>
                                                <Row className={styles.row_item}>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="건물구분" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>{buildingClassification}</span>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row>
                                                            <Col span={10}>
                                                                <Label label="이동일자" />
                                                            </Col>
                                                            <Col span={14} className={styles.padding_y_medium}>
                                                                <span>{moveDate}</span>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Space>
                                </Col>
                                <Col span={8} className={styles.padding_left_large}>
                                    <Space direction="horizontal" className={styles.mt}>
                                        <IconCard icon={<ColorDocIcon />} label="자산번호" val={toolsNum} />
                                        <IconCard icon={<ColorLocationIcon />} label="재고위치" val={stockLocation} />
                                    </Space>
                                </Col>
                            </Row>

                            <Flex justify="center" align="center" gap={8}>
                                {!isMaster && !isSubMaster && regionId !== 'all' && (
                                    <ColorButton
                                        size="large"
                                        type="primary"
                                        onClick={() => navigate(`/tools/rental/edit/${id}`)}
                                    >
                                        수정
                                    </ColorButton>
                                )}
                                <ColorButton size="large" onClick={() => navigate('/tools/rental')}>
                                    목록
                                </ColorButton>
                            </Flex>
                        </>
                    )
                    // : (
                    //     <div className={styles.spin_wrap}>
                    //         <Spinner />
                    //     </div>
                    // )
                }
            </div>
        </PageBanner>
    )
}

export default ToolsRentDetail
