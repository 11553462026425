import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { App } from 'antd'
import PageBanner from 'layout/PageBanner'
import { BannerIconTools } from 'assets/Icons'
import RegistrationAndEditFormTools from 'components/RegistrationAndEditForm/Tools'
import { convertToolsInventoryCamelToSnakeCase } from 'utils/utilCommon'
import { findDuplicatedToolsNumSingle, insertToolsInventory } from 'api/tools/toolsInventory.api'

const ToolsRegisterOne = () => {
    const { message } = App.useApp()
    const navigate = useNavigate()
    const { regionId } = useSelector(state => state.user.userInfo)
    const msgDuplicate = '이미 등록된 자산번호입니다'

    const findDuplicatedToolsNum = async toolsNum => {
        const response = await findDuplicatedToolsNumSingle(toolsNum, regionId)

        return response
    }

    const onFinish = async value => {
        try {
            const formData = convertToolsInventoryCamelToSnakeCase(value)
            const duplicatedToolsNum = await findDuplicatedToolsNum(formData.tools_num)
            if (duplicatedToolsNum.data.length > 0) {
                message.error(msgDuplicate)
                return
            }
            const insertTools = async () => {
                const response = await insertToolsInventory(formData, regionId)

                console.log(response)

                if (response.status === 201) {
                    message.success('성공적으로 등록되었습니다')
                    navigate(`/tools/inventory`)
                }
            }
            insertTools()
        } catch (err) {
            console.error(err)
        }
    }

    const onFinishFailed = errorInfo => {
        message.error('필수값을 확인해주세요')
        console.log('Failed:', errorInfo)
    }

    return (
        <PageBanner title="재고 관리 단건등록" bannerIcon={<BannerIconTools />}>
            <RegistrationAndEditFormTools onFinish={onFinish} onFinishFailed={onFinishFailed} />
        </PageBanner>
    )
}

export default ToolsRegisterOne
